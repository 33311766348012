import React from 'react'





import bl from '../../assets/bottomline.png'
import microLogo from '../../assets/microLogo.png'
import secudeLogo from '../../assets/secudeLogo.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'

import vd1 from '../../assets/vd1.png'
import vd2 from '../../assets/vd2.png'
import vd3 from '../../assets/vd3.png'

import SolutionEndUserIcon from '../../assets/SolutionEndUserIcon'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import './virtualDesktop.css'

import styled from 'styled-components'
import { device, InnerWrapper, Text } from '../../common.styles'
import TopButton from '../TopButton/TopButton'

const HorizontalBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(156px, 1fr));
    grid-gap: 16px;
    margin: 60px 0;

    @media only screen and ${device.mobileL} {
        grid-template-columns: repeat(auto-fit, minmax(52px, 1fr));
        margin: 0 20px 20px;
    }
`;

const HorizontalItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    img {
        width: 156px;
        height: 156px;

        @media only screen and ${device.mobileL} {
            width: 52px;
            height: 52px;
        }
    }

    @media only screen and ${device.mobileL} {
        gap: 16px;
    }
`;

const IconText = styled(Text)`
    line-height: inherit;
    text-align: center;
`;

const VirtualDesktop = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <InnerWrapper>
                <DecoHeading desc="Virtual Desktop Infrastructure (VDI)" icon={<SolutionEndUserIcon />} />

                <DecoDesc1>
                    Remote or hybrid work model are common nowadays, virtual desktop infrastructure facilitates secure and convenient remote access for a more efficient and cost-effective business model.
                </DecoDesc1>
                <div>

                    <HorizontalBlock>

                        <HorizontalItem>
                            <img src={vd1} alt="" />
                            <IconText className='subContent_hr'>Cost savings</IconText>

                        </HorizontalItem>
                        <HorizontalItem>
                            <img src={vd2} alt="" />
                            <IconText className='subContent_hr'>Improved security</IconText>

                        </HorizontalItem>
                        <HorizontalItem>
                            <img src={vd3} alt="" />
                            <IconText className='subContent_hr'>Centralized management</IconText>
                        </HorizontalItem>
                    </HorizontalBlock>


                </div>

            </InnerWrapper>



            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <div>
                        <a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'><img src={microLogo} alt="" height="50" style={{ marginRight: '20px' }} /></a>
                        <a target='_blank' href='https://secude.com/'><img src={secudeLogo} alt="" height="50" /></a>
                    </div>
                </div>
                <div><img src={bl} alt="" style={{ width: `80%`, marginTop: `20px`, marginBottom: `20px`, objectFit: `contain` }} /></div>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>

                </div>
            </SectionBlock>

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default VirtualDesktop
import React from 'react'

import { useNavigate } from "react-router-dom"
import { Link } from 'react-scroll'
import bl from '../../assets/bottomline.png'
import cloud1 from '../../assets/cloud1.jpg'
import cloud2 from '../../assets/cloud2.jpg'
import cloud3 from '../../assets/cloud3.jpg'
import cloud4 from '../../assets/cloud4.jpg'
import ServiceOCCSIcon from '../../assets/ServiceOCCSIcon'
import serviceAd from '../../assets/service_ad.png'
import serviceCs from '../../assets/service_cs.png'
import serviceIbroker from '../../assets/service_ibroker.png'
import serviceItrading from '../../assets/service_itrading.png'
import serviceTs from '../../assets/service_ts.png'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import ImageDescBlock from '../../ImageDescBlock'
import SectionBlock from '../../SectionBlock'


import styled from 'styled-components'
import { device, Heading, InnerWrapper, Text } from '../../common.styles'
import TopButton from '../TopButton/TopButton'
import './opmCloud.css'
import { useMediaQuery } from 'react-responsive'


// import '././App.css'

const FeatureCenter = styled.div`
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
`;

const OpmCloud = () => {
    const isMobile = useMediaQuery({ query: device.mobileL });

    return (
        <div>


            <InnerWrapper>
                <DecoHeading desc="OPM Cloud Computing Services" icon={<ServiceOCCSIcon />} />
                <DecoDesc1>
                    OPM SYSTEMS COMPANY LTD is now on the list of Government Public Cloud service provider.
                </DecoDesc1>
                <FeatureCenter>
                    <Heading className='abStyle'>Features</Heading>
                </FeatureCenter>
                <ImageDescBlock imageFirst={false} image={cloud1} title="Service Strategy" desc="OPM Cloud Computing Services is one of our major investments in the last few years. We spent and invested over 2 years to establish the cloud computing services. OPM professional team developed our own applications and the web Content Management Framework running on our cloud services environment. It is the new marketing strategy selling OPM applications with the cloud services platform." />
                <ImageDescBlock image={cloud2} title="Investment Portfolios" desc="Portal hosting services for the customers, mainly the CMS portal OPM designed and provided the tools to let the users self-manage the contents. Moreover, mailing service could be bundled as requested. Provide the development platform for In-house IT or SI to develop their applications (mainly JAVA applications run under Linux O/S).">
                    <ul>
                        <li><Text>Investment in datacenter facilities</Text></li>
                        <li><Text>Investment in storage solution</Text></li>
                        <li><Text>Investment in security protection</Text></li>
                        <li><Text>Investment in networking infrastructure</Text></li>
                        <li><Text>Investment in virtual machine technology</Text></li>
                    </ul>
                </ImageDescBlock>
                <ImageDescBlock imageFirst={false} image={cloud3} title="Benefit of the Designs">
                    <ul>
                        <li><Text>Reduce setup costs and maintenance costs for hardware and networking in customer offices</Text></li>

                        <li><Text>OPM provided 24 X 7 services for all the cloud services</Text></li>

                        <li>
                            <Text>Centralization of company information / data, authorized users could view the full pictures in any branch anywhere</Text>
                        </li>

                        <li>
                            <Text>The primary goal of information security is to protect information assets and thus to maintain the confidentiality, integrity and availability, along with related goals such as authenticity, accountability and reliability, etc</Text>
                        </li>

                        <li>
                            <Text>OPM is the cloud partner empowered by Microsoft</Text>
                        </li>
                    </ul>
                </ImageDescBlock>
                <ImageDescBlock image={cloud4} title="Value-added Management">
                    <ul>
                        <li>
                            <Text>Applications Development</Text>
                        </li>

                        <li>
                            <Text>Web Portal and Content Development</Text>
                        </li>

                        <li>
                            <Text>End User Portal Solution</Text>
                        </li>
                    </ul>
                </ImageDescBlock>



            </InnerWrapper>

            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Products#iBrokers'><img src={serviceIbroker} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                    <a href='/pages/Products#iTrading'><img src={serviceItrading} alt="" height="50" /></a>
                </div>
                <div><img src={bl} alt="" style={{ width: `80%`, marginTop: `20px`, marginBottom: `20px`, objectFit: `contain` }} /></div>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href=''><Link to='APPLICATION_DEVELOPMENT' offset={isMobile ? -80 : null} spy={true}> <img src={serviceAd} alt="" height="50" /></Link></a>
                    <a href=''><Link to='CONSULTING_SERVICE' offset={isMobile ? -80 : null} spy={true}><img src={serviceCs} alt="" height="50" style={{ marginLeft: '16px', marginRight: '16px' }} /></Link></a>
                    <a href=''><Link to='TECHNICAL_SUPPORT' offset={isMobile ? -80 : null} spy={true}>  <img src={serviceTs} alt="" height="50" /></Link></a>
                </div>
            </SectionBlock >

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />

        </div >
    )
}

export default OpmCloud
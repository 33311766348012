import React from 'react';
import { Element } from 'react-scroll';
import ApplicationDev from '../applicationDev/ApplicationDev';
import ConsultingService from '../consultingServices/ConsultingService';
import HelpDeskService from '../helpdestService/HelpdeskService';
import TechnicalSupport from '../technicalSupport/TechnicalSupport';
import './serviceContent.css';

import ServiceAPIcon from '../../assets/ServiceAPIcon';
import ServiceCSIcon from '../../assets/ServiceCSIcon';
import ServiceHDSIcon from '../../assets/ServiceHDSIcon';
import ServiceOCCSIcon from '../../assets/ServiceOCCSIcon';
import ServiceTSIcon from '../../assets/ServiceTSIcon';
import SideMenuContent from '../../SideMenuContent';
import OpmCloud from '../opmCloud/OpmCloud';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../common.styles';

const SECTIONS = {
  APPLICATION_DEVELOPMENT: 'APPLICATION_DEVELOPMENT',
  CONSULTING_SERVICE: 'CONSULTING_SERVICE',
  TECHNICAL_SUPPORT: 'TECHNICAL_SUPPORT',
  OPM_CLOUD_COMPUTING_SERVICES: 'OPM_CLOUD_COMPUTING_SERVICES',
  HELP_DESK_SERVICES: 'HELP_DESK_SERVICES',
}

const menuItems = [
  { label: 'Applications\nDevelopment', icon: <ServiceAPIcon />, element: SECTIONS.APPLICATION_DEVELOPMENT },
  { label: 'Consulting\nServices', icon: <ServiceCSIcon />, element: SECTIONS.CONSULTING_SERVICE },
  { label: 'Technical\nSupport', icon: <ServiceTSIcon />, element: SECTIONS.TECHNICAL_SUPPORT },
  { label: 'OPM Cloud\nComputing Services', icon: <ServiceOCCSIcon />, element: SECTIONS.OPM_CLOUD_COMPUTING_SERVICES },
  { label: 'Help Desk Services', icon: <ServiceHDSIcon />, element: SECTIONS.HELP_DESK_SERVICES },
]

const ServiceContent = () => {

  const contentHashes = {
    '#Application-Development': null,
    '#Consulting-Services': null,
    '#Technical-Support': null,
    '#OPM-Cloud-Computing-Services': null,
    '#Help-Desk-Services': null,
  }

  const isMobile = useMediaQuery({ query: device.mobileL });

  const refreshScroll = () => {
    var hash = window.location.hash;
    if (hash && contentHashes[hash] instanceof HTMLElement) {
      if (isMobile) {
        setTimeout(() => {
          const headerOffset = 80;
          const elementPosition = contentHashes[hash].getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({ top: offsetPosition });
        }, 100);
      } else {
        contentHashes[hash].scrollIntoView();
      }
    }
  }

  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      refreshScroll();
    }
  };

  return (
    <SideMenuContent menuTitle="Services" menuItems={menuItems}>
      <Element name={SECTIONS.APPLICATION_DEVELOPMENT}>
        <div ref={(v) => { contentHashes[`#Application-Development`] = v; }}></div>
        <ApplicationDev />
      </Element>
      <Element name={SECTIONS.CONSULTING_SERVICE}>
        <div ref={(v) => { contentHashes[`#Consulting-Services`] = v; }}></div>
        <ConsultingService />
      </Element>
      <div ref={(v) => { contentHashes[`#Technical-Support`] = v; }}></div>
      <Element name={SECTIONS.TECHNICAL_SUPPORT}>
        <TechnicalSupport />
      </Element>
      <div ref={(v) => { contentHashes[`#OPM-Cloud-Computing-Services`] = v; }}></div>
      <Element name={SECTIONS.OPM_CLOUD_COMPUTING_SERVICES}>
        <OpmCloud />
      </Element>
      <div ref={(v) => { contentHashes[`#Help-Desk-Services`] = v; }}></div>
      <Element name={SECTIONS.HELP_DESK_SERVICES}>
        <HelpDeskService />
      </Element>
    </SideMenuContent>
  )
}

export default ServiceContent

// export default function serviceContent() {

// }
import React from 'react';
import { Helmet } from "react-helmet";
import styled from 'styled-components';
import navBg from '../assets/navBg.png';
import OPMLogo from '../assets/OPMLogo';
import { device, SectionHeading, SectionSubheading, Text } from '../common.styles';
import TopButton from '../components/TopButton/TopButton';
import { Footer, Header } from '../containers';

const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const SitemapHeading = styled(SectionHeading)`
  font-family: AB;
  font-weight: bold;
  text-align: left;
  color: #343434;
  padding-left: 12%;
`;

const SitemapSection = styled.div`
  padding: 0 12%;
`;

const SitemapSectionHeading = styled(SectionSubheading)`
  font-size: ${props => props.theme.fontSize.sectionSubheading * 1.4}px !important;
  font-family: HB;
  font-weight: bold;
  margin-bottom: 16px;
  color: #2baee5;

  @media only screen and ${device.mobileL} {
    font-size: ${props => props.theme.fontSize.mobileSectionSubheading * 1.4}px !important;
  }
`;

const SitemapSectionContentRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2.7px;
  padding-bottom: 16px;
  gap: 16px;

  @media only screen and ${device.mobileL} {
    display: inline;
  }
`;

const SitemapSectionContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.3%;

  @media only screen and ${device.mobileL} {
    width: 100%;
  }
`;

const SitemapDescHeading = styled(Text)`
  font-family: HB;
  font-weight: bold;
  color: #3c3c3b;
`;

const SitemapDesc = styled(Text)`
  color: #3c3c3b;
`;

const Slogan1 = styled.div`
  font-family: AL;
  font-size: 88px;
  color: #343434;
  line-height: 1.26;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const Slogan2 = styled.div`
  font-family: AB;
  font-size: 88px;
  line-height: 1.26;
  color: #343434;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const StyledTopButton = styled.div`
  margin-left: 8%;
  margin-top: 24px;
  margin-bottom: 24px;
`;


const SiteMap = () => {



  const productHash = {
    '#iBrokers': null,
    '#iTrading': null,
    '#PRIME': null,
    '#LDAP': null,
    '#CMSmart': null,
    '#iStorage': null,
    '#DIGITTRADE': null,
    '#nFront-Password-Filiter': null,
    '#nFront-Web-Password-Change': null,
    '#nFront-Account-Disabler': null,
  }
  const handleClick = event => {
    window.scrollTo(0, 0)
  };
  return (

    <>
      <div className='App'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>SiteMap - Welcome to OPM System Company Limited</title>
          <meta name="Site Map" content="OPM - Site Map" />
        </Helmet>
        <div className="gradient__bg">
          {/* <Navbar/> */}
          <Header bgImg={navBg}>
            <StyledOPMLogo />
            <Slogan1>WE PARTNER FOR</Slogan1>
            <Slogan2>A BRIGHT FUTURE</Slogan2>
          </Header>

          <div style={{ padding: '100px 0' }}>
            <SitemapHeading>Sitemap</SitemapHeading>

            <br />

            <br />

            <br />

            <SitemapSection>

              <a href='/'><SitemapSectionHeading >Home</SitemapSectionHeading></a>

              <SitemapSectionContentRow>

                <SitemapSectionContentColumn>

                  <a href='/#WhyOPM'><SitemapDesc>Why OPM</SitemapDesc></a>

                  <a href='/#Services'><SitemapDesc>Services</SitemapDesc></a>

                  <a href='/#Products'><SitemapDesc>Products</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn>

                  <a href='/#Solution'><SitemapDesc>Solutions</SitemapDesc></a>

                  <a href='/#BusinessPartner'><SitemapDesc>Business Partner</SitemapDesc></a>

                </SitemapSectionContentColumn>

              </SitemapSectionContentRow>

            </SitemapSection>

            <br />

            <br />

            <SitemapSection>

              <a href='/pages/WhyOPM'><SitemapSectionHeading >Why OPM</SitemapSectionHeading></a>

              <SitemapSectionContentRow>

                <SitemapSectionContentColumn>

                  <a href='/pages/WhyOPM#OpmInfo'><SitemapDesc>OPM</SitemapDesc></a>

                  <a href='/pages/WhyOPM#OpmExperience'> <SitemapDesc>Experience</SitemapDesc></a>

                  <a href='/pages/WhyOPM#OpmApproach'><SitemapDesc>Approach</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn className='sitemap_sub_content_frame'>

                  <a href='/pages/WhyOPM#OpmMethod'><SitemapDesc>Method</SitemapDesc></a>

                  <a href='/pages/WhyOPM#OpmAward'><SitemapDesc>Awards</SitemapDesc></a>

                </SitemapSectionContentColumn>

              </SitemapSectionContentRow>

            </SitemapSection>

            <StyledTopButton><TopButton /></StyledTopButton>

            <SitemapSection>

              <a href='/pages/Services'> <SitemapSectionHeading >Services</SitemapSectionHeading></a>

              <SitemapSectionContentRow>

                <SitemapSectionContentColumn>

                  <a href='/pages/Services#Application-Development' ><SitemapDesc>Applications Development</SitemapDesc></a>

                  <a href='/pages/Services#Consulting-Services'>  <SitemapDesc>Consulting Services</SitemapDesc></a>

                  <a href='/pages/Services#Technical-Support'>  <SitemapDesc>Technical Support</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn>

                  <a href='/pages/Services#OPM-Cloud-Computing-Services'>  <SitemapDesc>OPM Cloud Computing Services</SitemapDesc></a>

                  <a href='/pages/Services#Help-Desk-Services'>  <SitemapDesc>Help Desk Services</SitemapDesc></a>

                </SitemapSectionContentColumn>

              </SitemapSectionContentRow>

            </SitemapSection>

            <br />

            <br />

            <SitemapSection>

              <a href='/pages/Products'><SitemapSectionHeading >Products</SitemapSectionHeading></a>

              <SitemapSectionContentRow>

                <SitemapSectionContentColumn>

                  <a href='/pages/Products#iBrokers'><SitemapDesc>iBrokers</SitemapDesc></a>

                  <a href='/pages/Products#iTrading'> <SitemapDesc>iTrading</SitemapDesc></a>

                  <a href='/pages/Products#PRIME'> <SitemapDesc>PRIME</SitemapDesc></a>

                  <a href='/pages/Products#LDAP'> <SitemapDesc>LDAP PROTECT</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn>

                  <a href='/pages/Products#CMSmart'><SitemapDesc>CMSmart</SitemapDesc></a>

                  <a href='/pages/Products#iStorage'> <SitemapDesc>iStorage</SitemapDesc></a>

                  <a href='/pages/Products#DIGITTRADE'> <SitemapDesc>DIGITTRADE</SitemapDesc></a>

                  <a href='/pages/Products#nFront-Password-Filiter'><SitemapDesc>nFront Password Filter</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn>

                  <a href='/pages/Products#nFront-Web-Password-Change'> <SitemapDesc>nFront Web Password Change</SitemapDesc></a>

                  <a href='/pages/Products#nFront-Account-Disabler'> <SitemapDesc>nFront Account Disabler</SitemapDesc></a>

                </SitemapSectionContentColumn>

              </SitemapSectionContentRow>

            </SitemapSection>

            <StyledTopButton><TopButton /></StyledTopButton>

            <SitemapSection>

              <a href='/pages/Solutions'><SitemapSectionHeading >Solutions</SitemapSectionHeading></a>

              <SitemapSectionContentRow>

                <SitemapSectionContentColumn>

                  <a href='/pages/Solutions#SECURITY'> <SitemapDescHeading>Security</SitemapDescHeading></a>

                  <a href='/pages/Solutions#SECURITY_IAM'> <SitemapDesc>Identity & Access Management</SitemapDesc></a>

                  <a href='/pages/Solutions#SECURITY_EPSS'> <SitemapDesc>End Point Security Solution</SitemapDesc></a>

                  <a href='/pages/Solutions#SECURITY_SSO'>  <SitemapDesc>Single Sign-on (SSO)</SitemapDesc></a>

                  <a href='/pages/Solutions#SECURITY_MFA'>  <SitemapDesc>Multi-factor Authentication</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn>

                  <a href='/pages/Solutions#END_USER'> <SitemapDescHeading> End User</SitemapDescHeading></a>

                  <a href='/pages/Solutions#END_USER_PORTAL'>  <SitemapDesc>End User Portal Solution</SitemapDesc></a>

                  <a href='/pages/Solutions#MOBILE_ENTERPRISE'> <SitemapDesc>Mobile Enterprise Solution</SitemapDesc></a>

                  <a href='/pages/Solutions#VIRTUAL_DESKTOP'>  <SitemapDesc>Virtual Desktop Infrastructure (VDI)</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn>

                  <a href='/pages/Solutions#BY_INDUSTRY'> <SitemapDescHeading>By Industry</SitemapDescHeading></a>

                  <a href='/pages/Solutions#HEALTH_CARE'>  <SitemapDesc>Health Care Solution</SitemapDesc></a>

                  <a href='/pages/Solutions#CASE_MANAGEMENT'>  <SitemapDesc>Case Management System</SitemapDesc></a>

                  <a href='/pages/Solutions#CALL_LOG_SYSTEM'>  <SitemapDesc>Call Log System</SitemapDesc></a>

                  <a href='/pages/Solutions#BOOKING_SYSTEM'>  <SitemapDesc>Booking System</SitemapDesc></a>

                  <a href='/pages/Solutions#STREAMING_SYSTEM'>  <SitemapDesc>Interactive Live Streaming System</SitemapDesc></a>

                </SitemapSectionContentColumn>

              </SitemapSectionContentRow>

            </SitemapSection>

            <br />

            <br />

            <SitemapSection>

              <SitemapSectionContentRow>

                <SitemapSectionContentColumn>

                  <a href='/pages/Solutions#BLOCKCHAIN'>  <SitemapDescHeading>Blockchain</SitemapDescHeading></a>

                  <a href='/pages/Solutions#AUTHENTICATION'>  <SitemapDesc>Authentication</SitemapDesc></a>

                  <a href='/pages/Solutions#DATA_PROTECTION'>  <SitemapDesc>Data Protection</SitemapDesc></a>

                  <a href='/pages/Solutions#TRANSACTION'>  <SitemapDesc>Transaction Processing</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn>

                  <a href='/pages/Solutions#AI'><SitemapDescHeading>Artificial Intelligence (AI)</SitemapDescHeading></a>

                  <a href='/pages/Solutions#NLP'>  <SitemapDesc>Natural Language Processing (NLP)</SitemapDesc></a>

                  <a href='/pages/Solutions#CHATBOT'>  <SitemapDesc>ChatBot</SitemapDesc></a>

                  <a href='/pages/Solutions#OPTICAL_CHRACTER'>  <SitemapDesc>Optical Character Recognition (OCR)</SitemapDesc></a>

                  <a href='/pages/Solutions#IMAGE_RECOGNITION'>  <SitemapDesc>Image Recognition</SitemapDesc></a>

                </SitemapSectionContentColumn>

                <SitemapSectionContentColumn>

                  <a href='/pages/Solutions#HYBRID_CLOUD_INFRA'><SitemapDescHeading>Hybrid Cloud Infrastructure</SitemapDescHeading></a>

                  <a href='/pages/Solutions#K8S'>  <SitemapDesc>Kubernetes (K8s)</SitemapDesc></a>

                  <a href='/pages/Solutions#PERSIST_STORAGE'>  <SitemapDesc>Persistent Storage</SitemapDesc></a>

                  <a href='/pages/Solutions#PRIVATE_REGISTRY'>  <SitemapDesc>Private Registry</SitemapDesc></a>

                  <a href='/pages/Solutions#ORCHESTRATOR'>  <SitemapDesc>Orchestrator</SitemapDesc></a>

                </SitemapSectionContentColumn>

              </SitemapSectionContentRow>

            </SitemapSection>

            <StyledTopButton><TopButton /></StyledTopButton>

            <SitemapSection>

              <a href='/pages/ContactUs'><SitemapSectionHeading >Contact Us</SitemapSectionHeading></a>

            </SitemapSection>

            <br />

            <br />

            <SitemapSection>

              <a href='/pages/JoinUs'><SitemapSectionHeading >Join Us</SitemapSectionHeading></a>

            </SitemapSection>

          </div>



        </div>

        <Footer />
      </div >

    </>
  )
}

export default SiteMap
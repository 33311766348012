import styled from "styled-components";
import { device, Text } from "../../common.styles";

const Wrapper = styled.div`
    position: relative;
    padding: 8px;
    padding-top: 28px;
    box-sizing: border-box;
    width: 50%;
    display: flex;
    vertical-align: top;

    @media only screen and ${device.mobileL} {
        width: 100%;
    }
`;

const Box = styled.div`
    position: relative;
    border-bottom: 2px solid #ccc;
    width: 100%;
`

const Content = styled.div`
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .thumb {
        width: 200px;
        padding: 8px;
        box-sizing: border-box;

        @media only screen and ${device.mobileL} {
            width: 150px;
        }
    }

    .desc {
        width: calc(100% - 200px);
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;
        padding-left: 16px;

        @media only screen and ${device.mobileL} {
            width: calc(100% - 150px);
        }

        p {
            font-size: 14px !important;
            line-height: 140%;
        }
    }
`;

const Logo = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 34px;
    object-fit: contain;
    .product-logo {
        transform-origin: right center;
        transform: scale(0.5);
    }
`;

const DecoCircle = styled.div`
    position: absolute;
    bottom: -4px;
    right: 20px;
    background-color: white;
    width: 20px;
    height: 8px;

    &::after {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -4px;
        width: 8px;
        height: 8px;
        border-radius: 10px;
        display: block;
        content: ' ';
        background-color: #ccc;
    }
`;

const StyledText = styled(Text)`
    color: #676767;
`;

const IStorageProductGrid = ({ thumb, logo, pointsArr = [] }) => (
    <Wrapper>
        <Box>
            <Content>
                <div className="thumb">
                    <img src={thumb} alt="" width="100%" />
                </div>
                <div className="desc">
                    <ul>
                        {pointsArr.map((point, index) => <li key={index}><StyledText>{point}</StyledText></li>)}
                    </ul>
                </div>
            </Content>
            <DecoCircle />
        </Box>
        <Logo>
            <img className="product-logo" src={logo} alt="" />
        </Logo>
    </Wrapper>
)

export default IStorageProductGrid;
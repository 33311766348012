import React from 'react'




import ldapBenefit1 from '../../assets/ldap_benefit1.png'

import pdfLdap from '../../assets/ldap_product_download.png'

import styled from 'styled-components'
import LdapIcon1 from '../../assets/LDAP_Protect_01.png'
import LdapIcon2 from '../../assets/LDAP_Protect_02.png'
import LdapIcon3 from '../../assets/LDAP_Protect_03.png'
import LdapIcon4 from '../../assets/LDAP_Protect_04.png'
import ldapPdfFilr from '../../assets/pdf/ldap-project.pdf'
import { Heading, InnerWrapper, Text } from '../../common.styles'
import DecoHeading from '../../DecoHeading'
import ImageDescBlock from '../../ImageDescBlock'
import TopButton from '../TopButton/TopButton'
import './lDapProtect.css'
import LDapProtectKeyFeaturesGrid from './LDapProtectKeyFeaturesGrid'
const StyledHeading = styled(Heading)`
    text-align: center;
`;

const Section = styled.div`
    text-align: center;
`;

const FeaturesBox = styled.div`
    max-width: 80%;
    width: 100%;
    margin: auto;
`;

const FeatureButtonBox = styled.div`
    max-width: 80%;
    width: 100%;
    margin: 32px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 32px;
`;

const FeatureButton = styled.div`
    position: relative;
    border: 2px solid #4f7ac7;
    display: inline-flex;
    height: 85.9px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;

    div {
        padding: 0 8px;
        box-sizing: border-box;

        &::before {
            position: absolute;
            top: -12px;
            right: -30px;
            width: 70px;
            height: 35px;
            background-color: white;
            display: block;
            content: ' ';
            transform: rotate(45deg);
        }

        &::after {
            position: absolute;
            top: -4px;
            right: 35px;
            width: 8px;
            height: 8px;
            background-color: #4f7ac7;
            border-radius: 50px;
            display: block;
            content: ' ';
            transform: rotate(-45deg);
        }
    }

    &.second {
        width: 66.666%;
    }

    &::before {
        position: absolute;
        top: -10px;
        left: -12px;
        width: 25px;
        height: 20px;
        border-bottom: 2px solid #4f7ac7;
        background-color: white;
        display: block;
        content: ' ';
        transform: rotate(-45deg);
    }

    &::after {
        position: absolute;
        bottom: -10px;
        right: -12px;
        width: 25px;
        height: 20px;
        border-top: 2px solid #4f7ac7;
        background-color: white;
        display: block;
        content: ' ';
        transform: rotate(-45deg);
    }
`;

const StyledText = styled(Text)`
    font-family: HB;
    font-weight: bold;
    color: #2e5fbe;
    text-align: center;
`;

const LDapProtect = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <InnerWrapper>
                <DecoHeading heading="LDAP PROTECT" subheading="An appliance that acts as middleware layer among LDAP clients, servers and other directory services." />
                <Section>
                    <br />
                    <StyledHeading>
                        Features
                    </StyledHeading>
                    <br />
                    <FeaturesBox>
                        <LDapProtectKeyFeaturesGrid icon={<img style={{ width: `30%` }} src={LdapIcon1} />} text="Periodic health checks to detect unavailable or slow back-end servers" />
                        <LDapProtectKeyFeaturesGrid icon={<img style={{ width: `30%` }} src={LdapIcon2} />} text="Hide LDAP schema from outsider, and Identity-based policy on LDAP access control" />
                        <LDapProtectKeyFeaturesGrid icon={<img style={{ width: `30%` }} src={LdapIcon3} />} text="LDAP Firewall, provides dynamic load balancing to various LDAP servers (e.g. AD, eDirectory, LDAP)" />
                        <LDapProtectKeyFeaturesGrid icon={<img style={{ width: `30%` }} src={LdapIcon4} />} text="Realtime monitoring and logging" />
                    </FeaturesBox>
                    <FeatureButtonBox>
                        <FeatureButton>
                            <StyledText>Enhanced security</StyledText>
                        </FeatureButton>
                        <FeatureButton>
                            <StyledText>High availability</StyledText>
                        </FeatureButton>
                        <FeatureButton>
                            <StyledText>Enhanced scalability</StyledText>
                        </FeatureButton>
                        <FeatureButton>
                            <StyledText>Direct access control<br />to directory services</StyledText>
                        </FeatureButton>
                    </FeatureButtonBox>
                </Section>
                <br />
                <br />
                <ImageDescBlock image={ldapBenefit1} title="Benefits">
                    <ul>
                        <li><Text>Configure easily</Text></li>
                        <li><Text>Seamless integration to most LDAP directory</Text></li>
                        <li><Text>Flexible and central network restriction to existing LDAP services</Text></li>
                        <li><Text>Strengthen LDAP Security Protection</Text></li>
                        <li><Text>Strong auditing on LDAP traffic</Text></li>
                    </ul>
                </ImageDescBlock>
                <Section>
                    <br />
                    <a href={ldapPdfFilr} style={{ alignSelf: `center` }} target="_blank"><img src={pdfLdap} alt="" width={'37%'} /></a>
                </Section>
                <TopButton />
            </InnerWrapper>
        </div>
    )
}

export default LDapProtect
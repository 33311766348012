import React from 'react'





import microLogo from '../../assets/microLogo.png'
import mult1 from '../../assets/mult1.png'
import mult2 from '../../assets/mult2.png'
import secudeLogo from '../../assets/secudeLogo.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'

import styled from 'styled-components'
import bl from '../../assets/bottomline.png'
import SolutionSecurityIcon from '../../assets/SolutionSecurityIcon'
import { device, InnerWrapper, Text } from '../../common.styles'
import DecoHeading from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import TopButton from '../TopButton/TopButton'
import './multiFactor.css'

const ContenText1 = styled(Text)`
    text-align: center;
    margin: 50px 0;

    @media only screen and ${device.mobileL} {
        margin: 25px 0;
    }
`;

const ContenText2 = styled(Text)`
    text-align: center;
    margin-top: 90px;

    @media only screen and ${device.mobileL} {
        margin-top: 25px;
    }
`;

const MultImg2 = styled.img`
    width: 100%;
    max-width: 1000px;
    align-self: center;
    margin: 80px 0;

    @media only screen and ${device.mobileL} {
        margin: 25px 0;
    }
`;

const MultiFactor = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading desc="Multi-Factor Authentication" icon={<SolutionSecurityIcon />} />

                <ContenText1 className='subContent_hr'>We can establish the multi-factor Authentication such as Face Recognition Smart Card,<br />
                    Push Message, SMS, OTP etc. To increase the security and control the authentication.</ContenText1>


                <div style={{ padding: '0px 16px', boxSizing: 'border-box', textAlign: 'center' }}>
                    <img src={mult1} style={{ width: '100%', maxWidth: '1000px', alignSelf: `center` }} alt="" />
                </div>

                <ContenText2 className='subContent_hr'>The solution can integration with Windows Desktop, Network Equipment, Mobile, Websites etc</ContenText2>

                <div style={{ padding: '16px', boxSizing: 'border-box', textAlign: 'center' }}>
                    <MultImg2 src={mult2} alt="" />
                </div>
            </InnerWrapper>

            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <div>
                        <a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'><img src={microLogo} alt="" height="50" style={{ marginRight: '20px' }} /></a>
                        <a target='_blank' href='https://secude.com/'><img src={secudeLogo} alt="" height="50" /></a>
                    </div>
                </div>
                <div><img src={bl} alt="" style={{ width: `80%`, marginTop: `20px`, marginBottom: `20px`, objectFit: `contain` }} /></div>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <div>
                        <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                        <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>
                    </div>
                </div>
            </SectionBlock>

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default MultiFactor
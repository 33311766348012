import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 120px;
  height: 120px;

  @media only screen and ${device.mobileL} {
    width: 42px;
    height: 42px;
  }
  
  svg {
    fill:#343434;
  }
`;

const SolutionAuth6Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 133.016 133.016">
      <g data-name="Group 1612" transform="translate(-1206.263 -3283.555)">
        <g data-name="Group 1611">
          <path data-name="Path 1104" d="M1302.778 3327.389a.877.877 0 0 0-.58-.676.887.887 0 0 0-.878.183c-.02.017-2 1.751-7.81 1.751a37.47 37.47 0 0 1-4.772-.331c-10.181-1.312-14.652-5.793-14.694-5.836a.875.875 0 0 0-1.257 0c-.044.045-4.516 4.525-14.7 5.838a37.4 37.4 0 0 1-4.771.331c-5.806 0-7.79-1.734-7.8-1.742a.874.874 0 0 0-1.468.484c-.063.372-5.861 37.412 28.928 57.586a.881.881 0 0 0 .877 0c34.786-20.177 28.988-57.216 28.925-57.588zm-29.365 51.921c-12.054-7.62-19.826-18.282-23.107-31.7a57.319 57.319 0 0 1-1.609-14.244 34.953 34.953 0 0 0 10.039-.019 35.392 35.392 0 0 0 14.678-5 35.388 35.388 0 0 0 14.675 5 34.961 34.961 0 0 0 10.035.019c.114 9.848-2.38 31.79-24.711 45.944z" />
          <g data-name="Group 1610">
            <path data-name="Path 1105" d="M1285.352 3343.027a2.889 2.889 0 0 0-4.282 0l-11.427 12.232-3.508-3.754a2.889 2.889 0 0 0-4.282 0 3.4 3.4 0 0 0 0 4.584l5.392 5.771a3.577 3.577 0 0 0 4.8 0l13.311-14.249a3.4 3.4 0 0 0-.004-4.584z" />
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
);

export default SolutionAuth6Icon;




import styled from 'styled-components';

export const fontSizeArr = [{
  heading: 25,
  sectionHeading: 45,
  sectionDesc: 35,
  sectionSubheading: 12.5,
  body: 11,
  mobileHeading: 16,
  mobileSectionHeading: 26,
  mobileSectionDesc: 18,
  mobileSectionSubheading: 8,
  mobileBody: 12
}, {
  heading: 37.5,
  sectionHeading: 67.5,
  sectionDesc: 52.5,
  sectionSubheading: 18.75,
  body: 16.5,
  mobileHeading: 18,
  mobileSectionHeading: 28,
  mobileSectionDesc: 20,
  mobileSectionSubheading: 10,
  mobileBody: 14
}, {
  heading: 50,
  sectionHeading: 90,
  sectionDesc: 70,
  sectionSubheading: 25,
  body: 22,
  mobileHeading: 20,
  mobileSectionHeading: 30,
  mobileSectionDesc: 22,
  mobileSectionSubheading: 12,
  mobileBody: 16
}];

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '767px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.laptop})`,
  laptop: `(min-width: ${size.tablet + 1} and (max-width: ${size.laptop}))`,
  laptopL: `(min-width: ${size.laptop + 1} and (max-width: ${size.laptopL}))`,
  desktop: `(min-width: ${size.laptopL + 1} and (max-width: ${size.desktop}))`,
  desktopL: `(min-width: ${size.desktop})`
};

export const Heading = styled.h2`
    font-family: AB;
    font-size: ${props => props.theme.fontSize.heading}px !important;
    font-weight: bold;

    @media only screen and ${device.mobileL} {
      font-size: ${props => props.theme.fontSize.mobileHeading}px !important;
    }
`;

export const SectionHeading = styled.h3`
    font-family: AB;
    font-size: ${props => props.theme.fontSize.sectionHeading}px !important;
    font-weight: bold;
    text-align: left;
    color: #4f7ac7;
    line-height: 1;
    overflow: hidden;

    @media only screen and ${device.mobileL} {
      font-size: ${props => props.theme.fontSize.mobileSectionHeading}px !important;
    }
`;

export const SectionDesc = styled.h4`
    font-family: AL;
    font-size: ${props => props.theme.fontSize.sectionDesc}px !important;
    font-weight: 300;
    text-align: left;
    color: #4f7ac7;
    overflow: hidden;

    @media only screen and ${device.mobileL} {
      font-size: ${props => props.theme.fontSize.mobileSectionDesc}px !important;
    }
`;

export const SectionSubheading = styled.h5`
    font-family: AL;
    font-size: ${props => props.theme.fontSize.sectionSubheading}px !important;
    font-weight: 300;
    text-align: left;
    color: #1d1d1b;
    overflow: hidden;

    @media only screen and ${device.mobileL} {
      font-size: ${props => props.theme.fontSize.mobileSectionSubheading}px !important;
    }
`;

export const Text = styled.div`
  font-size: ${props => props.theme.fontSize.body}px !important;
  font-family: HR;
  font-weight: normal;
  line-height: 1.59;

  @media only screen and ${device.mobileL} {
    font-size: ${props => props.theme.fontSize.mobileBody}px !important;
  }
`;

export const TextLight = styled.div`
  font-size: ${props => props.theme.fontSize.body}px !important;
  font-family: HL;
  font-weight: normal;
  line-height: 1.59;

  @media only screen and ${device.mobileL} {
    font-size: ${props => props.theme.fontSize.mobileBody}px !important;
  }
`;

export const InnerWrapper = styled.div`
    padding: 16px;
    box-sizing: border-box;
`;

export const PreviewBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
`;
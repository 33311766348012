
import TrackVisibility from 'react-on-screen'
import { withTheme } from 'styled-components'
import Image2 from '../../assets/whyopm/Why OPM-Experience photo.jpg'
import Image1 from '../../assets/whyopm/Why OPM-whyopm photo.jpg'
import { device, Heading, Text } from '../../common.styles'
import './OpmInfo.css'
// import { useInView } from 'react-intersection-observer';
import * as React from "react"
import { useState } from "react"
import { useMediaQuery } from 'react-responsive'



const VisibilityWrapper = ({
    children,
}) => {
    const [isInitial, setIsInitial] = useState(false);

    return (
        <>
            <TrackVisibility partialVisibility>
                {
                    ({ isVisible }) => {
                        if (!isInitial && !!isVisible) {

                            setIsInitial(true);
                        }
                        return (
                            <div className={`fade-in-section ${isInitial ? 'is-visible' : ''}`}>
                                {children}
                            </div>
                        )
                    }
                }
            </TrackVisibility>
        </>
    )
}



const OpmInfo = (props) => {
    const { contentHashes } = props;
    return (
        <div className="info-section section">
            <div className="item">
                <div className="text-wrapper">
                    <div ref={(v) => { contentHashes[`#OpmInfo`] = v; }}></div>
                    <VisibilityWrapper>
                        <>
                            <Heading className="abStyle">OPM</Heading>
                            <Text className="hrStyle">
                                OPM Systems Company Limited is one of the leading solutions provider on Identity and Access Management(IAM).<br />
                                We provide comprehensive deployment services to various
                                network security solutions, software applications development and maintenance services to commercial corporations,
                                government, semi-government sectors and education institutions.<br />
                                We have an experienced team in solutions implementation and project management who help develop and deploy sophisticated business solutions that enhances business operations.
                            </Text>

                        </>
                    </VisibilityWrapper>
                </div>
                <div className="img-wrapper">
                    <img className='thumb' src={Image1}></img>
                </div>
            </div>

            <div className="item">
                <div className="text-wrapper">
                    <div ref={(v) => { contentHashes[`#OpmExperience`] = v; }}></div>
                    <VisibilityWrapper>
                        <>
                            <Heading className="abStyle">Experience</Heading>
                            <Text className="text-22 desc-text hrStyle">
                                With over 4 years experience, OPM has a strong track record in deploying and developing e-business solutions for corporate customers and government departments.<br />
                                Our staff in the professional service division have more than 15 years of experience in recommending, designing and
                                implementing different business solutions and products to our customer environments.

                            </Text>
                        </>
                    </VisibilityWrapper>
                </div>
                <div className="img-wrapper">
                    <img className='thumb' src={Image2}></img>
                </div>
            </div>



        </div>
    );
}

export default withTheme(OpmInfo)
import { DropDownList } from "@progress/kendo-react-dropdowns";
import styled from "styled-components";
import { Heading } from "../../common.styles";
import "./dropdown.css";




// Dropdown categories  
const categories = [
    { id: null, label: "All categories" },
    { id: "Engineer", label: "Engineer" },
    { id: "Administrator", label: "Administrator" },
];

const StyledHeading = styled(Heading)`
    color: #e1001e;
`;

export const RecipeDropDownList = ({
    category,
    setCategory,
}) => {

    return (
        <section className="joinUs-filter-item">
            <form className="k-form">
                <StyledHeading>Categories</StyledHeading>
                <DropDownList value={(category || categories[0]).label} data={categories.map((it) => { return it.label })} onChange={e => {
                    let selectedCategory = categories.filter((c) => {
                        return c.id != null
                            && c.label === e.value;
                    })
                    if (selectedCategory.length > 0) {
                        setCategory(selectedCategory[0])
                    } else {
                        setCategory(null);
                    }
                }} />
            </form>
        </section>
    );
};
import React from 'react'





import microLogo from '../../assets/microLogo.png'
import secudeLogo from '../../assets/secudeLogo.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'
import ssologo from '../../assets/ssologo.png'

import './sso.css'

import bl from '../../assets/bottomline.png'
import SolutionSecurityIcon from '../../assets/SolutionSecurityIcon'
import { Heading, InnerWrapper, Text } from '../../common.styles'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import TopButton from '../TopButton/TopButton'


const SSO = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <InnerWrapper>
                <DecoHeading desc="Single Sign-on [SSO]" icon={<SolutionSecurityIcon />} />

                <div className='applicationline'>
                    <img src={ssologo} style={{ width: `100%`, maxWidth: '1000px' }} />
                </div>
                <DecoDesc1>
                    The challenge many organizations facing too many of password as well as strong password, it keeps on
                    administrative effort for maintenance password. Adopt of Single Sign-On it can reduce the administration
                    effort and better on user experience. Also we provide a passwordless option to customer.
                </DecoDesc1>


                <Heading style={{ marginBottom: `30px`, textAlign: `center` }} className='iBroker_webview_preview_style'>Features</Heading>

                <Text style={{ marginBottom: `30px`, textAlign: `center`, width: `100%` }} className='subContent_hr'>A portal content all of the required application, once log-ed to the portal.
                    Users can base on his/her roles for access the difference applications without password.</Text>

            </InnerWrapper>


            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'><img src={microLogo} alt="" height="50" style={{ marginRight: '20px' }} /></a>
                    <a target='_blank' href='https://secude.com/'><img src={secudeLogo} alt="" height="50" /></a>
                </div>
                <div><img src={bl} alt="" style={{ width: `80%`, marginTop: `20px`, marginBottom: `20px`, objectFit: `contain` }} /></div>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <div>
                        <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                        <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>
                    </div>
                </div>
            </SectionBlock>

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default SSO
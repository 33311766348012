

import awardsImage from '../../assets/whyopm/Awards-bg1.png';
import { Heading } from '../../common.styles';
import "./OpmAward.css";


import * as React from "react";
import { useState } from "react";
import TrackVisibility from 'react-on-screen';



const VisibilityWrapper = ({
    children,
}) => {
    const [isInitial, setIsInitial] = useState(false);

    return (
        <>
            <TrackVisibility partialVisibility>
                {
                    ({ isVisible }) => {
                        if (!isInitial && !!isVisible) {

                            setIsInitial(true);
                        }
                        return (
                            <div className={`fade-in-section ${isInitial ? 'is-visible' : ''}`} style={{ backgroundColor: 'white' }}>
                                {children}
                            </div>
                        )
                    }
                }
            </TrackVisibility>
        </>
    )
}


const OpmAward = () => {
    return (

        <VisibilityWrapper>
            <>
                <Heading style={{ textAlign: `center`, color: `#343434` }}>Awards</Heading>
                <img className="thumb" src={awardsImage}></img>
            </>

        </VisibilityWrapper>


    );
}

export default OpmAward
import React from 'react'
import './partner.css'

import styled, { withTheme } from 'styled-components'
import { device, SectionHeading, Text } from '../../common.styles'
import TopButton from '../../components/TopButton/TopButton'
import businessPartnerImg from '../../assets/img_business_partner.png'

const PartnerDesc = styled.p`
  padding-left: 130px;
  padding-right: 130px;
  max-width: ${props => props.theme.maxWidth}px;
  margin: auto;
  font-size: ${props => props.theme.fontSize.body}px;
  font-family: HR;
  text-align: center;

  @media only screen and ${device.mobileL} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const OpmPartner1 = styled.div`
padding-bottom: 50px;
img {
  object-fit: contain;
}

@media only screen and ${device.mobileL} {
    padding: 0 16px 16px;
    img {
      width: 100% !important;
    }
  }
`;

const StyledSectionHeading = styled(SectionHeading)`
  font-family: AR;
  font-weight: normal;
  text-align: center;
  color: #343434;
`;

const StyledText = styled(Text)`
  text-align: center;
  color: #343434;
`;

const Partner = () => {
  const handleClick = event => {
    window.scrollTo(0, 0)
  };

  return (
    <div className='opm_partner'>
      <TopButton marginLeft={148} marginTop={52} marginLeftMobileL={48} marginTopMobileL={52} />
      <StyledSectionHeading>Business Partner</StyledSectionHeading>
      <br />
      <br />
      <PartnerDesc><StyledText> At OPM, we pride ourselves in our co-operation with other business partners. We have established strong links and business partnership with companies that are internationally renowned. As we continue in our role as one of the leading solutions provider in Identity and Access
        Management our portfolio of business partners will continue to grow.</StyledText></PartnerDesc>

      <OpmPartner1 className='opm_partner1'>
        <img className="center" width="100%" src={businessPartnerImg} alt="" />
      </OpmPartner1>
    </div >
  )
}

export default withTheme(Partner)
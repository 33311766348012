import React from 'react'





import cs from '../../assets/cs.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'

import styled from 'styled-components'
import SolutionHybridIcon from '../../assets/SolutionHybridIcon'
import { device, InnerWrapper, Text } from '../../common.styles'
import DecoHeading1 from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import TopButton from '../TopButton/TopButton'
import './hybrid.css'

const ContactUsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    @media only screen and ${device.mobileL} {
        height: 100px;
    }

    a {
        width: 380.9px;
        
        @media only screen and ${device.mobileL} {
            width: 169.34px;
        }
    }
`;


const Hybrid = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading1 heading="Hybrid Cloud Infrastructure" desc="Kubernetes (K8s)" icon={<SolutionHybridIcon />} />


                <Text style={{ maxWidth: '800px', margin: '16px auto 0', textAlign: 'center' }}>
                    Kubernetes is a portable, extensible, open source platform for managing containerized workloads and
                    services, that facilitates both declarative configuration and automation.
                </Text>

                <ContactUsContainer>
                    <a href='/pages/ContactUs#Message'><img src={cs} style={{ width: `100%` }} /></a>
                </ContactUsContainer>
            </InnerWrapper>



            <SectionBlock>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>

                </div>
            </SectionBlock>

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default Hybrid
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Element, Link as ScrollLink } from 'react-scroll';
import styled, { css } from 'styled-components';
import OPMLogo from '../../assets/OPMLogo';
import { device, fontSizeArr, Heading, SectionSubheading, Text, TextLight } from '../../common.styles';
import { ThemeContext } from '../../ThemeContext';
import iBrokePdf from '../../assets/pdf/iBroker.pdf'
import itradingPdf from '../../assets/pdf/iTrading.pdf'
import primePdf from '../../assets/pdf/PRIME.pdf'
import ldapPdfFilr from '../../assets/pdf/ldap-project.pdf'
import endUserPdf from '../../assets/pdf/EndUser.pdf'

const menuItems = [
  // { label: 'Home', to: '/' },
  { label: 'Why OPM', to: '/pages/WhyOPM' },
  { label: 'Services', to: '/pages/Services' },
  { label: 'Products', to: '/pages/Products' },
  { label: 'Solutions', to: '/pages/Solutions' },
  { label: 'Contact Us', to: '/pages/ContactUs' },
  { label: 'Join Us', to: '/pages/JoinUs' },
]


const TextVersionWrapper = styled.div`
  // max-width: 800px;
  margin: auto;
  padding: 16px;
  padding-left :80px;
  padding-right:80px;
  box-sizing: border-box;
`;

const TextVersionHeading = styled(Heading)`
font-family: HB;
font-weight: bold;
padding-Top:30px;
color:#1F3763;
`;

const TextVersionsectionHeading = styled(SectionSubheading)`
font-family: HB;
font-weight: bold;
padding-Top:30px;
color:#1F3763;
// margin: 30px 0;
`;


const TextVersionSubSubsectionHeading = styled(SectionSubheading)`
font-family: HR;
padding-Top:30px;
color:#4472C4;
// margin: 30px 0;
`;

const TextVersionSubHeading = styled(Heading)`
  font-family: HB;
  font-weight: bold;
  padding-Top:30px;
  color:#1F3763;
  // margin: 30px 0;
`;



const TextVersionSubsectionHeading = styled(SectionSubheading)`
font-family: HB;
font-weight: bold;
padding-Top:30px;
color:#1F3763;
// margin: 30px 0;
`;



const TextVersionSubsubsectionHeading = styled(TextLight)`
font-family: HB;
font-weight: bold;
padding-Top:30px;
color:#1F3763;
// margin: 30px 0;
`;







const TextVersionUl = styled(Heading)`
  font-family: HB;
  padding-left:30px
`;

const TextVersionDesc = styled(Text)`
  font-family: HR;
  line-height: 1;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  ${props => props.bgImg && css`
    background-image: url(${props.bgImg});
    background-size: cover;
    background-position: center;
  `}

  &:before {
    display: block;
    content: ' ';
    padding-top: 50px;
  
    @media only screen and ${device.mobileL} {
      padding-top: 80px;
    }
  }
  
  @media only screen and ${device.mobileL} {
    margin-top: 80px;
  }
`;

const ContentBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: ${props => props.contentAlign};
`;

const Content = styled.div`
  padding: 16px;
  box-sizing: border-box;
`;

const MobileWrapper = styled.div`
  @media only screen and ${device.mobileL} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
  }
`;

const MobileMenuWrapper = styled.div`
  @media only screen and ${device.mobileL} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, .8);
    display: none;

    ${props => props.showMenu && css`
      display: inline;
    `}
  }
`;


const uiWrapper = styled.div`
  @media only screen and ${device.mobileL} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
  }
`;


const NavBarBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  object-fit: contain;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.22) 2%, rgba(255, 255, 255, 0.38) 5%, rgba(255, 255, 255, 0.53) 7%, rgba(255, 255, 255, 0.66) 10%, rgba(255, 255, 255, 0.77) 13%, rgba(255, 255, 255, 0.85) 16%, rgba(255, 255, 255, 0.92) 20%, rgba(255, 255, 255, 0.96) 25%, rgba(255, 255, 255, 0.99) 31%, #fff 50%, rgba(255, 255, 255, 0.99) 69%, rgba(255, 255, 255, 0.96) 75%, rgba(255, 255, 255, 0.92) 80%, rgba(255, 255, 255, 0.85) 84%, rgba(255, 255, 255, 0.77) 87%, rgba(255, 255, 255, 0.66) 90%, rgba(255, 255, 255, 0.53) 93%, rgba(255, 255, 255, 0.38) 95%, rgba(255, 255, 255, 0.22) 98%, rgba(255, 255, 255, 0.04) 100%, rgba(255, 255, 255, 0));

  ${props => props.tone === 'dark' && css`
  mix-blend-mode: multiply;
  opacity: 1;
  background-image: linear-gradient(to right, rgba(133, 174, 217, 0) 0%, rgba(133, 174, 217, 0.09) 1%, rgba(133, 174, 217, 0.28) 3%, rgba(133, 174, 217, 0.45) 6%, rgba(133, 174, 217, 0.6) 8%, rgba(133, 174, 217, 0.73) 11%, rgba(133, 174, 217, 0.83) 15%, rgba(133, 174, 217, 0.91) 19%, rgba(133, 174, 217, 0.96) 24%, rgba(133, 174, 217, 0.99) 31%, #85aed9 50%, rgba(133, 174, 217, 0.99) 69%, rgba(133, 174, 217, 0.96) 76%, rgba(133, 174, 217, 0.91) 81%, rgba(133, 174, 217, 0.83) 85%, rgba(133, 174, 217, 0.73) 89%, rgba(133, 174, 217, 0.6) 92%, rgba(133, 174, 217, 0.45) 94%, rgba(133, 174, 217, 0.28) 97%, rgba(133, 174, 217, 0.09) 99%, rgba(133, 174, 217, 0) 100%);
  `}
`;

const NavBarWrapper = styled.div`
  position: relative;
  width: 100%;
  /* opacity: 0.6; */

  @media only screen and ${device.mobileL} {
    background-image: none;
    top: 0;
  }
`;

const NavBarMenu = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  background-color: white;
  display: none;

  @media only screen and ${device.mobileL} {
    display: flex;
    align-items: center; 
    padding: 16px;
    padding-left: 0;
    box-sizing: border-box;

    ${props => props.showMenu && css`
      display: none;
    `}
  }
`;

const StyledOPMLogo = styled(OPMLogo)`
  display: inline-block;
  width: 128px;
`;

const NavBarMenuClose = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;

  @media only screen and ${device.mobileL} {
    display: block;
  }
`;

const NavBar = styled.div`
  margin: auto;
  max-width: ${props => props.theme.maxWidth}px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  @media only screen and ${device.mobileL} {
    display: inline;
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: inline;
    background-color: rgba(255, 255, 255, .8); */
  }
`;

const NavBarLink = styled(Link)`
  position: relative;
  display: block;
  padding: 8px;
  color: black;

  font-size: ${props => props.theme.fontSize.body}px;

  ${props => props.tone === 'dark' && css`
    color: white;
  `}

  @media only screen and ${device.mobileL} {
    color: black;
  }

  &:hover {
    opacity: .7;
  }
`;

const TopBar = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and ${device.mobileL} {
    position: relative;
    display: inline;

    .font-changer {
      display: none;
    }
  }
`;

const TopBarLink = styled.div`
  padding: 8px 8px 0;
  box-sizing: border-box;
  white-space: nowrap;
  font-size: ${props => props.theme.fontSize.body}px;
  cursor: pointer;

  ${props => props.tone === 'dark' && css`
    color: white;
  `}

  @media only screen and ${device.mobileL} {
    color: black;
  }

  &:hover {
    opacity: .7;
  }
`;

const TextVersionContent = styled.div`

  padding-top:20px;

`;

const FontChanger = styled.div`
  padding-top: 4px;
  display: flex;
  align-items: baseline;

  .font-button {
    width: 20px;
    cursor: pointer;
  }

  & > div {
    ${props => props.tone === 'dark' && css`
      color: white;
    `}

    @media only screen and ${device.mobileL} {
      color: black;
      padding: 8px;
    }
  }
`;

const Font1 = styled.div`
  font-size: 12px;

  ${props => props.active && css`
    text-decoration: underline;
  `}
`;

const Font2 = styled.div`
  font-size: 16px;


  ${props => props.active && css`
      text-decoration: underline;
    `}
`;

const Font3 = styled.div`
  font-size: 20px;


  ${props => props.active && css`
      text-decoration: underline;
    `}
`;

const FontIconArr = [Font1, Font2, Font3];

const FontChangerBlock = ({ theme, tone, currentFont, setSelectedTheme, onChange }) => (
  <FontChanger tone={tone}>
    {FontIconArr.map((FontItem, index) =>
      <FontItem key={index} className="font-button" active={currentFont === index} onClick={() => {
        onChange(index);
        setSelectedTheme({
          ...theme,
          fontSize: {
            heading: fontSizeArr[index].heading,
            sectionHeading: fontSizeArr[index].sectionHeading,
            sectionDesc: fontSizeArr[index].sectionDesc,
            sectionSubheading: fontSizeArr[index].sectionSubheading,
            body: fontSizeArr[index].body
          }
        });
      }}>A</FontItem>)}
  </FontChanger>
);

const MenuContent = ({ theme, tone, currentFont, setSelectedTheme, onChange }) => (
  <>
    <TopBar>
      <a href="/pages/Sitemap">
        <TopBarLink tone={tone}>Sitemap</TopBarLink></a>
      <a href="/">
        <TopBarLink tone={tone}>Full Version</TopBarLink>
      </a>
      <div style={{ width: '100%' }}></div>
      <TopBarLink tone={tone}><ScrollLink to="content">Skip to Content</ScrollLink></TopBarLink>
      <div className='font-changer'>
        <FontChangerBlock theme={theme} tone={tone} currentFont={currentFont} setSelectedTheme={setSelectedTheme} onChange={onChange} />
      </div>
    </TopBar>
    {/* <NavBarWrapper>
      <NavBar>
        <NavBarBg tone={tone} />
        {menuItems.map(({ label, to }, index) => <NavBarLink key={index} to={to} tone={tone}>{label}</NavBarLink>)}
      </NavBar>
    </NavBarWrapper> */}
  </>
)

const TextVersionHeader = ({ tone, bgImg, children, contentAlign = 'start' }) => {
  const [currentFont, setCurrentFont] = useState(1);
  const [showMenu, setShowMenu] = useState(false);

  const closeMenu = () => {
    setShowMenu(!showMenu);
  }

  return (
    <ThemeContext.Consumer>
      {({ theme, setSelectedTheme }) => (
        <Wrapper bgImg={bgImg}>

          <MobileWrapper>

            <MenuContent theme={theme} tone={tone} currentFont={currentFont} setSelectedTheme={setSelectedTheme} onChange={(index) => setCurrentFont(index)} />



          </MobileWrapper>
          <Element name="title">

            <TextVersionWrapper>

              {/* <TextVersionHeading>Home</TextVersionHeading> */}
              <TextVersionHeading><ScrollLink to="opm_part">Why OPM</ScrollLink></TextVersionHeading>

              <TextVersionUl>
                <ul>
                  <li><TextVersionDesc><ScrollLink to="opm_opm">OPM</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="opm_experience">Experience</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="opm_approach">Approach</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="opm_method">Method</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="opm_award">Awards</ScrollLink></TextVersionDesc></li>

                </ul>
              </TextVersionUl>

              <TextVersionHeading><ScrollLink to="service_part">Services</ScrollLink></TextVersionHeading>
              <TextVersionUl>
                <ul>
                  <li><TextVersionDesc><ScrollLink to="service_application_development">Applications Development</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="service_consulting_service">Consulting Services</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="service_technical_support">Technical Support</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="service_opm_cloud">OPM Cloud Computing Services</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="service_helpdesk_service">Help Desk Services</ScrollLink></TextVersionDesc></li>

                </ul>
              </TextVersionUl>
              <TextVersionHeading><ScrollLink to="product_part">Products</ScrollLink></TextVersionHeading>
              <TextVersionUl>
                <ul>
                  <li><TextVersionDesc><ScrollLink to="product_iBroke">iBrokers</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_iTrading">iTrading</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_prime">Prime</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_ldap">LDAP PROTECT</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_cmsmart">CMSmart</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_iStorage">iStorage</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_digittrade">DIGITTRADE</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_nfont_password_filter">nFront Password Filter</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_nFront_web_password_change">nFront Password Change</ScrollLink></TextVersionDesc></li>
                  <li><TextVersionDesc><ScrollLink to="product_nFront_account_disable">nFront Account Disable</ScrollLink></TextVersionDesc></li>
                </ul>
              </TextVersionUl>
              {/* <TextVersionHeading>Solutions</TextVersionHeading> */}
              <TextVersionHeading><ScrollLink to="solution">Solutions</ScrollLink></TextVersionHeading>
              <TextVersionUl>
                <ul style={{ listStyleType: `none` }}>
                  <li><TextVersionSubHeading><ScrollLink to="solution_security">Security</ScrollLink></TextVersionSubHeading>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc><ScrollLink to="solution_identity_access_management">Identity & Access Management</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_end_point">End Point Security Solution</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_sso">Single Sign-on (SSO)</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_multi_factor">Multi-factor Authentication</ScrollLink></TextVersionDesc></li>
                      </ul>
                    </TextVersionUl>

                  </li>
                  <li><TextVersionSubHeading><ScrollLink to="solution_end_user">End User</ScrollLink></TextVersionSubHeading>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc><ScrollLink to="solution_end_user_portal_solution">End User Portal Solution</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_end_user_mobile">Mobile Enterprise Solution</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_vdi">Virtual Desktop Infrastructure (VDI)</ScrollLink></TextVersionDesc></li>
                      </ul>
                    </TextVersionUl>
                  </li>

                  <li><TextVersionSubHeading><ScrollLink to="solution_by_industry">By Industry</ScrollLink></TextVersionSubHeading>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc><ScrollLink to="solution_industry_health">Health Care Solution</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_cms">Case Management System</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_call_log">Call Log System</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_booking">Booking System</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_straming">Interactive Live Streaming System</ScrollLink></TextVersionDesc></li>
                      </ul>
                    </TextVersionUl>
                  </li>

                  <li><TextVersionSubHeading><ScrollLink to="solution_block_chain">Blockchain</ScrollLink></TextVersionSubHeading>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc><ScrollLink to="solution_authentication">Authentication</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_data_protection">Data Protection</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_transaction_processing">Transaction Processing</ScrollLink></TextVersionDesc></li>

                      </ul>
                    </TextVersionUl>
                  </li>

                  <li><TextVersionSubHeading><ScrollLink to="solution_ai">Artificial Intelligence (AI)</ScrollLink></TextVersionSubHeading>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc><ScrollLink to="solution_nlp">Natural Language Processing (NLP)</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_chat_bot">ChatBot</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_ocr">Optical Character Recognition (OCR)</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_image_recognition">Image Recognition</ScrollLink></TextVersionDesc></li>

                      </ul>
                    </TextVersionUl>
                  </li>

                  <li><TextVersionSubHeading><ScrollLink to="solution_hybrid_cloud">Hybrid Cloud</ScrollLink></TextVersionSubHeading>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc><ScrollLink to="solution_k8s">Kubernetes (K8s)</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_persistent">Persistent Storage</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_private_registry">Private Registry</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="solution_orchestrator">Orchestrator</ScrollLink></TextVersionDesc></li>

                      </ul>
                    </TextVersionUl>
                  </li>
                </ul>
              </TextVersionUl>

              <TextVersionHeading><ScrollLink to="contact_us">Contact Us</ScrollLink></TextVersionHeading>

              <TextVersionHeading><ScrollLink to="join_us">Join Us</ScrollLink></TextVersionHeading>

            </TextVersionWrapper>

          </Element>


          <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000` }} />




          <Element name="content">

            <TextVersionWrapper>

              <div className='opm_part'>

                <TextVersionHeading>Why OPM</TextVersionHeading>

                <div className='opm_opm'>
                  <TextVersionContent>
                    <TextVersionSubHeading>OPM</TextVersionSubHeading>
                    <Text>OPM Systems Company Limited is one of the leading solutions provider on Identity and Access Management(IAM).
                      We provide comprehensive deployment services to various network security solutions, software applications development and maintenance services to commercial corporations, government, semi-government sectors and education institutions.
                      We have an experienced team in solutions implementation and project management who help develop and deploy sophisticated business solutions that enhances business operations.</Text>
                  </TextVersionContent>


                </div>

                <div className='opm_experience'>
                  <TextVersionContent>
                    <TextVersionSubHeading>Experience</TextVersionSubHeading>
                    <Text>With over 4 years experience, OPM has a strong track record in deploying and developing e-business solutions for corporate customers and government departments.
                      Our staff in the professional service division have more than 15 years of experience in recommending, designing and implementing different business solutions and products to our customer environments.</Text>
                  </TextVersionContent>
                </div>

                <div className='opm_approach'>
                  <TextVersionContent>
                    <TextVersionsectionHeading>Approach</TextVersionsectionHeading>
                    <Text>Our approach is not simply to deliver the installation of a suitable piece of software but time spent understanding of your business and its processes.
                      We listen to customers to ensure requirements are fully addressed and solutions are designed to address customer needs.
                      Our Project Management Office help to ensure that the project is well and properly managed to ensure the project goal is achieved efficiently in timely manner.</Text>
                  </TextVersionContent>
                </div>


                <div className='opm_method'>
                  <TextVersionContent>
                    <TextVersionsectionHeading>Method</TextVersionsectionHeading>
                    <Text>Before we embark upon the set up and installation we discuss and understand your requirements and needs, both now and in the future.
                      From your requirements we establish the initial configuration setup and design the system to fit the needs of the business ensuring that future requirements are not overlooked.
                      Long after the solution has been implemented, we continue to provide ongoing support to ensure that it continues to function and grow with your organization.</Text>
                  </TextVersionContent>
                </div>

                <div className='opm_award'>
                  <TextVersionContent>
                    <TextVersionsectionHeading>Award</TextVersionsectionHeading>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Most Prominent Network Security Consultant Services of The Year</TextVersionDesc></li>
                        <li><TextVersionDesc>Hong Kong's Most Outstanding Services Awards 2021</TextVersionDesc></li>
                        <li><TextVersionDesc>Most Valuable Services Awards in Hong Kong 2017</TextVersionDesc></li>
                        <li><TextVersionDesc>Business Achiever Awards by ABCA 2017</TextVersionDesc></li>

                      </ul>
                    </TextVersionUl>
                  </TextVersionContent>
                </div>


                <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

              </div>




              <div className='service_part'>

                <TextVersionHeading>Services</TextVersionHeading>


                <div className='service_application_development'>
                  <TextVersionContent>

                    <TextVersionSubHeading>Applications Development</TextVersionSubHeading>


                    <Text>Some companies may find it difficult to understand what applications are suited to their company and how to incorporate them.
                      OPM can help your organization grow and thrive with our flexibility know how in the area of application development.</Text>

                    <TextVersionsectionHeading>Features</TextVersionsectionHeading>
                    <Text>We have a deep understanding of industrial needs and great depth of application knowledge:</Text>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Gather different modules and components and build tailored applications for your business with our framework.</TextVersionDesc></li>
                        <li><TextVersionDesc>Innovative professionals who are willing to listen and understand various requirements.</TextVersionDesc></li>
                        <li><TextVersionDesc>Develop applications based on your requirements, reduce your cost and increase your return in application investments.</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>
                  </TextVersionContent>



                </div>






                <div className='service_option'>
                  <TextVersionContent>

                    <TextVersionsectionHeading>Options</TextVersionsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>1. Product Development</TextVersionDesc></li>
                        <li><TextVersionDesc>2. Project Management</TextVersionDesc></li>
                        <li><TextVersionDesc>3. System Design</TextVersionDesc></li>
                        <li><TextVersionDesc>4. Software Development</TextVersionDesc></li>
                        <li><TextVersionDesc>5. IT Infrastructure Services</TextVersionDesc></li>
                        <li><TextVersionDesc>6. Quality Assurance</TextVersionDesc></li>
                        <li><TextVersionDesc>7. Digital Marketing</TextVersionDesc></li>
                        <li><TextVersionDesc>8. Blockchain</TextVersionDesc></li>
                        <li><TextVersionDesc>9. Artificial Intelligence (AI)</TextVersionDesc></li>
                        <li><TextVersionDesc>10. System Integration (SI)</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>
                  </TextVersionContent>


                </div>


                <div className='related'>
                  <TextVersionContent>

                    <TextVersionsectionHeading>Related Products</TextVersionsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc><ScrollLink to="product_iBroke">iBrokers</ScrollLink></TextVersionDesc></li>
                        <li><TextVersionDesc><ScrollLink to="product_iTrading">iTrading</ScrollLink></TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>
                  </TextVersionContent>

                  <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />
                </div>




                <div className='service_consulting_service'>
                  <TextVersionContent>

                    <TextVersionsectionHeading>Consulting Services</TextVersionsectionHeading>

                    <Text>OPM aligns your IT strategy with business priorities to promote growth and innovation. We create innovative and dynamic infrastructure designs for you to deliver improved business responsiveness and return on investment (ROI). Integrate best practices to help transform and optimize your IT and design solution architecture for you.</Text>

                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>
                    <Text>OPM Consulting Services ensure smooth implementations with the right economics, at the right time, with the functionality you need.</Text>
                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Focus on deadlines and ROI - Help maximize your technology investment and partner with you to collaboratively resolve your challenges.</TextVersionDesc></li>
                        <li><TextVersionDesc>A proven track record - Our expertise ranges across project management issues and deep into a variety of IT technologies. We have delivered measurable business outcomes and tangible ROI to clients since 2006.</TextVersionDesc></li>
                        <li><TextVersionDesc>Full dedication and accountability - Our organizational structure is designed for full accountability. Your Project Manager takes complete responsibility for the success of your implementation.</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>
                  </TextVersionContent>

                  <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />
                </div>



                <div className='service_technical_support'>
                  <TextVersionContent>

                    <TextVersionsectionHeading>Technical Supports</TextVersionsectionHeading>

                    <Text>At OPM, we have fully trained professionals who are always willing to help. We constantly upgrade ourselves with the ever-advancing technological changes so that we stay ahead of the pack. OPM are always on standby to lend you our expertise support and advice even if you have the most uncommon technical issues.</Text>

                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Professional and experienced technical support team.</TextVersionDesc></li>
                        <li><TextVersionDesc> Applying KM (knowledge management) methodology in order to solve problems with the most suitable solutions.</TextVersionDesc></li>
                        <li><TextVersionDesc>For issues that are unknown, the team will investigate the problems in all directions, to find the best solution and log back the new found cases and solutions in the KM system.</TextVersionDesc></li>
                        <li><TextVersionDesc>99% of problems and issues can be solved on time.</TextVersionDesc></li>


                      </ul>

                    </TextVersionUl>
                  </TextVersionContent>


                </div>

                <div className='related'>
                  <TextVersionContent>

                    <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc><ScrollLink to="service_helpdesk_service">Help Desk Services</ScrollLink></TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>
                  </TextVersionContent>

                  <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />
                </div>



                <div className='service_opm_cloud'>
                  <TextVersionContent>

                    <TextVersionsectionHeading>OPM Cloud Computing Services</TextVersionsectionHeading>

                    <Text>OPM SYSTEMS COMPANY LTD is now on the list of Government Public Cloud service provider. </Text>

                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>
                    <TextVersionUl>


                      <TextVersionSubsubsectionHeading>Service strategy </TextVersionSubsubsectionHeading>
                      <Text>OPM Cloud Computing Services is one of our major investments in the last few years. We spent and invested over 2 years to establish the cloud computing services. OPM professional team developed our own applications and the web Content Management Framework running on our cloud services environment. It is the new marketing strategy selling OPM applications with the cloud services platform.</Text>

                      <TextVersionSubsubsectionHeading>Investment portfolios </TextVersionSubsubsectionHeading>
                      <Text>Portal hosting services for the customers, mainly the CMS portal OPM designed and provided the tools to let the users self-manage the contents. Moreover, mailing service could be bundled as requested. Provide the development platform for In-house IT or SI to develop their applications (mainly JAVA applications run under Linux O/S).</Text>
                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc>Investment in datacenter facilities</TextVersionDesc></li>
                          <li><TextVersionDesc>Investment in storage solution</TextVersionDesc></li>
                          <li><TextVersionDesc>Investment in security protection</TextVersionDesc></li>
                          <li><TextVersionDesc>Investment in networking infrastructure</TextVersionDesc></li>
                          <li><TextVersionDesc>Investment in virtual machine technology</TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <TextVersionSubsubsectionHeading>Benefits of the designs:</TextVersionSubsubsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc>Reduce setup costs and maintenance costs for hardware and networking in customer offices</TextVersionDesc></li>
                          <li><TextVersionDesc>OPM provided 24 X 7 services for all the cloud services</TextVersionDesc></li>
                          <li><TextVersionDesc>Centralization of company information / data, authorized users could view the full pictures in any branch anywhere</TextVersionDesc></li>
                          <li><TextVersionDesc>Using our cloud as development platform could save the setup cost and times, only purchase the equipment/hardware once the development is success</TextVersionDesc></li>
                          <li><TextVersionDesc>The primary goal of information security is to protect information assets and thus to maintain the confidentiality, integrity and availability, along with related goals such as authenticity, accountability and reliability, etc</TextVersionDesc></li>
                          <li><TextVersionDesc>OPM is the cloud partner empowered by Microsoft</TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>



                      <TextVersionSubsubsectionHeading>Value-added Management:</TextVersionSubsubsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc>Applications Development</TextVersionDesc></li>
                          <li><TextVersionDesc>Web Portal and Content Development:</TextVersionDesc></li>
                          <li><TextVersionDesc>End User Portal Solution</TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>


                      <TextVersionSubsectionHeading>Related Products</TextVersionSubsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to="product_iBroke">iBrokers</ScrollLink></TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to="product_iTrading">iTrading</ScrollLink></TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>

                      <TextVersionSubsectionHeading>Related Services</TextVersionSubsectionHeading>

                      <TextVersionUl>
                        <ul>
                          { /*<li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>*/}
                          <li><TextVersionDesc><ScrollLink to="service_application_development">Applications Development</ScrollLink></TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'>Consulting Services</ScrollLink></TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink></TextVersionDesc></li>

                        </ul>
                      </TextVersionUl>




                    </TextVersionUl>
                  </TextVersionContent>

                  <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />


                </div>




                <div className='service_helpdesk_service'>
                  <TextVersionContent>

                    <TextVersionsectionHeading>Help Desk Services</TextVersionsectionHeading>

                    <Text>OPM professional help desk team can provides 24/7 IT support services to your business. Our engineers can help to troubleshoot any technical problems and expedite solutions on and off-site to make sure your business is always on track.</Text>

                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>

                    <Text>We have a professional and experienced team providing:</Text>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Hardware and software help desk</TextVersionDesc></li>
                        <li><TextVersionDesc>Bilingual and multi-lingual support available</TextVersionDesc></li>
                        <li><TextVersionDesc>On-site and off-site support available</TextVersionDesc></li>
                        <li><TextVersionDesc>Customer service inquiries and information</TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>
                    <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                    <TextVersionUl>
                      <ul>

                        <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                        </TextVersionDesc></li>
                      </ul>
                    </TextVersionUl>
                  </TextVersionContent>

                  <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />
                </div>




                <div className='product_part'>

                  <TextVersionHeading>Product</TextVersionHeading>



                  <div className='product_iBroke'>

                    <TextVersionsectionHeading>iBrokers</TextVersionsectionHeading>


                    <Text>iBrokers Insurance System is a comprehensive system designed especially for the insurance agent/broker which handles Customer Relationships, Policies, Renewals, and financial ledgers. It is a 100% web base application, so the user could access it through the internet. iBrokers is a one-stop solution to empower your leading position and enhance your competitiveness in the market.</Text>


                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Support multi-level of CRM, brokerages, companies and currencies</TextVersionDesc></li>
                        <li><TextVersionDesc>Embedded a policy calculation engine to calculate profit sharing of the policy with different parties</TextVersionDesc></li>
                        <li><TextVersionDesc>Keep track of the policy history</TextVersionDesc></li>
                        <li><TextVersionDesc>Well integrated with the accounting system</TextVersionDesc></li>
                        <li><TextVersionDesc>Multi-level security control and user access right</TextVersionDesc></li>
                        <li><TextVersionDesc>Support different types of report formats, e.g. EXCEL, PDF, etc.</TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>



                    <TextVersionsectionHeading>Benefits</TextVersionsectionHeading>

                    <TextVersionUl style={{ paddingBottom: `30px` }}>
                      <ul>
                        <li><TextVersionDesc>One-stop solution to complete the job from quotation to invoicing, and AR/AP</TextVersionDesc></li>
                        <li><TextVersionDesc>Centralize the policies and CRM data, management can view full pictures in real time</TextVersionDesc></li>
                        <li><TextVersionDesc>User can access iBrokers at anywhere, in anytime through any web browser</TextVersionDesc></li>
                        <li><TextVersionDesc>User friendly interfaces</TextVersionDesc></li>
                        <li><TextVersionDesc>Reduce the IT support costs</TextVersionDesc></li>
                        <li><TextVersionDesc>Easily tailor made to suit the uniqueness of a company</TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>

                    <Text><a target='_blank' href={iBrokePdf}><u>Click to download PDF</u></a></Text>

                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>




                  <div className='product_iTrading'>

                    <TextVersionsectionHeading>iTrading</TextVersionsectionHeading>


                    <Text>A value-added trading system designed especially for project oriented company</Text>


                    <Text>Users are able to handle customer relationships, projecttracking, trading documents, and financial ledgers. It is a 100% web based application, so the user could access it through the internet.

                      iTrading provides a complete solution to streamline the complex trading workflow, and enhance your competitiveness in the market.</Text>


                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>

                    <TextVersionUl style={{ paddingBottom: `30px` }}>
                      <ul>
                        <li><TextVersionDesc>Support virtual products (services maintenance, product warranties)</TextVersionDesc></li>
                        <li><TextVersionDesc>Access through any web browser</TextVersionDesc></li>
                        <li><TextVersionDesc>Customizable</TextVersionDesc></li>
                        <li><TextVersionDesc>Standard workflow with simple user interface</TextVersionDesc></li>
                        <li><TextVersionDesc>Real time project status in a summary page</TextVersionDesc></li>
                        <li><TextVersionDesc>Jobs deadline reminder</TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>

                    <Text><a target='_blank' href={itradingPdf}><u>Click to download PDF</u></a></Text>

                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>




                  <div className='product_prime'>

                    <TextVersionsectionHeading>PRIME</TextVersionsectionHeading>


                    <Text>Post / Rank / Identity Management Essentials</Text>


                    <Text>A cost-effective career transition solution which allows you to improve the overall experience of posting transition for all participants within your Government Department.

                      PRIME also helps organization to automate the complex provisioning process so users can have immediate access to resource across the organization systems and applications.</Text>


                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Assign resources to users based on business roles and policies with role-based provisioning</TextVersionDesc></li>
                        <li><TextVersionDesc>Self-defining posts and ranks information</TextVersionDesc></li>
                        <li><TextVersionDesc> Filtered search </TextVersionDesc></li>
                        <li><TextVersionDesc>Provide audit trail</TextVersionDesc></li>
                        <li><TextVersionDesc>Multi-level security control and user access right</TextVersionDesc></li>


                      </ul>

                    </TextVersionUl>



                    <TextVersionSubsectionHeading>Benefits</TextVersionSubsectionHeading>



                    <TextVersionUl style={{ paddingBottom: `30px` }}>
                      <ul>
                        <li><TextVersionDesc>Update user’s attributes and roles simultaneously</TextVersionDesc></li>
                        <li><TextVersionDesc>Support two roles at the same time for one user</TextVersionDesc></li>
                        <li><TextVersionDesc>Generate up-to-date/provision complete posting list and related reports easily</TextVersionDesc></li>
                        <li><TextVersionDesc>Reduce IT support costs</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>

                    <Text><a target='_blank' href={primePdf}><u>Click to download PDF</u></a></Text>

                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>



                  <div className='product_ldap'>

                    <TextVersionsectionHeading>LDAP PROTECT</TextVersionsectionHeading>


                    <Text>An appliance that acts as middleware layer among LDAP clients, servers and other directory services.</Text>



                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>

                    <TextVersionUl style={{ paddingBottom: `30px` }}>
                      <ul>
                        <li><TextVersionDesc>Periodic health checks to detect unavailable or slow back-end servers</TextVersionDesc></li>
                        <li><TextVersionDesc>Hide LDAP schema from outsider,
                          and Identity-based policy on LDAP access control </TextVersionDesc></li>
                        <li><TextVersionDesc>LDAP Firewall, provides dynamic load balancing to various LDAP servers (e.g. AD, eDirectory, LDAP)</TextVersionDesc></li>
                        <li><TextVersionDesc>Realtime monitoring and logging</TextVersionDesc></li>

                        <ul>
                          <li><TextVersionDesc>Enhanced security</TextVersionDesc></li>
                          <li><TextVersionDesc>High availability </TextVersionDesc></li>
                          <li><TextVersionDesc>Enhanced scalability</TextVersionDesc></li>
                          <li><TextVersionDesc>Direct access control to directory services</TextVersionDesc></li>

                        </ul>
                      </ul>

                    </TextVersionUl>


                    <Text><a target='_blank' href={ldapPdfFilr}><u>Click to download PDF</u></a></Text>

                    <TextVersionSubsectionHeading>Benefits</TextVersionSubsectionHeading>



                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Configure easily</TextVersionDesc></li>
                        <li><TextVersionDesc>Seamless integration to most LDAP directory</TextVersionDesc></li>
                        <li><TextVersionDesc>Flexible and central network restriction to existing LDAP services</TextVersionDesc></li>
                        <li><TextVersionDesc>Strengthen LDAP Security Protection</TextVersionDesc></li>
                        <li><TextVersionDesc>Strong auditing on LDAP traffic</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>

                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>









                  <div className='product_cmsmart'>

                    <TextVersionHeading>CMSmart</TextVersionHeading>


                    <Text>SME’s Best Virtual Data & PR Solution Web Management System</Text>


                    <Text>It has integrated website set-up, management and content update functions. It's interface is designed by the professional OPM team, customised to suit your business needs. It's user-friendly and maintenance-free features enable you to manage your news release more effectively.</Text>


                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>


                    <Text>website set-up, management and content update</Text>




                    <TextVersionSubsectionHeading>Benefits</TextVersionSubsectionHeading>



                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>No programming and installation required</TextVersionDesc></li>
                        <li><TextVersionDesc>Auto image resize</TextVersionDesc></li>
                        <li><TextVersionDesc>High Quality Picture slide show</TextVersionDesc></li>
                        <li><TextVersionDesc>Cover all business sectors</TextVersionDesc></li>
                        <li><TextVersionDesc>Multi-lingual Support</TextVersionDesc></li>
                        <li><TextVersionDesc>User-friendly</TextVersionDesc></li>
                        <li><TextVersionDesc>Customizable</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>

                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>



                  <div className='product_iStorage'>

                    <TextVersionHeading>iStorage</TextVersionHeading>


                    <TextVersionSubSubsectionHeading>Securing the Mainstream</TextVersionSubSubsectionHeading>


                    <Text>Trusted global leader of PIN authenticated/ hardware encrypted data storage devices</Text>

                    <TextVersionSubsectionHeading>About the Product</TextVersionSubsectionHeading>


                    <Text>We have USB Flash Drives, Hard Disk Drives, and Solid State Drive formats. We deliver the most innovative products to securely store and protect data to Military specified encryption levels, safeguarding your valuable business information whilst ensuring compliance to regulations and directives.</Text>


                    <TextVersionSubsectionHeading>Features</TextVersionSubsectionHeading>



                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Securely store and protect data to military specified encryption levels</TextVersionDesc></li>
                        <li><TextVersionDesc>No software required</TextVersionDesc></li>
                        <li><TextVersionDesc>PIN authentication - full disk encryption</TextVersionDesc></li>
                        <li><TextVersionDesc>Immune to key-loggers and brute force attacks</TextVersionDesc></li>
                        <li><TextVersionDesc>Safeguarding valuable business information whilst ensuring compliance to regulations and directives</TextVersionDesc></li>
                        <li><TextVersionDesc>AES 256-bit hardware encryption</TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>



                    <TextVersionSubsectionHeading><a target='_blank' href="https://istorage-uk.com/">Get More Information</a></TextVersionSubsectionHeading>




                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>




                  <div className='product_digittrade'>

                    <TextVersionHeading>DIGITTRADE</TextVersionHeading>





                    <Text>DIGITTRADE have been developing and producing external encrypted hard drives since 2005. Banks, companies and authorities use the highly secure HS256S and HS256 S3 hard drives for the safe transport of sensitive data and for the creation of data protection-compliant backups. The HS256 S3 hard drive also received the BSI certificate at the end of 2017.</Text>



                    <TextVersionsectionHeading>Features</TextVersionsectionHeading>



                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Safe transport of sensitive data</TextVersionDesc></li>
                        <li><TextVersionDesc>Create data protection-compliant backups</TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>



                    <TextVersionsectionHeading><a target='_blank' href="https://www.digittrade.de/store/en">Get More Information</a></TextVersionsectionHeading>




                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>






                  <div className='product_nfont_password_filter'>

                    <TextVersionHeading>nFront Password Filter</TextVersionHeading>





                    <Text>
                      nFront Password Filter is a password policy tool that prevents the use of weak, easily hacked passwords for Windows Active Directory. nFront Password Filter allows network administrators to create and enforce multiple granular password policies within a single Windows domain. nFront Password Filter can reject non-compliant passwords before they are allowed on the network. nFront Password Filter can scan a new password against over 2 million common passwords in less than 1 second. Also included is a client that lists the user’s specific password policy rules. All configuration is done via Group Policies (GPO) and the design contains no single point of failure and no “password policy server.” nFront Password Filter is used by many companies to meet SOX, PCI and HIPAA password compliance requirements.

                    </Text>



                    <TextVersionsectionHeading>nFront Password Filter
                    </TextVersionsectionHeading>

                    <TextVersionSubSubsectionHeading>ARE YOU ENFORCING A GOOD PASSWORD POLICY?
                    </TextVersionSubSubsectionHeading>


                    <Text>
                      Passwords. Everyone on your corporate network has one. How weak is the weakest password?

                      Having a good password policy that is enforced across all users is fundamental to good security practices. You are probably spending money on firewalls, anti-virus, encryption and data leakage products. However, if you are using the built-in Windows Password Policy you might as well burn the money you are spending for all the security software and devices.


                    </Text>

                    <TextVersionSubSubsectionHeading>WHAT IS NFRONT PASSWORD FILTER?
                    </TextVersionSubSubsectionHeading>

                    <Text>
                      nFront Password Filter is a password policy enforcement tool for Windows Active Directory that allows up to 10 different password policies in the same Windows domain. Each password policy has many granular settings and can be associated with one or more global or universal security groups. nFront Password Filter allows you to strengthen network security by preventing the use of weak, easily hacked passwords.

                    </Text>

                    <TextVersionSubSubsectionHeading>CAN YOU BENEFIT FROM NFRONT PASSWORD FILTER?
                    </TextVersionSubSubsectionHeading>


                    <Text>If a security auditor ran a password cracker on your network how many passwords would they crack? How can you be sure your employees are following your suggested password guidelines?
                      How can you be sure your administrators are not creating accounts with blank or simple passwords?

                      Are you using an identity management tool that has users change their password via a website but also allows them to bypass the website using CTRL-ALT-DEL and set weak passwords?
                    </Text>


                    <TextVersionSubSubsectionHeading>WHY USE NFRONT PASSWORD FILTER?
                    </TextVersionSubSubsectionHeading>

                    <Text>
                      Users do not understand the need for strong passwords and will not follow formal password policies unless the policies are enforced.

                      Protects from external hacking. IPSec is great. VPNs are great. 128-bit encryption is great. However, the correct username and password allows a hacker to use the 128-bit IPSec VPN tunnel and access your corporate data. All of your firewall devices and IDS devices have no way of distinguishing the compromised account from the actual legitimate user.

                      Protects from internal hacking. How about the new engineer who would like to access to company financial data? How about the college student who would like the modify the student records database? How about all the free password sniffing and cracking tools on the Internet?

                      Disallowing weak passwords is part of the <a target='_blank' href='https://www.sans.org/blog/cis-controls-v8/' style={{ color: `#00b0bd` }}>SANS/FBI Top 20 List</a>
                    </Text>


                    <TextVersionSubSubsectionHeading>HOW PASSWORDS ARE COMPROMISED:
                    </TextVersionSubSubsectionHeading>



                    <Text>
                      Passwords can be compromised in a number of ways. There are software tools to "guess" passwords. Essentially there are 4 categories of tools:<><br></br></>

                      Brute Force - go through every possible character combination incrementally starting with "a","b" and moving to "aa","ab", etc.

                      Dictionary password crackers - try dictionary words and common sequences/patterns Hybrid - combine multiple dictionary words and patterns
                      Rainbow Tables - use precalculated hashes for all passwords of 14 characters or less to find a match. Given a hash rainbow tables can be used to crack any password of 14 characters or less in about 2 minutes.

                      <a target='_blank' href='https://resources.infosecinstitute.com/topic/10-popular-password-cracking-tools/' style={{ color: `#00b0bd` }}>Here</a> is a nice list of different password cracking tools

                      There are DLL injection tools that can retrieve the database of hashed passwords. Please note that hashed passwords are not the same as encrypted passwords. Encrypted passwords can be decrypted given the shared secret or private key. However, hashed passwords cannot be reverse engineered. So what is the danger of a thief getting the hashes. A lot! There are tools like <a target='_blank' href='https://www.darknet.org.uk/2006/02/rainbowcrack-how-to-use-rainbow-crack-rainbow-tables/' style={{ color: `#00b0bd` }}>Rainbow Crackers</a>  which can crack any 14 character or less password in a matter of minutes if you can provide the password hash. There are websites where you an paste a captured hash and they will use their computing power to crack the LanMan or MD5 hash for you. Click here to read more...

                    </Text>




                    <TextVersionSubSubsectionHeading>WHAT IS WRONG WITH THE WINDOWS PASSWORD POLICY SETTINGS?
                    </TextVersionSubSubsectionHeading>



                    <Text>Windows gives you the tools to control password length, history and expiration, but no good controls to enforce the use of reasonable passwords that are not easily hacked. Without nFront Password Filter it is highly likely that weak, easily cracked passwords are allowed on your network.

                      Consider the following standard Windows policy:

                      Windows Server 2012 R2 Active Directory Password Policy
                    </Text>



                    <Text style={{ paddingTop: `30px` }}>THE WINDOWS PASSWORD POLICY ABOVE DOES NOT PREVENT ANY OF THE FOLLOWING PASSWORDS:
                    </Text>



                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>aaaaaaaa</TextVersionDesc></li>
                        <li><TextVersionDesc>abcdefgh</TextVersionDesc></li>
                        <li><TextVersionDesc>123456</TextVersionDesc></li>
                        <li><TextVersionDesc>januarypw</TextVersionDesc></li>
                        <li><TextVersionDesc>februarypw</TextVersionDesc></li>
                        <li><TextVersionDesc>march123</TextVersionDesc></li>
                        <li><TextVersionDesc>myuserid</TextVersionDesc></li>
                        <li><TextVersionDesc>mydogsname</TextVersionDesc></li>
                        <li><TextVersionDesc>qwerty123</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>


                    <Text style={{ paddingTop: `30px` }}>Windows Server 2012 R2 Active Directory Password Policy with complexity option</Text>

                    <TextVersionSubSubsectionHeading>EVEN IF YOU ENABLE WINDOWS COMPLEXITY YOU HAVE PASSWORDS LIKE THIS:
                    </TextVersionSubSubsectionHeading>



                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Password1</TextVersionDesc></li>
                        <li><TextVersionDesc>Microsoft1</TextVersionDesc></li>
                        <li><TextVersionDesc>Summer2019</TextVersionDesc></li>
                        <li><TextVersionDesc>Welcome1</TextVersionDesc></li>
                        <li><TextVersionDesc>Company1</TextVersionDesc></li>
                        <li><TextVersionDesc>LetMeIn1</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>


                    <Text>NOW CONSIDER THE ROBUST POLICY CAPABILITIES WITH NFRONT PASSWORD FILTER
                    </Text>


                    <Text style={{ paddingTop: `30px` }}>nFront Password Filter Password Policy Example
                    </Text>


                    <Text>Each policy in nFront Password Filter has over 40 settings. You can enforce specific requirements based on character types. There are several rules to break typical user patterns. The most effective rule is the dictionary checking rule. The filter can check million of words in less than one second.

                      To see a more exact comparison of settings see these links:

                    </Text>

                    <Text><u><a target='_blank' href='https://nfrontsecurity.com/products/nfront-password-filter/nfront-password-filter-vs-windows-2003-password-policy.php' style={{ color: `#00b0bd` }}>nFront Password Filter versus the Windows 2003 Password Policy</a></u></Text>

                    <Text><u><a target='_blank' href='https://nfrontsecurity.com/products/nfront-password-filter/nfront-password-filter-vs-windows-2012-password-policy.php' style={{ color: `#00b0bd` }}>nFront Password Filter versus Windows 2008/2012/2016/2019 Password Policies</a></u></Text>



                    <TextVersionSubSubsectionHeading>CONTROLLED BY GROUP POLICY
                    </TextVersionSubSubsectionHeading>


                    <Text>nFront Password Filter is controlled using a single Group Policy Object configuration. After installation of the software on all domain controllers, simply create a new GPO, load one of our provided templates (ADM and ADMX templates provided) and configure your policies. It's that easy!</Text>


                    <TextVersionSubSubsectionHeading>PASSWORD POLICIES LINKED TO GROUPS OR ORGANIZATIONAL UNITS
                    </TextVersionSubSubsectionHeading>

                    <Text>nFront Password Filter is controlled by a single GPO, not a bunch of confusing GPOs all over the place. You can associate any policy in the MPE version with one or more security groups or organizational units. Nested groups are supported. Thus, you can easily use the same groups that you have created for resource security to control password security. No need to re-organize your OU structure to support your password policies. No need to run Resultant Set of Policy to see who gets what policy. No need to edit multiple GPOs all over the place or figure the best policy precedence order such that one policy does not negate the other.
                    </Text>


                    <TextVersionSubSubsectionHeading>GRANULAR PASSWORD POLICIES
                    </TextVersionSubSubsectionHeading>

                    <Text>nFront Password Filter gives you granular control over your password policies. It can put min and max limits on specific types of characters, reject passwords that contain userids/usernames and even check a new password against a multi-language dictionary with over 2 million words in less than 1 second.</Text>


                    <TextVersionSubSubsectionHeading>WHAT ABOUT FINE-GRAINED PASSWORD POLICIES?
                    </TextVersionSubSubsectionHeading>

                    <Text>Windows 2008, Windows 2012, Windows 2016, and Windows 2019 support multiple password policies in the same domain. However, the policy settings are the same basic policies that are in Windows 2000 and Windows 2003. The only thing granular about fine-grained policies is the ability to apply them to different OUs. The policies do not have granular rules. You can set the minimum length, min/max age, history and complexity but still not stop passwords like Password1. The settings are not

                      robust enough to prevent the use of weak and easily cracked passwords. The settings are also cumbersome to put in place with no GUI to manage the settings.
                    </Text>



                    <TextVersionSubSubsectionHeading>MULTIPLE POLICIES
                    </TextVersionSubSubsectionHeading>

                    <Text>nFront Password Filter MPE allows you to have up to 6 different password policies in the same Windows domain. Each policy can be associated with one or more security groups and/or OUs. You can have strong password polices for Domain Administrators and those with access to more privileged information (credit card data, tax information, etc.). You can also associate weaker policy with other groups like "Mainframe Users."
                    </Text>


                    <TextVersionSubSubsectionHeading>POLICY RULES TO ENSURE PASSWORD COMPATIBILITY ACROSS OTHER SYSTEMS
                    </TextVersionSubSubsectionHeading>

                    <Text>Suppose you sync your Windows passwords with UNIX or AS/400 or other mainframe systems. You do not want a one-size fits-all password policy that has to be dumbed down to the least common denominator. System like UNIX or mainframes often truncate passwords longer than 8 or 12 characters. Furthermore, such systems often do not accept certain special characters. With nFront Password Filter you can control the special characters which are accepted or block the use of any special characters.</Text>


                    <TextVersionSubSubsectionHeading>PASSWORD POLICY RULES TO ENFORCE THE USE OF PASSPHRASES
                    </TextVersionSubSubsectionHeading>

                    <Text>Passphrases are simply long passwords like "The dog ate my newspaper." or "I love Chocolate!" Such phrases make great passwords because they are long and long passwords are generally always superior to shorter ones. You can configure nFront Password Filter to require a longer length for the password and require a minimum number of spaces to be used in the password. This should get you well on your way to the correct horse battery staple passphrases you want to see on your network.

                      Since passphrases typically contain dictionary words, you can skip dictionary checking for passwords over a specified number of characters. So long passwords may contain dictionary words but short passwords may not.

                      The filter also supports a feature called Length Based Aging. You can use this to incentivize your users to use passphrases or longer passwords. For example, you may decide that users can keep passwords for one year if the passwords are over 20 characters but passwords that are 10-15 characters must be changed every 90 days.
                    </Text>

                    <TextVersionSubSubsectionHeading>POLICIES THAT CANNOT BE BYPASSED
                    </TextVersionSubSubsectionHeading>

                    <Text>nFront Password Filter is not some set of Java rules on a website that are easily bypassed. nFront Password Filter is integrated into the operating system and runs as a thread under the local security authority (the lsass.ese process). The polices you create cannot be bypassed with an alternative password change mechanism.
                    </Text>

                    <TextVersionSubSubsectionHeading>WHY NOT WRITE A CUSTOM PASSFILT.DLL (PASSWORD FILTER)?
                    </TextVersionSubSubsectionHeading>


                    <Text>Writing a custom passfilt.dll is not a trivial process and is much more involved than a simple win32 application. The custom password filter must interface to the Local Security Authority (the lsass.exe process) and runs as a thread of the LSA. You cannot afford a bad line of code or an overlooked exception. A bad line of code can quickly mean a BSOD (blue screen of death). A memory leak or failure to use exception handing and secure coding techniques can deal to a security vulnerability and possible exploitation. A passfilt.dll works on the password in Unicode clear text and care must be taken to properly destroy the memory used by such buffers.

                      We got started in 2001 writing custom password filters for many different organizations. After noticing many similarities among the requests we decided to write a "configurable customer password filter." We were the first to introduce a password filter controlled by a group policy. In 2005, we were the first to release a 64-bit password filter. We were the first company to put a password strength meter on the

                      Windows change password screen. Currently we are the only company to offer rules for length-based password aging (e.g. longer passwords can be kept for a longer period of time).

                      You should contemplate the following questions if you are considering the development of a custom passfilt:

                      Will the code be written in house or by an external firm? Who will handle support issues?
                      Who will maintain the code and update it (as in the case of 64-bit servers)?
                    </Text>


                    <TextVersionSubSubsectionHeading>DICTIONARY CHECKING
                    </TextVersionSubSubsectionHeading>


                    <Text>nFront Password Filter goes beyond giving you control over character types and includes a very fast dictionary check feature. In less than 1 second, nFront Password Filter can scan a 2 million word dictionary and ensure that the user's proposed new password is not contained in the dictionary file!

                      nFront Password Filter ships with a 27,000 word customizable, plain-text dictionary. The dictionary check feature looks for a case-insensitive exact match (instead of a substring match) between the proposed new password and each entry in the dictionary. The substring search feature can be enable to look for the dictionary word anywhere within the password. You can customize the dictionary by editing the file in Notepad or any other text editor of your choice.
                    </Text>


                    <TextVersionSubSubsectionHeading>OPTIONAL CLIENT TO HELP END USERS
                    </TextVersionSubSubsectionHeading>


                    <Text>nFront Password Filter comes with an optional client that you can deploy to end-user workstations. You can choose to include your own custom message to the end user or our default password rules or both. You can also display a password strength meter. All settings, of course, are controlled by GPO.

                      The client automatically works in multiple languages (English, German, French, Spanish and Italian are supported). It automatically reports the locale of the client workstation to the encrypted RPC service that supports the client. The service then formulates the password policy rules in the language appropriate to the language of the client operating system.

                      The client is compatible with Windows XP, Windows 7, Windows 8, Windows 8.1 and Windows 10. On Windows XP it operates as a GINA stub DLL (which is the only method support my Microsoft). On Windows 7 and above it operates as a credential provider.
                    </Text>

                    <TextVersionsectionHeading>SYSTEM REQUIREMENTS FOR NFRONT PASSWORD FILTER
                    </TextVersionsectionHeading>


                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc> Windows 2003, 2008, 2008 R2, Server 2012, Server 2012 R2, Server 2016, Server 2019 x86 and x64 supported for all OS
                          2 MB free disk space</TextVersionDesc></li>
                        <li><TextVersionDesc>5 minutes of time per domain controller to install</TextVersionDesc></li>
                        <li><TextVersionDesc>30 minutes of time per domain to configure and test</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>

                    <TextVersionsectionHeading>SYSTEM REQUIREMENTS FOR NFRONT PASSWORD FILTER CLIENT
                    </TextVersionsectionHeading>


                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc> Windows XP, Windows 7, Windows 8, Windows 8.1 and Windows 10. It may also be installed on servers and DCs that are Windows 2003 and above.

                          x86 and x64 supported for all OSe</TextVersionDesc></li>
                        <li><TextVersionDesc>2 MB free disk space</TextVersionDesc></li>
                      </ul>

                    </TextVersionUl>

                    <Text>

                      We suggest an automated deployment using a software GPO or a tool like Microsoft System Center.
                    </Text>




                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>




                  <div className='product_nFront_web_password_change'>

                    <TextVersionHeading>nFront Web Password Change</TextVersionHeading>


                    <Text>The nFront Web Password Change is a password change portal for AD users which is "nFront Password Filter" aware. It is a small software package that you install on Internet Information Server. It understands the password policies defined with nFront Password Filter. When the user enters his or her name and tabs to the next field the password policy rules that apply to the specific account are retrieved and displayed.
                    </Text>

                    <TextVersionSubSubsectionHeading>A PASSWORD CHANGE PORTAL THAT IS "NFRONT" AWARE.</TextVersionSubSubsectionHeading>

                    <Text>The nFront Web Password Change is a password change portal for AD users which is "nFront Password Filter" aware. It is a small software package that you install on Internet Information Server. It understands the password policies defined with nFront Password Filter. When the user enters his or her name and tabs to the next field the password policy rules that apply to the specific account are retrieved and displayed. If the user attempts a password change that is disallowed by nFront Password Filter a more detailed failure message will be displayed and the user will be given another attempt to change his or her password.
                      When a user types in their password the password policy rules that apply to their specific account are displayed.
                    </Text>


                    <TextVersionSubSubsectionHeading>nFront Web Password Change - Password Rules
                    </TextVersionSubSubsectionHeading>


                    <Text>When a user types in their password the password policy rules that apply to their specific account are displayed.
                    </Text>


                    <TextVersionSubSubsectionHeading>nFront Web Password Change - Password Failure
                    </TextVersionSubSubsectionHeading>

                    <Text>If the user types a password that does not meet the rules, the screen refreshes and shows the exact reasons for failure.
                    </Text>

                    <TextVersionsectionHeading>BENEFITS
                    </TextVersionsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Great solution for offsite users or VPN users who need to change their password.</TextVersionDesc></li>
                        <li><TextVersionDesc>You can reduce helpdesk calls from people who are not aware of the exact password policy requirements.</TextVersionDesc></li>
                        <li><TextVersionDesc>You can load the software in minutes with no need to reboot.</TextVersionDesc></li>
                      </ul>
                    </TextVersionUl>


                    <TextVersionsectionHeading>FEATURES
                    </TextVersionsectionHeading>


                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Windows 2003, 2008, 2012 or 2016 server (32-bit and 64-bit)</TextVersionDesc></li>
                        <li><TextVersionDesc>Internet Information Server</TextVersionDesc></li>
                        <li><TextVersionDesc>.NET Framework 3.5 or later installed</TextVersionDesc></li>
                        <li><TextVersionDesc>nFront Password Filter installed on all Domain Controllers</TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>

                    <TextVersionsectionHeading>UP AND RUNNING IN 5 MINUTES</TextVersionsectionHeading>
                    <Text>You can install the software in less than 5 minutes. There is no configuration except for the registration code to be entered if you purchase the product.</Text>


                    <TextVersionsectionHeading>REQUIREMENTS
                    </TextVersionsectionHeading>

                    <Text>Windows 2003, 2008, 2012 or 2016 server (32-bit and 64-bit)
                      Internet Information Server
                      .NET Framework 3.5 or later installed
                      nFront Password Filter installed on all Domain Controllers

                    </Text>

                    <TextVersionsectionHeading> THE TECHNICALS
                    </TextVersionsectionHeading>

                    <Text>
                      The software uses the same client DLL that is used in the nFront Password Filter Client. The client DLL has the technology to communicate with any DC using encrypted RPC to pull a list of password policy rules and to check the password against the rules. The software connects to the nFront Password Policy service on any DC. If the password meets the nFront Password Filter rules a call to the standard password change API is initiated. The password change may still fail if the password is one that has been used in the past X passwords depending on your domain-level password history settings.
                    </Text>

                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                  </div>



                  <div className='product_nFront_account_disable'>
                    <TextVersionHeading>nFront Account Disabler</TextVersionHeading>

                    <Text>nFront Account Disabler can automatically disable inactive and dormant accounts within your Windows Active Directory. Disabling inactive accounts is not only a security best practice but it is also part of the PCI compliance requirements and the IRS 1075 guideline.
                    </Text>


                    <TextVersionSubSubsectionHeading>NEVER WORRY ABOUT DORMANT ACCOUNTS AGAIN.</TextVersionSubSubsectionHeading>


                    <Text>
                      Wouldn't it be nice to automatically disable any accounts that have not logged on in three weeks?

                      nFront Account Disabler can automatically disable inactive and dormant accounts within your Windows Active Directory. Disabling inactive accounts is not only a security best practice but it is also part of the <a target='_blank' href='https://www.pcisecuritystandards.org/' style={{ color: `#00b0bd` }}>PCI compliance</a> requirements and the <a target='_blank' href='https://www.irs.gov/pub/irs-pdf/p1075.pdf' style={{ color: `#00b0bd` }}>IRS 1075 guideline</a>.</Text>


                    <TextVersionSubSubsectionHeading>A FULLY AUTOMATED SOLUTION:
                    </TextVersionSubSubsectionHeading>

                    <Text>Some utilities simply offer reporting and leave the work of disabling accounts up to you. nFront Account Disabler is different. If is fully automated and once configured you need only review the daily activity reports which can be emailed to you in an HTML or PDF format.
                    </Text>


                    <TextVersionsectionHeading>FEATURES
                    </TextVersionsectionHeading>

                    <Text>Determines last “true logon time” for all active directory accounts. In other words, it scans across all domain controllers to get the correct last logon time for each user.
                      Can disable accounts even though all domain controllers are not available at the time of the query.
                    </Text>


                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Can skip system accounts like IUSR_machine-name.
                        </TextVersionDesc></li>
                        <li><TextVersionDesc>Do not disable the built-in Administrator account.
                        </TextVersionDesc></li>
                        <li><TextVersionDesc>Do not disable specific groups like a group for service accounts.</TextVersionDesc></li>
                        <li><TextVersionDesc>Generates local HTML reports.</TextVersionDesc></li>
                        <li><TextVersionDesc>Can email a PDF or HTML report of the dormant accounts to an Administrator.</TextVersionDesc></li>
                        <li><TextVersionDesc>Builds a CSV file of disabled accounts.</TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>



                    <Text>Maintains a running log of all accounts that have been disabled by nFront Account Disabler. This log does not track accounts that have been disabled outside of nFront Account Disabler.
                    </Text>

                    <TextVersionsectionHeading>UP AND RUNNING IN 5 MINUTES
                    </TextVersionsectionHeading>

                    <Text>You can install and configure the software in less than 5 minutes.</Text>

                    <Text>nFront Account Disabler Configuration dialog.
                    </Text>

                    <Text>nFront Account Disabler Report Settings.
                    </Text>

                    <Text>Example Report of Dormant Accounts:
                    </Text>




                    <Text>NFRONT AD DISABLER REPORT</Text>
                    <Text>Date of Run: 4/11/2017 6:00:00 AM </Text>
                    <Text>Active User Accounts: 911</Text>
                    <Text>Settings:</Text>


                    <table>
                      <tr>
                        <th style={{ textAlign: `left` }}><Text>Disable Old Accounts:</Text> </th>
                        <td>True</td>

                      </tr>
                      <tr>
                        <th style={{ textAlign: `left` }}><Text>Ignore unreachable domain controllers:</Text></th>
                        <td>False</td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: `left` }}><Text>Old Account Age (in days):</Text></th>
                        <td>90</td>
                      </tr>

                      <tr>
                        <th style={{ textAlign: `left` }}><Text>Reporting / Service Interval (in hours):</Text></th>
                        <td>24</td>
                      </tr>

                      <tr>
                        <th style={{ textAlign: `left` }}><Text>Report To Address </Text></th>
                        <td></td>
                      </tr>

                      <tr>
                        <th style={{ textAlign: `left` }}><Text>Report From Address </Text></th>
                        <td></td>
                      </tr>

                      <tr>
                        <th style={{ textAlign: `left` }}><Text>SMTP Server: </Text></th>
                        <td></td>
                      </tr>

                      <tr>
                        <th style={{ textAlign: `left` }}><Text>Users with a dormant account</Text></th>
                        <td></td>
                      </tr>


                    </table>


                    <table>
                      <tr>
                        <th style={{ textAlign: `left` }}><Text>Username</Text></th>
                        <th style={{ textAlign: `left` }}><Text>Last Logon Time</Text></th>
                        <th style={{ textAlign: `left` }}><Text>Server Name </Text></th>
                        <th style={{ textAlign: `left` }}><Text>Disabled</Text></th>
                      </tr>
                      <tr>
                        <td style={{ textAlign: `center` }}><Text>serviceUser1</Text></td>
                        <td style={{ textAlign: `center` }}><Text>3/17/2016 4:17:18 PM</Text></td>
                        <td style={{ textAlign: `center` }}><Text>DC120.lab12.nfrontlabs.local</Text></td>
                        <td style={{ textAlign: `center` }}><Text>Yes</Text></td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: `center` }}><Text>Joe Smith</Text> </td>
                        <td style={{ textAlign: `center` }}><Text>11/17/2016 8:17:18 AM</Text></td>
                        <td style={{ textAlign: `center` }}><Text>DC120.lab12.nfrontlabs.local</Text></td>
                        <td style={{ textAlign: `center` }}><Text>Yes</Text></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: `center` }}><Text>Mary Poppins</Text> </td>
                        <td style={{ textAlign: `center` }}><Text>Never logged on</Text></td>
                        <td style={{ textAlign: `center` }}><Text>DC120.lab12.nfrontlabs.local </Text></td>
                        <td style={{ textAlign: `center` }}><Text>Yes</Text></td>
                      </tr>

                    </table>

                    <TextVersionsectionHeading>LIMITATIONS OF THE EVALUATION VERSION
                    </TextVersionsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Reports up to 3 inactive accounts
                        </TextVersionDesc></li>
                        <li><TextVersionDesc>Does not disable any inactive accounts
                        </TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>


                    <TextVersionsectionHeading>SYSTEM REQUIREMENTS

                    </TextVersionsectionHeading>

                    <TextVersionUl>
                      <ul>
                        <li><TextVersionDesc>Windows 2003, 2008, 2012 or 2016 server2 MB free disk space
                        </TextVersionDesc></li>
                        <li><TextVersionDesc>Microsoft .NET Framework 2.0 or later
                        </TextVersionDesc></li>

                      </ul>

                    </TextVersionUl>


                    <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />
                  </div>




                  <div className='solution'>

                    <TextVersionHeading>Solutions</TextVersionHeading>

                    <div className='solution_security'>

                      <TextVersionSubHeading>Security</TextVersionSubHeading>

                      <div className='solution_identity_access_management'>

                        <TextVersionSubSubsectionHeading>Identity & Access Management</TextVersionSubSubsectionHeading>

                        <Text>Managing user identities and their rights to access resources throughout the identity life cycle is critical for effective identity and access management, in both our physical and logical worlds. OPM, enables legitimated as well as approved users to access applications and data while protecting these assets against unauthorized access without interfering with productivity while balancing the security risks with the cost of acquisition and management.</Text>

                        <TextVersionsectionHeading>Features</TextVersionsectionHeading>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc>OPM enables organizations to provide authorized user access to systems, portfolio of products and services
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Provides efficient management of the entire identity lifecycle: assessing, planning, implementing, auditing, monitoring and maintaining identities and access privileges
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Identity life-cycle management, with user self-care, enrollment, proofing, provisioning, recertification and de-provisioning
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Identity control, including access and privacy control, role management, single sign-on (SSO) and auditing
                            </TextVersionDesc></li>

                          </ul>

                        </TextVersionUl>
                        <br />

                        <Text>Access authorization provides timely access throughout the user's life cycle across multiple environments and security domains while enforcing security policies and protecting against external threats with:</Text>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc>Centralized control for consistent execution of security policies across multiple applications and users
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Automation with a policy-based security infrastructure guided by IT requirements and business goals
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Single Sign-On (SSO) to improve the user experience and reduce help-desk costs
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Integration of access and identity management within one infrastructure environment
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Identity federation to share user authentication and attribute information between trusted Web services applications
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>IMulti-factor authentication to enhance security
                            </TextVersionDesc></li>


                          </ul>

                        </TextVersionUl>


                        <TextVersionsectionHeading>Related Products</TextVersionsectionHeading>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc><ScrollLink to='product_prime'>PRIME</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='product_ldap'>LDAP PROJECT</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'>Microfocus</a>
                            </TextVersionDesc></li>

                          </ul>

                        </TextVersionUl>

                        <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink></TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                            </TextVersionDesc></li>
                          </ul>

                        </TextVersionUl>



                        <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                      </div>

                    </div>



                    <div className='solution_end_point'>

                      <TextVersionSubSubsectionHeading>End Point Security Solution</TextVersionSubSubsectionHeading>

                      <Text>OPM Endpoint Management solutions protect your organization's confidential information and help you achieve regulatory compliance across physical and virtual environments. With powerful endpoint protection capabilities, users can use endpoints like desktops, notebooks, handhelds, or USB drives without the risk of data loss. IT security is assured without hampering productivity.</Text>

                      <TextVersionsectionHeading>Features</TextVersionsectionHeading>

                      <Text>OPM data and information security solutions deliver comprehensive capabilities for data protection and access management that can be integrated to help address information security across the enterprise with:</Text>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc>Data encryption(Folder level and Full Disk with multi factorauthentication)
                          </TextVersionDesc></li>
                          <li><TextVersionDesc>Endpoint control(Including personal firewall, wireless connection management, application control, device control, patch delivery, and network access control)
                          </TextVersionDesc></li>
                          <li><TextVersionDesc>Access protection policy management
                          </TextVersionDesc></li>
                          <li><TextVersionDesc>Information assess, protect, and manage services
                          </TextVersionDesc></li>
                          <li><TextVersionDesc>Endpoint device patching and virus signature updates
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>


                      <TextVersionsectionHeading>Related Products</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'>Microfocus</a>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><a target='_blank' href='https://secude.com/'>Secude</a>
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>


                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>


                    <div className='solution_sso'>
                      <TextVersionSubSubsectionHeading>Single Sign-on (SSO)</TextVersionSubSubsectionHeading>
                      <Text>The challenge many organizations facing too many of password as well as strong password, it keeps on administrative effort for maintenance password. Adopt of Single Sign-On it can reduce the administration effort and better on user experience. Also we provide a passwordless option to customer.</Text>
                      <TextVersionsectionHeading>Features</TextVersionsectionHeading>
                      <Text>A portal content all of the required application, once log-ed to the portal. Users can base on his/her roles for access the difference applications without password.</Text>
                      <TextVersionsectionHeading>Related Products</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'>Microfocus</a>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><a target='_blank' href='https://secude.com/'>Secude</a>
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>



                    <div className='solution_multi_factor'>
                      <TextVersionSubSubsectionHeading>Multi-factor Authentication</TextVersionSubSubsectionHeading>
                      <Text>We can establish the mult-factor Authentication such as Face Recognition Smart Card, Push Message, SMS, OTP etc. To increase the security and control the authentication</Text>

                      <Text>The solution can integration with Windows Desktop, Network Equipment, Mobile, Web sites etc</Text>
                      <TextVersionsectionHeading>Related Products</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'>Microfocus</a>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><a target='_blank' href='https://secude.com/'>Secude</a>
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>




                    <div className='solution_end_user'>

                      <TextVersionHeading>End User</TextVersionHeading>

                      <div className='solution_end_user_portal_solution'>
                        <TextVersionSubSubsectionHeading>End User Portal Solution</TextVersionSubSubsectionHeading>
                        <Text>
                          Your younger workers are used to using a lot of social collaboration tools in their personal lives. This is a great boom for businesses. In the right hands, these kinds of tools can dramatically boost innovation and productivity. Our real-time and collaboration tools enable these new ways of working together, with a strong focus on interoperability and security.
                        </Text>

                        <Text>It is relatively clear what a portal can be but why should an organization consider one?</Text>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc> Portals, serve as a core site that mediate in various ways between users, and the vast range of information that is out there in hyperspace. </TextVersionDesc></li>
                            <li><TextVersionDesc>Provide a platform allows people to interact with others, applications, processes, documents and other content in a personalized and role-based fashion.
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Provides needed functionality, but requires you to input the way that it needs to work in your organization.
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Portal allows customizing of your work environment to your own needs, present resources in a consistent fashion, and can provide a personalized experience based upon your previous preferences or specific role within an organization.
                            </TextVersionDesc></li>
                          </ul>

                        </TextVersionUl>

                        <TextVersionsectionHeading>Related Products</TextVersionsectionHeading>
                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc><a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'>Microfocus</a>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><a target='_blank' href='https://secude.com/'>Secude</a>
                            </TextVersionDesc></li>
                          </ul>

                        </TextVersionUl>

                        <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                            </TextVersionDesc></li>
                          </ul>

                        </TextVersionUl>

                        <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                      </div>

                    </div>






                    <div className='solution_end_user_mobile'>
                      <TextVersionSubSubsectionHeading>Mobile Enterprise Solution</TextVersionSubSubsectionHeading>
                      <Text>
                        The challenge many organizations facing today is how to empower these mobile professionals and make them more productive while they are on the road. We provided solutions which allow businesses, government agencies, and mobile workers to maximize business and personal productivity by providing secure, easy-to-use, and instant mobile access to data, applications, and services on hundreds of mobile devices.
                      </Text>
                      <TextVersionsectionHeading>Features</TextVersionsectionHeading>
                      <Text>OPM works closely with mobile partners providing mobile data management solution for popular handheld devices like iPhone, iPad, Android and BlackBerry so that they can access critical data anytime, anywhere securely. With our dedicated IT certified experts who skilled in BlackBerry@Enterprise Server and GOOD Enterprise Server, OPM can provide you the most cost-effective Mobile Data Management Solution of your organization.</Text>
                      <Text><a target='_blank' href={endUserPdf}><u>Click to download PDF</u></a></Text>


                      <TextVersionsectionHeading>Related Products</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><a target='_blank' href='https://www.hkcsl.com/'>CSL</a>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><a target='_blank' href='https://www.blackberry.com/cn/zh'>Blackberry</a>
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>



                    <div className='solution_vdi'>
                      <TextVersionSubSubsectionHeading>Virtual Desktop Infrastructure (VDI)</TextVersionSubSubsectionHeading>
                      <Text>
                        Remote or hybrid work model are common nowadays, virtual desktop infrastructure facilitates secure and convenient remote access for a more efficient and cost-effective business model.
                      </Text>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc>Cost savings
                          </TextVersionDesc></li>
                          <li><TextVersionDesc> Improved security
                          </TextVersionDesc></li>
                          <li><TextVersionDesc>Centralized management
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>


                      <TextVersionsectionHeading>Related Products</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'>Microfocus</a>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><a target='_blank' href='https://secude.com/'>Secude</a>
                          </TextVersionDesc></li>
                        </ul>

                      </TextVersionUl>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>



                    <div className='solution_by_industry'>

                      <TextVersionHeading>By Industry</TextVersionHeading>

                      <div className='solution_industry_health'>

                        <TextVersionSubSubsectionHeading>Health Care Solution</TextVersionSubSubsectionHeading>


                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc>Patient Management System
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Pharmaceutical Dispensing System
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Drug Allergy Alert System
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Drug Inventory Management System
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Out-patient Management System
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Pharmaceutical Registration
                            </TextVersionDesc></li>
                            <li><TextVersionDesc>Import and Export integration
                            </TextVersionDesc></li>

                          </ul>

                        </TextVersionUl>


                        <TextVersionsectionHeading>Features</TextVersionsectionHeading>

                        <Text>
                          OPM works closely with government of the design of the health care solution as well as the government monitoring organisation.
                        </Text>

                        <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                            </TextVersionDesc></li>

                          </ul>

                        </TextVersionUl>

                        <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                      </div>

                    </div>



                    <div className='solution_cms'>
                      <TextVersionSubSubsectionHeading>Case Management System</TextVersionSubSubsectionHeading>

                      <Text>Case Management System for case handling of a group or individual people in need and its follow-up action of them.</Text>
                      <Text>OPM works closely with Non-governmental organizations (NGO) to design case solution for those people and allow inspector to keep tracking the progress of the people.</Text>


                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>



                    <div className='solution_call_log'>
                      <TextVersionSubSubsectionHeading>Call Log System</TextVersionSubSubsectionHeading>

                      <Text>Call Log System for handling phone call of a group or individual people in need and its follow-up action of them</Text>
                      <Text>OPM works closely with Non-governmental organizations (NGO) to design case solution for those people and allow inspector to keep tracking the progress of the people.</Text>


                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>






                    <div className='solution_booking'>
                      <TextVersionSubSubsectionHeading>Booking System</TextVersionSubSubsectionHeading>

                      <Text>Booking System for handling of a appointment in a timeslot and information related to the appointment</Text>
                      <Text>OPM works closely with Non-governmental organizations (NGO) to design booking system with timeslot handling solution for public to allow them to consult professional people for their needs</Text>


                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>



                    <div className='solution_straming'>
                      <TextVersionSubSubsectionHeading>Interactive Live Streaming System</TextVersionSubSubsectionHeading>

                      <Text>Live Streaming System to allow our client to broadcast the real life performance such as live concert with the interaction with the public</Text>
                      <Text>OPM works closely with commercial company to design interactive live streaming system to handle large amount of user with low-latency. In the meantime to allow the public to interact with the performer.</Text>


                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>




                    <div className='solution_block_chain'>
                      <TextVersionHeading>Blockchain</TextVersionHeading>
                      <div className='solution_authentication'>
                        <TextVersionSubSubsectionHeading>Authentication</TextVersionSubSubsectionHeading>

                        <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                        <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                            </TextVersionDesc></li>

                          </ul>

                        </TextVersionUl>

                        <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />
                      </div>
                    </div>



                    <div className='solution_data_protection'>

                      <TextVersionSubSubsectionHeading>Data Protection</TextVersionSubSubsectionHeading>

                      <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>


                    <div className='solution_transaction_processing'>

                      <TextVersionSubSubsectionHeading>Transaction Processing</TextVersionSubSubsectionHeading>

                      <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>



                    <div className='solution_ai'>
                      <TextVersionHeading>Artificial Intelligence (AI)</TextVersionHeading>
                      <div className='solution_nlp'>
                        <TextVersionSubSubsectionHeading>Natural Language Processing (NLP)</TextVersionSubSubsectionHeading>

                        <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                        <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                            </TextVersionDesc></li>

                          </ul>

                        </TextVersionUl>

                        <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />
                      </div>
                    </div>


                    <div className='solution_chat_bot'>

                      <TextVersionSubSubsectionHeading>ChatBot</TextVersionSubSubsectionHeading>

                      <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>


                    <div className='solution_ocr'>

                      <TextVersionSubSubsectionHeading>Optical Character Recognition (OCR)</TextVersionSubSubsectionHeading>

                      <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>



                    <div className='solution_image_recognition'>

                      <TextVersionSubSubsectionHeading>Image Recognition</TextVersionSubSubsectionHeading>

                      <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_application_development'>Applications Development</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>





                    <div className='solution_hybrid_cloud'>
                      <TextVersionHeading>Hybrid Cloud Infrastructure</TextVersionHeading>
                      <div className='solution_k8s'>
                        <TextVersionSubSubsectionHeading>Kubernetes (K8s)</TextVersionSubSubsectionHeading>

                        <Text>Kubernetes is a portable, extensible, open source platform for managing containerized workloads and services, that facilitates both declarative configuration and automation.</Text>

                        <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                        <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                        <TextVersionUl>
                          <ul>
                            <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                            </TextVersionDesc></li>
                            <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                            </TextVersionDesc></li>

                          </ul>

                        </TextVersionUl>

                        <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />
                      </div>
                    </div>



                    <div className='solution_persistent'>

                      <TextVersionSubSubsectionHeading>Persistent Storage</TextVersionSubSubsectionHeading>

                      <Text>Persistent Storage is any storage device that retains data after there is no power supply to that device.</Text>

                      <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>



                    <div className='solution_private_registry'>

                      <TextVersionSubSubsectionHeading>Private Registry</TextVersionSubSubsectionHeading>

                      <Text>Private Registry is a stateless, highly scalable central space for storing and distributing container images. They provide secure image management and a fast way to pull and push images with the right permissions.</Text>

                      <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>




                    <div className='solution_orchestrator'>

                      <TextVersionSubSubsectionHeading>Orchestrator</TextVersionSubSubsectionHeading>

                      <Text>Orchestration is automating many tasks together. It’s automation not of a single task but an entire IT-driven process. Orchestrating a process, then, is automating a series of individual tasks to work together to achieve easily management.</Text>

                      <Text><ScrollLink to='contact_us'>Contact Us</ScrollLink></Text>

                      <TextVersionsectionHeading>Related Services</TextVersionsectionHeading>

                      <TextVersionUl>
                        <ul>
                          <li><TextVersionDesc><ScrollLink to='service_consulting_service'> Consulting Services</ScrollLink>
                          </TextVersionDesc></li>
                          <li><TextVersionDesc><ScrollLink to='service_technical_support'> Technical Support</ScrollLink>
                          </TextVersionDesc></li>

                        </ul>

                      </TextVersionUl>

                      <div style={{ height: `2px`, width: `100%`, backgroundColor: `#000000`, marginTop: `30px` }} />

                    </div>


                  </div>


                  <div className='contact_us'>
                    <TextVersionHeading>Contact us</TextVersionHeading>
                    <Text>info@opm.com.hk</Text>

                  </div>

                  <div className='join_us'>
                    <TextVersionHeading>Join Us</TextVersionHeading>

                  </div>


                </div>



              </div>


            </TextVersionWrapper>
          </Element>
        </Wrapper>
      )}
    </ThemeContext.Consumer>
  )
}

export default TextVersionHeader
import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 50px;
  height: 50px;

  @media only screen and ${device.mobileL} {
    width: 18px;
    height: 18px;
  }
  
  svg {
    fill:#343434;
  }
`;

const SolutionAuth1Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 59.528 62.879">
      <g>
        <path d="M755.36 2912a.873.873 0 0 0-.58-.676.885.885 0 0 0-.878.182c-.019.017-2 1.751-7.81 1.751a37.468 37.468 0 0 1-4.772-.331c-10.181-1.312-14.652-5.792-14.694-5.836a.877.877 0 0 0-1.257 0c-.044.044-4.516 4.524-14.7 5.837a37.392 37.392 0 0 1-4.771.331c-5.806 0-7.79-1.734-7.8-1.742a.874.874 0 0 0-1.468.485c-.063.371-5.86 37.412 28.928 57.585a.874.874 0 0 0 .877 0c34.786-20.176 28.988-57.217 28.925-57.586zm-29.365 51.92c-12.054-7.62-19.826-18.281-23.107-31.7a57.318 57.318 0 0 1-1.609-14.244 34.963 34.963 0 0 0 10.039-.019 35.411 35.411 0 0 0 14.679-5 35.382 35.382 0 0 0 14.674 5 34.969 34.969 0 0 0 10.035.019c.114 9.846-2.38 31.788-24.711 45.942z" transform="translate(-696.231 -2906.822)" />
        <path d="M730.009 2924.8H722.1v7.948h-7.948v7.907h7.948v7.948h7.907v-7.948h7.948v-7.907h-7.948z" transform="translate(-696.231 -2906.822)" />
      </g>
    </svg>
  </IconWrapper>
);

export default SolutionAuth1Icon;




import React from 'react'
import { Helmet } from "react-helmet";
// import Sidebar from '../components/slidebar/Sidebar'
import ServiceContent from '../components/serviceContent/ServiceContent'
import { Footer, Partner, FeatureList, WhatGPT3, Header, Features, Solution } from '../containers';
import serviceBg from '../assets/service_bg.png';
import "../assets/css/Common.css"
import OPMLogo from '../assets/OPMLogo';
import styled from 'styled-components'
import { device } from '../common.styles';

const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AR;
  font-size: 88px;
  color: white;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const service = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services - Welcome to OPM System Company Limited</title>
        <meta name="Services" content="OPM - Services" />
      </Helmet>
      <Header bgImg={serviceBg} tone="dark" contentAlign='end'>
        <StyledOPMLogo />
        <Slogan1>
          Services
        </Slogan1>
      </Header>
      <ServiceContent />
      <Footer bgcolor='#343434' haveSideMenu={true} />
    </div>
  )
}

export default service
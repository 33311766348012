import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import styled from 'styled-components';
import { device, SectionDesc } from '../../common.styles';

const MobileSidebar = ({ onClick }) => {
    const ServicesSections = {
        APPLICATION_DEVELOPMENT: 'APPLICATION_DEVELOPMENT',
        CONSULTING_SERVICE: 'CONSULTING_SERVICE',
        TECHNICAL_SUPPORT: 'TECHNICAL_SUPPORT',
        OPM_CLOUD_COMPUTING_SERVICES: 'OPM_CLOUD_COMPUTING_SERVICES',
        HELP_DESK_SERVICES: 'HELP_DESK_SERVICES',
    }
    const ProductsSections = {
        IBROKERS: 'IBROKERS',
        ITRADING: 'ITRADING',
        PRIME: 'PRIME',
        LDAP_PROTECT: 'LDAP_PROTECT',
        CMSMART: 'CMSMART',
        ISTORAGE: 'ISTORAGE',
        DIGITTRADE: 'DIGITTRADE',
        NFRONT_PASSWORD_FILTER: 'NFRONT_PASSWORD_FILTER',
        NFRONT_WEB_PASSWORD_CHANGE: 'NFRONT_WEB_PASSWORD_CHANGE',
        NFRONT_ACCOUNT_DISABLER: 'NFRONT_ACCOUNT_DISABLER',
    }
    const SolutionsSections = {
        SECURITY: 'SECURITY',
        SECURITY_IAM: 'SECURITY_IAM',
        SECURITY_EPSS: 'SECURITY_EPSS',
        SECURITY_SSO: 'SECURITY_SSO',
        SECURITY_MFA: 'SECURITY_MFA',

        END_USER: 'END_USER',
        END_USER_PORTAL: 'END_USER_PORTAL',
        MOBILE_ENTERPRISE: 'MOBILE_ENTERPRISE',
        VIRTUAL_DESKTOP: 'VIRTUAL_DESKTOP',

        BY_INDUSTRY: 'BY_INDUSTRY',
        HEALTH_CARE: 'HEALTH_CARE',
        CASE_MANAGEMENT: 'CASE_MANAGEMENT',
        CALL_LOG_SYSTEM: 'CALL_LOG_SYSTEM',
        STREAMING_SYSTEM: 'STREAMING_SYSTEM',

        BLOCKCHAIN: 'BLOCKCHAIN',
        AUTHENTICATION: 'AUTHENTICATION',
        DATA_PROTECTION: 'DATA_PROTECTION',
        BOOKING_SYSTEM: 'BOOKING_SYSTEM',
        TRANSACTION: 'TRANSACTION',

        AI: 'AI',
        NLP: 'NLP',
        CHATBOT: 'CHATBOT',
        OPTICAL_CHRACTER: 'OPTICAL_CHRACTER',
        IMAGE_RECOGNITION: 'IMAGE_RECOGNITION',
        HYBRID_CLOUD_INFRA: 'HYBRID_CLOUD_INFRA',
        K8S: 'K8S',
        PERSIST_STORAGE: 'PERSIST_STORAGE',
        PRIVATE_REGISTRY: 'PRIVATE_REGISTRY',
        ORCHESTRATOR: 'ORCHESTRATOR',
    }

    const isMobile = useMediaQuery({ query: device.mobileL });

    const menuItemOnClick = (name) => {
        onClick();
        if (name) {
            const to = document.querySelector(`div[name="${name}"]`);
            if (to) {
                if (isMobile) {
                    setTimeout(() => {
                        const headerOffset = 80;
                        const elementPosition = to.getBoundingClientRect().top;
                        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                        window.scrollTo({ top: offsetPosition });
                    }, 100);
                } else {
                    to.scrollIntoView();
                }
            }
        }
    };

    const StyledSectionDesc = styled.span`
        white-space: pre-wrap;
        color: #343434;
        font-weight: bold;
    `;

    return (
        <Sidebar width='85%' style={{ height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
                <a href="/pages/Sitemap" onClick={() => menuItemOnClick()}><StyledSectionDesc>Sitemap</StyledSectionDesc></a>
                <a href="/pages/TextVersion" onClick={() => menuItemOnClick()}><StyledSectionDesc>Text Version</StyledSectionDesc></a>
                <ScrollLink to="content" offset={isMobile ? -80 : null} onClick={() => menuItemOnClick()}><StyledSectionDesc>Skip to Content</StyledSectionDesc></ScrollLink>
            </div>
            <Menu>
                <MenuItem routerLink={<a href="/" />} onClick={() => menuItemOnClick()}><StyledSectionDesc>Home</StyledSectionDesc></MenuItem>
                <MenuItem routerLink={<a href="/pages/WhyOPM" />} onClick={() => menuItemOnClick()}><StyledSectionDesc>Why OPM</StyledSectionDesc></MenuItem>
                <SubMenu label={<StyledSectionDesc>Services</StyledSectionDesc>}>
                    <MenuItem routerLink={<a href="/pages/Services#Application-Development" onClick={() => { menuItemOnClick(ServicesSections.APPLICATION_DEVELOPMENT); }} />}><StyledSectionDesc>Applications Development</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<Link reloadDocument={true} to="/pages/Services#Consulting-Services" onClick={() => { menuItemOnClick(ServicesSections.CONSULTING_SERVICE); }} />}><StyledSectionDesc>Consulting Services</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Services#Technical-Support" onClick={() => { menuItemOnClick(ServicesSections.TECHNICAL_SUPPORT); }} />}><StyledSectionDesc>Technical Support</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Services#OPM-Cloud-Computing-Services" onClick={() => { menuItemOnClick(ServicesSections.OPM_CLOUD_COMPUTING_SERVICES); }} />}><StyledSectionDesc>OPM Cloud Computing Services</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Services#Help-Desk-Services" onClick={() => { menuItemOnClick(ServicesSections.HELP_DESK_SERVICES); }} />}><StyledSectionDesc>Help Desk Services</StyledSectionDesc></MenuItem>
                </SubMenu>
                <SubMenu label={<StyledSectionDesc>Products</StyledSectionDesc>}>
                    <MenuItem routerLink={<a href="/pages/Products#iBrokers" onClick={() => { menuItemOnClick(ProductsSections.IBROKERS); }} />}><StyledSectionDesc>iBrokers</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#iTrading" onClick={() => { menuItemOnClick(ProductsSections.ITRADING); }} />}><StyledSectionDesc>iTrading</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#PRIME" onClick={() => { menuItemOnClick(ProductsSections.PRIME); }} />}><StyledSectionDesc>PRIME</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#LDAP" onClick={() => { menuItemOnClick(ProductsSections.LDAP_PROTECT); }} />}><StyledSectionDesc>LDAP PROTECT</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#CMSmart" onClick={() => { menuItemOnClick(ProductsSections.CMSMART); }} />}><StyledSectionDesc>CMSmart</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#iStorage" onClick={() => { menuItemOnClick(ProductsSections.ISTORAGE); }} />}><StyledSectionDesc>iStorage</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#DIGITTRADE" onClick={() => { menuItemOnClick(ProductsSections.DIGITTRADE); }} />}><StyledSectionDesc>DIGITTRADE</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#nFront-Password-Filiter" onClick={() => { menuItemOnClick(ProductsSections.NFRONT_PASSWORD_FILTER); }} />}><StyledSectionDesc>nFront Password Filter</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#nFront-Web-Password-Change" onClick={() => { menuItemOnClick(ProductsSections.NFRONT_WEB_PASSWORD_CHANGE); }} />}><StyledSectionDesc>nFront Web Password Change</StyledSectionDesc></MenuItem>
                    <MenuItem routerLink={<a href="/pages/Products#nFront-Account-Disabler" onClick={() => { menuItemOnClick(ProductsSections.NFRONT_ACCOUNT_DISABLER); }} />}><StyledSectionDesc>nFront Account Disabler</StyledSectionDesc></MenuItem>
                </SubMenu>
                <SubMenu label={<StyledSectionDesc>Solutions</StyledSectionDesc>}>
                    <SubMenu label={<StyledSectionDesc>Security</StyledSectionDesc>}>
                        <MenuItem routerLink={<a href="/pages/Solutions#SECURITY_IAM" onClick={() => { menuItemOnClick(SolutionsSections.SECURITY_IAM); }} />}><StyledSectionDesc>Identity & Access Management</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#SECURITY_EPSS" onClick={() => { menuItemOnClick(SolutionsSections.SECURITY_EPSS); }} />}><StyledSectionDesc>End Point Security Solution</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#SECURITY_SSO" onClick={() => { menuItemOnClick(SolutionsSections.SECURITY_SSO); }} />}><StyledSectionDesc>Single Sign-on (SSO)</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#SECURITY_MFA" onClick={() => { menuItemOnClick(SolutionsSections.SECURITY_MFA); }} />}><StyledSectionDesc>Multi-factor Authentication</StyledSectionDesc></MenuItem>
                    </SubMenu>
                    <SubMenu label={<StyledSectionDesc>End User</StyledSectionDesc>}>
                        <MenuItem routerLink={<a href="/pages/Solutions#END_USER_PORTAL" onClick={() => { menuItemOnClick(SolutionsSections.END_USER_PORTAL); }} />}><StyledSectionDesc>End User Portal Solution</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#MOBILE_ENTERPRISE" onClick={() => { menuItemOnClick(SolutionsSections.MOBILE_ENTERPRISE); }} />}><StyledSectionDesc>Mobile Enterprise Solution</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#VIRTUAL_DESKTOP" onClick={() => { menuItemOnClick(SolutionsSections.VIRTUAL_DESKTOP); }} />}><StyledSectionDesc>Virtual Desktop Infrastructure (VDI)</StyledSectionDesc></MenuItem>
                    </SubMenu>
                    <SubMenu label={<StyledSectionDesc>By Industry</StyledSectionDesc>}>
                        <MenuItem routerLink={<a href="/pages/Solutions#HEALTH_CARE" onClick={() => { menuItemOnClick(SolutionsSections.HEALTH_CARE); }} />}><StyledSectionDesc>Health Care Solution</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#CASE_MANAGEMENT" onClick={() => { menuItemOnClick(SolutionsSections.CASE_MANAGEMENT); }} />}><StyledSectionDesc>Case Management System</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#CALL_LOG_SYSTEM" onClick={() => { menuItemOnClick(SolutionsSections.CALL_LOG_SYSTEM); }} />}><StyledSectionDesc>Call Log System</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#BOOKING_SYSTEM" onClick={() => { menuItemOnClick(SolutionsSections.BOOKING_SYSTEM); }} />}><StyledSectionDesc>Booking System</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#STREAMING_SYSTEM" onClick={() => { menuItemOnClick(SolutionsSections.STREAMING_SYSTEM); }} />}><StyledSectionDesc>Interactive Live Streaming System</StyledSectionDesc></MenuItem>
                    </SubMenu>
                    <SubMenu label={<StyledSectionDesc>Blockchain</StyledSectionDesc>}>
                        <MenuItem routerLink={<a href="/pages/Solutions#AUTHENTICATION" onClick={() => { menuItemOnClick(SolutionsSections.AUTHENTICATION); }} />}><StyledSectionDesc>Authentication</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#DATA_PROTECTION" onClick={() => { menuItemOnClick(SolutionsSections.DATA_PROTECTION); }} />}><StyledSectionDesc>Data Protection</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#TRANSACTION" onClick={() => { menuItemOnClick(SolutionsSections.TRANSACTION); }} />}><StyledSectionDesc>Transaction Processing</StyledSectionDesc></MenuItem>
                    </SubMenu>
                    <SubMenu label={<StyledSectionDesc>Artificial Intelligence (AI)</StyledSectionDesc>}>
                        <MenuItem routerLink={<a href="/pages/Solutions#NLP" onClick={() => { menuItemOnClick(SolutionsSections.NLP); }} />}><StyledSectionDesc>Natural Language Processing (NLP)</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#CHATBOT" onClick={() => { menuItemOnClick(SolutionsSections.CHATBOT); }} />}><StyledSectionDesc>ChatBot</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#OPTICAL_CHRACTER" onClick={() => { menuItemOnClick(SolutionsSections.OPTICAL_CHRACTER); }} />}><StyledSectionDesc>Optical Character Recognition (OCR)</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#IMAGE_RECOGNITION" onClick={() => { menuItemOnClick(SolutionsSections.IMAGE_RECOGNITION); }} />}><StyledSectionDesc>Image Recognition</StyledSectionDesc></MenuItem>
                    </SubMenu>
                    <SubMenu label={<StyledSectionDesc>Hybrid Cloud Infrastructure</StyledSectionDesc>}>
                        <MenuItem routerLink={<a href="/pages/Solutions#K8S" onClick={() => { menuItemOnClick(SolutionsSections.K8S); }} />}><StyledSectionDesc>Kubernetes (K8s)</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#PERSIST_STORAGE" onClick={() => { menuItemOnClick(SolutionsSections.PERSIST_STORAGE); }} />}><StyledSectionDesc>Persistent Storage</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#PRIVATE_REGISTRY" onClick={() => { menuItemOnClick(SolutionsSections.PRIVATE_REGISTRY); }} />}><StyledSectionDesc>Private Registry</StyledSectionDesc></MenuItem>
                        <MenuItem routerLink={<a href="/pages/Solutions#ORCHESTRATOR" onClick={() => { menuItemOnClick(SolutionsSections.ORCHESTRATOR); }} />}><StyledSectionDesc>Orchestrator</StyledSectionDesc></MenuItem>
                    </SubMenu>
                </SubMenu>
                <MenuItem routerLink={<a href="/pages/ContactUs" />} onClick={() => menuItemOnClick()}><StyledSectionDesc>Contact Us</StyledSectionDesc></MenuItem>
                <MenuItem routerLink={<a href="/pages/JoinUs" />} onClick={() => menuItemOnClick()}><StyledSectionDesc>Join Us</StyledSectionDesc></MenuItem>
            </Menu>
        </Sidebar >
    );
};

export default MobileSidebar
import React from 'react';
import { Helmet } from "react-helmet";
import styled from 'styled-components';
import OPMLogo from '../assets/OPMLogo';
import productBg from '../assets/product_bg.png';
import { device } from '../common.styles';
import ProductContent from '../components/productContent/ProductContent';
import { Footer, Header } from '../containers';

const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AR;
  font-size: 88px;
  color: white;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const Product = () => {
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Product - Welcome to OPM System Company Limited</title>
        <meta name="Products" content="OPM - Products" />
      </Helmet>
      <Header bgImg={productBg} tone="dark">
        <StyledOPMLogo />
        <Slogan1>Products</Slogan1>
      </Header>
      <ProductContent />
      <Footer haveSideMenu={true} />
    </div>
  )
}

export default Product
import React, { useEffect } from 'react';


import "../DropdownList/dropdown.css";


import './joinUscontent.css';


import styled, { withTheme } from 'styled-components';
import { device } from '../../common.styles';
import { LocationDropDownList } from '../DropdownList/LocationDropDownList';
import { RecipeDropDownList } from '../DropdownList/RecipDropDownList';
import { TypeDropDownList } from '../DropdownList/TypeDropDownList';
import JoinUsTitle from '../JoinUsTitle/JoinUsTitle';
// import ConsultingService from '../consulting Services/ConsultingService';

const DropdownWrapper = styled.div`
  // display: flex;
  // max-width: 800px;
  // width: 100%;
  // margin: auto;

  // section {
  //   width: 33.333%;
  //   padding: 0 8px;
  //   box-sizing: border-box;
  // }

  // @media only screen and ${device.mobileL} {
  //   display: inline;

  //   section {
  //     width: 100%;
  //   }
  // }
`;

const contentCss = `.content {
  flex-grow: 1;
}`;



// function onMenuClick() {

//   const [currentIndex, setCurrentIndex] = React.useState(0)

// }





const responArr = [
  {
    desc: 'Provide end-user software applications and computer hardware support and troubleshooting in a professional manner.',
  },
  {
    desc: 'Keep record of daily data communication transactions, problems and remedial action taken',

  }, {
    desc: 'Handle the user requests according to the IT standards/procedures/guidelines',

  }, {
    desc: 'Responsible to work according to the shift duty assigned and support roster for 24/7 technical support to End users',

  }, {
    desc: 'Report Line manager or Supervisor and finish the duties with proper time management',
  },
];

const requireArr = [
  "Work in customer site in Tung Chung",
  "Diploma or above in Information Technology or Computer related disciplines",
  "Hand-on experience in IT Helpdesk and End-User support",
  "I.T. Certificates holder is an advantage",
  "Good command in Cantonese and English, Putonghua is an advantage",
];

const jobsRawDatasource = [
  {
    location: `Hong Kong`,
    title: 'Senior Software Engineer',
    category: 'Engineer',
    type: "Full Time",
    responsibilies: responArr,
    requirement: requireArr,
  },
  {
    location: `Hong Kong`,
    title: 'Software Engineer',
    category: 'Engineer',
    type: "Part Time",
    responsibilies: responArr,
    requirement: requireArr,
  },
  {
    location: `Cheung Sha Wan`,
    title: 'Receptionist',
    category: 'Administrator',
    type: "Contract",
    responsibilies: responArr,
    requirement: requireArr,
  },

]
const JoinUsContent = () => {

  const [recipeCategory, setRecipeCategory] = React.useState(null);
  const [typeCategory, setTypeCategory] = React.useState(null);
  const [locationCategory, setLocationCategory] = React.useState(null);

  const [filteredJobList, setFilteredJobList] = React.useState([]);


  const doFilterJobList = () => {
    // filteredJobList.length = 0;

    var filteredJobs = jobsRawDatasource.filter((it) => {
      var isValid = true;

      var filterFunc = ((cat, value) => {

        if (cat != null) {
          if (cat.filter) {
            if (!cat.filter(value)) {
              return false;
            }
          } else {
            if (value !== cat.id) {
              return false;
            }
          }
        }

        return true;
      })

      if (!filterFunc(locationCategory, it.location)) {
        isValid = false;
      }
      if (!filterFunc(typeCategory, it.type)) {
        isValid = false;
      }
      if (!filterFunc(recipeCategory, it.category)) {
        isValid = false;
      }

      return isValid;
    })

    setFilteredJobList([...filteredJobs]);
  }



  useEffect(() => {
    doFilterJobList();
    return () => { }
  }, [])



  useEffect(() => {
    doFilterJobList();
    return () => { }
  }, [
    recipeCategory,
    typeCategory,
    locationCategory,
  ])



  return (

    <div style={{ display: `flex`, flexDirection: `column ` }}>

      <div className='globalContent_contactus bottomHeight'>



        <div>
          <div className="joinUs-filter-wrapper">
            <DropdownWrapper className="joinUs-filter-list">

              <RecipeDropDownList
                category={recipeCategory}
                setCategory={setRecipeCategory}
              />
              <br />
              <TypeDropDownList
                category={typeCategory}
                setCategory={setTypeCategory}
              />
              <br />
              <LocationDropDownList
                category={locationCategory}
                setCategory={setLocationCategory}
              />

            </DropdownWrapper>
          </div>



          <div className='joinUsbg'>

            <section className="k-listgroup">

              {
                filteredJobList.length <= 0 ? (
                  <div>
                    <center>No result</center>
                  </div>
                ) : null
              }

              <ul >
                {
                  (filteredJobList || []).map((it) => {
                    return (
                      <li >
                        <JoinUsTitle title={it.title} type={it.type} locationValue={it.location} respon={it.responsibilies} require={it.requirement} />
                      </li>
                    )
                  })
                }
              </ul>
            </section>

          </div>

        </div>


      </div>

    </div>
  )
}

export default withTheme(JoinUsContent)

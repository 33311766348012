import React from 'react'
import line from '../../assets/applicationdev_line.png'
import logo from '../../assets/ibroker_right_icon.png'
import logo2 from '../../assets/appdev_2.png'
import logo3 from '../../assets/appdev_3.png'
import start from '../../assets/start_quote.png'
import end from '../../assets/end_quote.png'
import descImg from '../../assets/application_img.png'
import logo1 from '../../assets/application_components.png'
import footer from '../../assets/application_dev_footer.png'
import FeaturesLoop from '../featuresLoop/FeaturesLoop'
import cloud1 from '../../assets/cloud1.jpg'
import top from '../../assets/top.png'

import ib1 from '../../assets/iBrokers_10.jpg'
import ib2 from '../../assets/iBrokers_11.jpg'

import ibenlarge1 from '../../assets/ibroker_enlarge1.jpg'
import ibenlarge2 from '../../assets/ibroker_enlarge2.jpg'

import pdf from '../../assets/ibrokers_pdf.png'

import idenacc1 from '../../assets/idenacc1.png'
import idenacc2 from '../../assets/idenacc2.png'
import idenacc3 from '../../assets/idenacc3.png'
import idenacc4 from '../../assets/idenacc4.png'
import idacc11bg from '../../assets/idacc1bg.png'
import idenacc11 from '../../assets/idenacc11.png'
import idenback22 from '../../assets/idenback22.png'

import iden1 from '../../assets/iden1.png'
import iden6icon from '../../assets/iden6icon.png'
import idenback from '../../assets/idenback.png'
import cs from '../../assets/cs.png'
import serviceAd from '../../assets/service_ad.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'
import serviceIbroker from '../../assets/service_ibroker.png'
import microLogo from '../../assets/microLogo.png'
import secudeLogo from '../../assets/secudeLogo.png'
import bl from '../../assets/bottomline.png'

import { device, InnerWrapper, Text } from '../../common.styles'
import DecoHeading1 from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import SolutionHybridIcon from '../../assets/SolutionHybridIcon'
import TopButton from '../TopButton/TopButton'
import styled from 'styled-components'

const ContactUsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    @media only screen and ${device.mobileL} {
        height: 100px;
    }

    a {
        width: 380.9px;
        
        @media only screen and ${device.mobileL} {
            width: 169.34px;
        }
    }
`;


const PresistStorage = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };
    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading1 desc="Persistent Storage" icon={<SolutionHybridIcon />} />

                <Text style={{ maxWidth: '800px', margin: '16px auto 0', textAlign: 'center' }}>
                    Persistent Storage is any storage device that retains data after there is no power supply to that devices.
                </Text>

                <ContactUsContainer>
                    <a href='/pages/ContactUs#Message'><img src={cs} style={{ width: `100%` }} /></a>
                </ContactUsContainer>
            </InnerWrapper>

            <SectionBlock>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>

                </div>
            </SectionBlock>
            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default PresistStorage
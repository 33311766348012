import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 60.5px;
  height: 67px;

  @media only screen and ${device.mobileL} {
    width: 20.17px;
    height: 22.33px;
  }

  svg {
    fill: #4f7ac7;
  }
`;

const ProductiTrading6Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 60.482 66.962">
      <g>
        <path d="M5898.371 1362.487h-2.517c.017-.36.053-.715.053-1.08 0-11.93-9.429-21.6-21.061-21.6s-21.06 9.671-21.06 21.6c0 .365.035.72.053 1.08h-1.439a7.256 7.256 0 0 0-7.256 7.256v29.769a7.256 7.256 0 0 0 7.256 7.256h45.97a7.256 7.256 0 0 0 7.256-7.256v-29.769a7.256 7.256 0 0 0-7.255-7.256zm-23.525-16.034a14.771 14.771 0 0 1 14.581 14.954c0 .365-.029.722-.054 1.08h-29.054c-.025-.358-.053-.715-.053-1.08a14.771 14.771 0 0 1 14.58-14.954zm-3.456 51.822-.011-.011-.011.011-14.617-14.618 6.1-6.1 8.523 8.524 16.274-16.274 6.1 6.1z" transform="translate(-5845.145 -1339.806)" />
      </g>
    </svg>

  </IconWrapper>
);

export default ProductiTrading6Icon;




import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin: 0 4px;
`;

const ServiceOCCSIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 18.45 11.03">
      <path d="M15.36,345.73H3.07a2.94,2.94,0,1,1-.29-5.88,3.48,3.48,0,0,1,3.53-3.25,3.62,3.62,0,0,1,1.45.3,4.91,4.91,0,0,1,4.13-2.2,4.77,4.77,0,0,1,4.87,4.66,3.84,3.84,0,0,1-.07.76,3,3,0,0,1,1.73,2.66A3,3,0,0,1,15.36,345.73ZM3.08,340.44a2.34,2.34,0,1,0-.25,4.68H15.36a2.41,2.41,0,0,0,2.46-2.34,2.35,2.35,0,0,0-1.58-2.18l-.25-.1.06-.26a3.63,3.63,0,0,0,.1-.87,4.16,4.16,0,0,0-4.26-4.06,4.29,4.29,0,0,0-3.75,2.13l-.15.25-.26-.13a3,3,0,0,0-1.42-.35A2.86,2.86,0,0,0,3.38,340v.44Zm7.59,3.37a1.27,1.27,0,0,1-1.32.31,1.14,1.14,0,0,1-.71-.8A1.4,1.4,0,0,1,9,341.94a1.16,1.16,0,0,1,1-.26,1.11,1.11,0,0,1,.95.87A1.45,1.45,0,0,1,10.67,343.81ZM10.08,342a.82.82,0,0,0-.86.16A1.25,1.25,0,0,0,9,343.25a.89.89,0,0,0,.63.67.79.79,0,0,0,.75-.18,1.18,1.18,0,0,0,.27-1.12A.9.9,0,0,0,10.08,342Zm2.93.91a.72.72,0,0,1-.58.23h-.86v1.06h-.32V342a.36.36,0,0,1,.09-.23.33.33,0,0,1,.23-.1h.92a.67.67,0,0,1,.51.22.81.81,0,0,1,.17.49.73.73,0,0,1-.16.48Zm-.25-.8a.5.5,0,0,0-.38-.15h-.73a.09.09,0,0,0-.08.08v.86h.81a.55.55,0,0,0,.35-.12.46.46,0,0,0,.11-.3.69.69,0,0,0-.08-.37Zm2.75,2.08v-1.74l-.75,1.74h-.27l-.76-1.73v1.73h-.3v-2.47h.3l.89,2,.88-2h.31v2.47Z" transform="translate(0.02 -334.7)" />
    </svg>
  </IconWrapper>
);

export default ServiceOCCSIcon;




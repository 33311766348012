import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled, { css } from 'styled-components'
import solutionBg from '../../assets/solution_bg.png'
import { device, Heading, SectionSubheading } from '../../common.styles'
import FullWidthSection from '../../FullWidthSection'
import '../featuresLoop/featureLoop.css'
import CircularBox from './CircularBox'

const icons = [
  { alignTop: true, alignLeft: true, text: "System Design", iconUrl: require("../../assets/service1.png") },
  { alignTop: true, alignLeft: true, text: "Project Management", iconUrl: require("../../assets/service2.png") },
  { alignTop: true, alignLeft: true, text: "Product Development", customAlign: 'top', iconUrl: require("../../assets/service3.png") },
  { alignTop: true, alignLeft: false, text: "System Integration (SI)", iconUrl: require("../../assets/service4.png") },
  { alignTop: true, alignLeft: false, text: "Artificial Intelligence (AI)", iconUrl: require("../../assets/service5.png") },
  { alignTop: true, alignLeft: false, text: "Blockchain", iconUrl: require("../../assets/service6.png") },
  { alignTop: true, alignLeft: false, text: "Digital Marketing", iconUrl: require("../../assets/service7.png") },
  { alignTop: false, alignLeft: false, text: "Quality Assurance", customAlign: 'bottom', iconUrl: require("../../assets/service8.png") },
  { alignTop: true, alignLeft: true, text: "IT Infrastruture Services", iconUrl: require("../../assets/service9.png") },
  { alignTop: true, alignLeft: true, text: "Software Development", iconUrl: require("../../assets/service10.png") },
];

const OpmFeature2 = styled(FullWidthSection)`
  height: 800px;
  background-image: url(${solutionBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media only screen and (max-width: 1320px) {
    height: auto;
  }
`;

const CircularWrapper = styled.div`
  position: relative;
  max-width: 700px;
  height: 100%;
  margin: auto;

  @media only screen and ${device.mobileL} {
    max-width: none;
  }

`;

const CircularBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AB;
  font-size: 48px;
  color: white;

  @media only screen and (max-width: 1320px) {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    display: block;
    padding: 32px 0 16px;
  }
  `;

const StyledSectionSubheading = styled(SectionSubheading)`
  position: absolute;
  font-family: AB;
  font-weight: bold;
  color: white;
  text-align: center;
  white-space: nowrap;
  ${props => props.alignLeft && css`
    right: 110%;
  `}
  ${props => !props.alignLeft && css`
    left: 110%;
  `}
  @media only screen and (max-width: 1320px) {
    position: static;
    display: inline;
    white-space: normal;
  }
`;

const FeatureBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  ${props => props.alignTop && css`
    align-items: top;
  `}
  ${props => !props.alignTop && css`
    align-items: end;
  `}

  ${props => props.customAlign && css`
    display: block;    
  `}

  ${StyledSectionSubheading} {
    ${props => props.customAlign && props.customAlign === 'top' && css`
      bottom: 100%;
      transform: translate(80%, -50%);   
    `}
    ${props => props.customAlign && props.customAlign === 'bottom' && css`
      top: 125%;
      transform: translate(-85%, -50%);
    `}
  }

  @media only screen and (max-width: 1320px) {
    display: inline;
  }

  img {
    width: 100%;

    @media only screen and (max-width: 1320px) {
      width: 80%;
    }
  }
`;

const Features = () => {
  return (
    <OpmFeature2>
      <CircularWrapper>
        <CircularBg>
          <Heading>Options</Heading>
        </CircularBg>
        <CircularBox size={90} itemsArr={icons.map(({ alignTop, alignLeft, customAlign, text, iconUrl }, index) => (
          <div>
            <FeatureBlock key={index} alignTop={alignTop} customAlign={customAlign}>
              <img src={iconUrl} alt="" />
              <br />
              <StyledSectionSubheading alignLeft={alignLeft}>{text}</StyledSectionSubheading>
            </FeatureBlock>
          </div>
        ))} />
      </CircularWrapper>
    </OpmFeature2>

  )
}


export default Features
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductLDAPProtectIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 17.01 19.29">
      <path d="M51.4,367.42c-.13-.64-.29-1.27-.47-1.9a.68.68,0,0,0-.53-.49,2.27,2.27,0,0,1-1.4-1.17.73.73,0,0,0-.36-.31,1,1,0,0,0-.24,0h-10a1,1,0,0,0-1,.42A2.15,2.15,0,0,1,36.1,365a.68.68,0,0,0-.53.49c-.92,3.19-1.53,7.82.77,11.8a12.59,12.59,0,0,0,5.31,4.86l.19.1a4.72,4.72,0,0,0,1.24.49l.17,0a2.3,2.3,0,0,0,.93-.29l.18-.09a12.66,12.66,0,0,0,5.2-4.16c2-2.81,2.67-6.65,1.84-10.83Zm-14.6-1.14a3.53,3.53,0,0,0,1.68-1.37H48a3.58,3.58,0,0,0,1.68,1.37c.78,2.88,1.24,7-.77,10.38a11.11,11.11,0,0,1-4.7,4.28l-.47.22-.29.12-.09,0-.15.05-.18-.07,0,0a.83.83,0,0,1-.21-.09l-.07,0a11.4,11.4,0,0,1-4.61-3.67c-2.35-3.25-2.18-7.14-1.63-9.83.08-.44.19-.88.32-1.37Z" transform="translate(-34.74 -363.5)" />
      <path d="M40.34,376h5.8a1,1,0,0,0,.94-1.12h0v-3.69c0-.58-.36-.8-.94-.8h-.41a2.51,2.51,0,1,0-5-.51,2.18,2.18,0,0,0,0,.51h-.4c-.58,0-.78.22-.78.8v3.69C39.56,375.43,39.75,376,40.34,376Zm3.55-1.13H42.58l.25-1.84a.89.89,0,0,1-.44-.77.87.87,0,0,1,.85-.87.84.84,0,0,1,.85.84h0a1,1,0,0,1-.45.82Zm-.66-6.46c.86,0,1.72.36,1.72,2H41.52C41.52,368.75,42.38,368.39,43.23,368.39Z" transform="translate(-34.74 -363.5)" />
    </svg>
  </IconWrapper>
);

export default ProductLDAPProtectIcon;




import React from 'react'
import { Helmet } from "react-helmet";
import { Navbar } from '../components';
import { Link } from 'react-scroll'
import { Footer, Header } from '../containers';
import OPMLogo from '../assets/OPMLogo';
import navBg from '../assets/navBg.png';
import styled from 'styled-components';
import { device, Heading } from '../common.styles';
import TextVersionHeader from '../containers/TextversionHeader/TestversionHeader';
import TextVersionContent from '../containers/TextVersionContent/TextVersionContent';


const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 500px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AL;
  font-size: 48px;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const Slogan2 = styled.div`
  font-family: AB;
  font-size: 48px;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const TextVersionWrapper = styled.div`
  // max-width: 800px;
  margin: auto;
  padding: 16px;
  box-sizing: border-box;
`;

const TextVersionHeading = styled(Heading)`
  font-family: HB;
  font-weight: bold;
  // margin: 30px 0;
`;

const TextVersionDesc = styled(Text)`
  font-family: HR;
  line-height: 1.2;
`;

const TextVersion = () => {


  const productHash = {
    '#iBrokers': null,
    '#iTrading': null,
    '#PRIME': null,
    '#LDAP': null,
    '#CMSmart': null,
    '#iStorage': null,
    '#DIGITTRADE': null,
    '#nFront-Password-Filiter': null,
    '#nFront-Web-Password-Change': null,
    '#nFront-Account-Disabler': null,
  }

  return (
    <>
      <div className='App'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Text Version</title>
          <meta name="Text Version" content="OPM - Atext Version" />
        </Helmet>


        <TextVersionHeader />

        {/* <TextVersionContent/> */}

        {/* <TextVersionWrapper>

            <TextVersionHeading>Home</TextVersionHeading>
            <TextVersionHeading>Why OPM</TextVersionHeading>
            <ul>
              <li><TextVersionDesc>OPM</TextVersionDesc></li>
              <li><TextVersionDesc>Experience</TextVersionDesc></li>
              <li><TextVersionDesc>Approach</TextVersionDesc></li>
              <li><TextVersionDesc>Method</TextVersionDesc></li>
              <li><TextVersionDesc>Awards</TextVersionDesc></li>
              <li><TextVersionDesc>Contact Us</TextVersionDesc></li>

            </ul>
            <TextVersionHeading>Services</TextVersionHeading>
            <ul>
              <li><TextVersionDesc>Applications Development</TextVersionDesc></li>
              <li><TextVersionDesc>Consulting Services</TextVersionDesc></li>
              <li><TextVersionDesc>Technical Support</TextVersionDesc></li>
              <li><TextVersionDesc>OPM Cloud Computing Services</TextVersionDesc></li>
              <li><TextVersionDesc>Awards</TextVersionDesc></li>
              <li><TextVersionDesc>Help Desk Services</TextVersionDesc></li>

            </ul>
            <TextVersionHeading>Product</TextVersionHeading>
            <ul>
              <li><TextVersionDesc>iBrokers</TextVersionDesc></li>
              <li><TextVersionDesc>iTrading</TextVersionDesc></li>
              <li><TextVersionDesc>Prime</TextVersionDesc></li>
              <li><TextVersionDesc>LDAP PROTECT</TextVersionDesc></li>
              <li><TextVersionDesc>CMSmart</TextVersionDesc></li>
              <li><TextVersionDesc>iStorage</TextVersionDesc></li>
              <li><TextVersionDesc>DIGITTRADE</TextVersionDesc></li>
              <li><TextVersionDesc>nFront Password Filter</TextVersionDesc></li>
              <li><TextVersionDesc>nFront Password Change</TextVersionDesc></li>
              <li><TextVersionDesc>nFront Account Disable</TextVersionDesc></li>
            </ul>
            <TextVersionHeading>Solution</TextVersionHeading>
            <ul>
              <li><TextVersionDesc>Security</TextVersionDesc>
                <ul>
                  <li><TextVersionDesc>Identity & Access Management</TextVersionDesc></li>
                  <li><TextVersionDesc>End Point Security Solution</TextVersionDesc></li>
                  <li><TextVersionDesc>Single Sign-on (SSO)</TextVersionDesc></li>
                  <li><TextVersionDesc>Multi-factor Authentication</TextVersionDesc></li>
                </ul>
              </li>
              <li><TextVersionDesc>End User</TextVersionDesc>
                <ul>
                  <li><TextVersionDesc>End User Portal Solution</TextVersionDesc></li>
                  <li><TextVersionDesc>Mobile Enterprise Solution</TextVersionDesc></li>
                  <li><TextVersionDesc>Virtual Desktop Infrastructure (VDI)</TextVersionDesc></li>
                  <li><TextVersionDesc>Health Care Solution</TextVersionDesc></li>
                </ul>
              </li>

              <li><TextVersionDesc>By Industry</TextVersionDesc>
                <ul>
                  <li><TextVersionDesc>Health Care Solution</TextVersionDesc></li>
                  <li><TextVersionDesc>Case Management System</TextVersionDesc></li>
                  <li><TextVersionDesc>Call Log System</TextVersionDesc></li>
                  <li><TextVersionDesc>Booking System</TextVersionDesc></li>
                  <li><TextVersionDesc>Interactive Live Streaming System</TextVersionDesc></li>
                </ul>
              </li>

              <li><TextVersionDesc>Blockchain</TextVersionDesc>
                <ul>
                  <li><TextVersionDesc>Authentication</TextVersionDesc></li>
                  <li><TextVersionDesc>Data Protection</TextVersionDesc></li>
                  <li><TextVersionDesc>Transaction Processing</TextVersionDesc></li>

                </ul>
              </li>

              <li><TextVersionDesc>Artificial Intelligence (AI)</TextVersionDesc>
                <ul>
                  <li><TextVersionDesc>Natural Language Processing (NLP)</TextVersionDesc></li>
                  <li><TextVersionDesc>Chatbot</TextVersionDesc></li>
                  <li><TextVersionDesc>Optical Character Recognition (OCR)</TextVersionDesc></li>
                  <li><TextVersionDesc>Image Recognition</TextVersionDesc></li>

                </ul>
              </li>

              <li><TextVersionDesc>Hybrid Cloud</TextVersionDesc>
                <ul>
                  <li><TextVersionDesc>Kubernetes (K8s)</TextVersionDesc></li>
                  <li><TextVersionDesc>Persistent Storage</TextVersionDesc></li>
                  <li><TextVersionDesc>Private Registry</TextVersionDesc></li>
                  <li><TextVersionDesc>Orchestrator</TextVersionDesc></li>

                </ul>
              </li>
            </ul>

            <TextVersionHeading>Contact Us</TextVersionHeading>

            <TextVersionHeading>Join Us</TextVersionHeading>

          </TextVersionWrapper>
 */}










      </div>

    </>
  )
}

export default TextVersion
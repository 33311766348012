import { createContext } from 'react';

export const theme = {
  fontSize: {
    heading: 37.5,
    sectionHeading: 67.5,
    sectionDesc: 52.5,
    sectionSubheading: 18.75,
    body: 16.5,
    mobileHeading: 18,
    mobileSectionHeading: 28,
    mobileSectionDesc: 20,
    mobileSectionSubheading: 10,
    mobileBody: 14
  },
  maxWidth: 1200
};

export const ThemeContext = createContext({
  theme,
  setSelectedTheme: () => { },
});
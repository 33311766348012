import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 79.3px;
  height: 69.6px;

  @media only screen and ${device.mobileL} {
    width: 26.43px;
    height: 23.2px;
  }

  svg {
    fill: #4f7ac7;
  }
`;

const ProductiTrading4Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 79.276 69.576">
      <g>
        <g>
          <path d="M5029.123 1360.005a14.237 14.237 0 1 0 14.238 14.237 14.238 14.238 0 0 0-14.238-14.237zm0 24.488a10.251 10.251 0 1 1 10.251-10.251 10.25 10.25 0 0 1-10.251 10.251z" transform="translate(-5014.885 -1339.806)" />
          <path d="M5078.931 1384.493a10.251 10.251 0 1 1 10.251-10.251 10.251 10.251 0 0 1-10.251 10.251z" transform="translate(-5014.885 -1339.806)" />
          <path d="M5078.93 1389.48a15.139 15.139 0 0 1-10.774-4.463 15.313 15.313 0 0 1-3.1-17.031 1 1 0 1 1 1.823.821 13.3 13.3 0 0 0 2.7 14.8 13.238 13.238 0 0 0 18.721-18.721 13.3 13.3 0 0 0-14.8-2.7 1 1 0 0 1-.821-1.824 15.23 15.23 0 0 1 17.031 24.655 15.137 15.137 0 0 1-10.78 4.463z" transform="translate(-5014.885 -1339.806)" />
        </g>
        <g>
          <path d="M5033.641 1354.583a29.36 29.36 0 0 1 24.648-12.783 29.908 29.908 0 0 1 21.168 8.53 31.239 31.239 0 0 0-48.371 1.968z" transform="translate(-5014.885 -1339.806)" />
          <path d="m5032.9 1352.763 3.309 2.24-3.2 1.168-3.2 1.168-.106-3.408-.105-3.408z" transform="translate(-5014.885 -1339.806)" />
        </g>
        <g>
          <path d="M5075.4 1394.6a29.358 29.358 0 0 1-24.648 12.783 29.9 29.9 0 0 1-21.167-8.53 31.238 31.238 0 0 0 48.37-1.968z" transform="translate(-5014.885 -1339.806)" />
          <path d="m5076.147 1396.425-3.309-2.24 3.2-1.168 3.2-1.168.106 3.408.105 3.408z" transform="translate(-5014.885 -1339.806)" />
        </g>
      </g>
    </svg>
  </IconWrapper>
);

export default ProductiTrading4Icon;




import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductiBrokersIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 17.02 17.96">
      <path d="M51.64,141.16a.25.25,0,0,0-.17-.19.26.26,0,0,0-.25.05,3.56,3.56,0,0,1-2.23.5,10.24,10.24,0,0,1-1.36-.1,7.43,7.43,0,0,1-4.2-1.66.25.25,0,0,0-.36,0h0a7.54,7.54,0,0,1-4.2,1.67,10.39,10.39,0,0,1-1.37.09,3.53,3.53,0,0,1-2.22-.5A.25.25,0,0,0,35,141a.25.25,0,0,0-.16.2,16.68,16.68,0,0,0,8.26,16.45.23.23,0,0,0,.25,0,16.69,16.69,0,0,0,8.27-16.45ZM43.25,156a14.47,14.47,0,0,1-6.6-9.05,16,16,0,0,1-.46-4.07,10.22,10.22,0,0,0,2.87,0,10.1,10.1,0,0,0,4.19-1.43,11.23,11.23,0,0,0,7.06,1.44A15,15,0,0,1,43.25,156Z" transform="translate(-34.74 -139.68)" />
      <path d="M44.4,144.82H42.14v2.27H39.86v2.26h2.28v2.27H44.4v-2.27h2.27v-2.26H44.4Z" transform="translate(-34.74 -139.68)" />
    </svg>
  </IconWrapper>
);

export default ProductiBrokersIcon;




import styled, { withTheme } from 'styled-components';
import { device, Heading, Text } from './common.styles';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        display: inline;
    }
`;

const Left = styled.div`
    width: 50%;
    padding: 8px;
    box-sizing: border-box;

    img {
        width: 100%;
    }

    @media only screen and ${device.mobileL} {
        width: 100%;
    }
`;

const Right = styled.div`
    width: 50%;
    padding: 8px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        width: 100%;
    }
`;

const IconDescBlock = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;

    img {
        width: 60px;

        @media only screen and ${device.mobileL} {
            width: 45px;
        }
    }
`;

const StyledHeading = styled(Heading)`
    font-weight: bold;
    font-family: AB;
    padding: 8px;
    box-sizing: border-box;
    
    @media only screen and ${device.mobileL} {
        text-align: center;
    }
`;

const StyledText = styled(Text)`
    padding: 8px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        text-align: center;
    }
`;

const StyledIconText = styled(StyledText)`
    padding: 8px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        text-align: left;
    }
`;

const IconDesc = ({ logo, text }) => (
    <IconDescBlock>
        <img src={logo} alt="" />
        <StyledIconText>{text}</StyledIconText>
    </IconDescBlock>
);

const ImagePointsBlock = ({ image, title, desc, itemsArr = [] }) => (
    <Wrapper>
        <Left>
            <img src={image} alt="" />
        </Left>
        <Right>
            <StyledHeading>{title}</StyledHeading>
            <StyledText>{desc}</StyledText>
            {itemsArr.map(({ logo, text }, index) => <IconDesc logo={logo} text={text} />)}
        </Right>
    </Wrapper>
);

export default withTheme(ImagePointsBlock);
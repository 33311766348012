import React from 'react'
import { Helmet } from 'react-helmet'

// import { Footer, Partner, FeatureList, WhatGPT3, Header,Features ,Solution} from '../containers';
import { CTA, Brand, Navbar } from '../components';

// import './App.css'
import { useLocation, useHistory } from 'react-router-dom';

// import '../components/technicalSupport/technicalSupport.css'
// import Navbar from './components/navbar/Navbar';

import '../components/consultingServices/consultingService.css'
import OpmContent from '../containers/OpmContent/OpmContent';


import MiniNavbar from '../components/miniNavBar/MiniNavbar';
import "../assets/css/Common.css"
import opmBg from '../assets/opm_bg.png';
import { Footer, Header } from '../containers';
import OPMLogo from '../assets/OPMLogo';
import { useNavigate } from "react-router-dom"
import styled from 'styled-components'
import { device } from '../common.styles';

// import ''


const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AL;
  font-size: 88px;
  color: white;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const OPM = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Why OPM -  Welcome to OPM System Company Limited</title>
        <meta name="Why OPM" content="OPM - Why OPM" />
      </Helmet>
      <Header bgImg={opmBg} tone="dark">
        <StyledOPMLogo />
        <Slogan1>
          Why OPM
        </Slogan1>
      </Header>
      <OpmContent />
      <Footer bgcolor='#7c98b4' />
    </>
  )
}

export default OPM
import React from 'react'





import enduser1 from '../../assets/enduser1.png'
import enduserlogo from '../../assets/enduserlogo.png'
import microLogo from '../../assets/microLogo.png'
import secudeLogo from '../../assets/secudeLogo.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'

import styled from 'styled-components'
import bl from '../../assets/bottomline.png'
import SolutionEndUserIcon from '../../assets/SolutionEndUserIcon'
import { device, InnerWrapper, Text } from '../../common.styles'
import DecoHeading from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import TopButton from '../TopButton/TopButton'
import './enduserProtal.css'

const OrganizationButton = styled(Text)`
    margin: auto;
    margin-top: 100px;
    background-color: rgba(0, 136, 236, 0.5);
    width: 80%;
    border-radius: 45px;
    padding: 20px;
    color: white;
    text-align: center;
`;

const Organization = styled.div`
    display: flex;
    padding: 16px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        display: inline;
    }
`;

const OrganizationItem = styled(Text)`
    width: 25%;
    padding: 16px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        width: 100%;
    }
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 25px;

    @media only screen and ${device.mobileL} {
        flex-wrap: wrap;
    }

    img {
        width: 150px;
        height: 150px;

        @media only screen and ${device.mobileL} {
            width: 45px;
            height: 45px;
        }
    }
`;

const StyledText = styled(Text)`
    text-align: left;
    color: #343434;

    @media only screen and ${device.mobileL} {
        text-align: center;
    }
`;

const EndUserProtal = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading heading="End User" desc="End User Portal Solution" icon={<SolutionEndUserIcon />} />

                <div style={{ maxWidth: '100%', textAlign: 'center' }}>
                    <img src={enduser1} style={{ alignSelf: `center`, width: `80%`, marginTop: `30px`, marginBottom: '30px' }} />



                    <DescContainer>

                        <img src={enduserlogo} alt="" />

                        <StyledText>
                            Your younger workers are used to using a lot of social collaboration tools in their personal lives.
                            This is a great boom for businesses.<br />
                            In the right hands, these kinds of tools can dramatically boost innovation and productivity.
                            Our real-time and collaboration tools enable these new ways of working together,
                            with a strong focus on interoperability and security.
                        </StyledText>

                    </DescContainer>
                </div>



                <OrganizationButton>It is relatively clear what a portal can be but why should an organization consider one?</OrganizationButton>



                <Organization>


                    <OrganizationItem>Portals, serve as a core site that mediate in various ways between users, and the vast range of information that is out there in hyperspace.</OrganizationItem>

                    <OrganizationItem>Provide a platform allows people to interact with others, applications, processes, documents and other content in a personalized and role-based fashion.</OrganizationItem>

                    <OrganizationItem>Provides needed functionality, but requires you to input the way that it needs to work in your organization.</OrganizationItem>

                    <OrganizationItem>Portal allows customizing of your work environment to your own needs, present resources in a consistent
                        fashion, and can provide a personalized experience based upon your previous preferences or specific role within an organization.</OrganizationItem>

                </Organization>


            </InnerWrapper>


            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'><img src={microLogo} alt="" height="50" style={{ marginRight: '20px' }} /></a>
                    <a target='_blank' href='https://secude.com/'><img src={secudeLogo} alt="" height="50" /></a>
                </div>
                <div><img src={bl} alt="" style={{ width: `80%`, marginTop: `20px`, marginBottom: `20px`, objectFit: `contain` }} /></div>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>
                </div>
            </SectionBlock>
            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default EndUserProtal
import React from 'react'





import endpic11 from '../../assets/endpic_11.png'

import './endpoint.css'

import styled from 'styled-components'
import bl from '../../assets/bottomline.png'
import microLogo from '../../assets/microLogo.png'
import secudeLogo from '../../assets/secudeLogo.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'
import SolutionSecurityIcon from '../../assets/SolutionSecurityIcon'
import { device, Heading, Text } from '../../common.styles'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import TopButton from '../TopButton/TopButton'


const StyledGradInnerWrapper = styled(SectionBlock)`
    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #eceded 100%);
    background: -webkit-linear-gradient(top,  #ffffff 0%,#eceded 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#eceded 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eceded',GradientType=0 );
`
const StyledHeading = styled(Heading)`
    color: #333;
`;

const StyledText = styled(Text)`
    color: #333;
    width: 100%;
    max-width: 600px;
    margin: auto;
`;

const EndPointGridWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 16px;
    box-sizing: ;
`;

const EndPointGrid = styled.div`
    position: relative;
    border: 1px solid #4f7ac7;
    width: calc(33.333% - 32px);
    display: inline-flex;
    height: 150px;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    margin: 8px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        width: 100% !important;
        /* display: flex; */
        vertical-align: middle;
    }

    div {
        padding: 0 8px;
        box-sizing: border-box;

        &::before {
            position: absolute;
            top: -8px;
            right: -12px;
            width: 30px;
            height: 20px;
            background-color: #f4f4f4;
            display: block;
            content: ' ';
            transform: rotate(45deg);
        }

        &::after {
            position: absolute;
            top: -4px;
            right: 16px;
            width: 8px;
            height: 8px;
            background-color: #4f7ac7;
            border-radius: 50px;
            display: block;
            content: ' ';
            transform: rotate(-45deg);
        }
    }

    &.second {
        width: 66.666%;
    }

    &::before {
        position: absolute;
        top: -8px;
        left: -12px;
        width: 30px;
        height: 20px;
        border-bottom: 1px solid #4f7ac7;
        background-color: #f4f4f4;
        display: block;
        content: ' ';
        transform: rotate(-45deg);
    }

    &::after {
        position: absolute;
        bottom: -8px;
        right: -12px;
        width: 30px;
        height: 20px;
        border-top: 1px solid #4f7ac7;
        background-color: #eff0f0;
        display: block;
        content: ' ';
        transform: rotate(-45deg);
    }
`;

const EndPointSolution = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <DecoHeading desc="Endpoint Security Solution" icon={<SolutionSecurityIcon />} />
            <DecoDesc1>
                OPM Endpoint Management solutions protect your organization's confidential information and help you achieve regulatory compliance across physical and virtual environments. With powerful endpoint protection capabilities, users can use endpoints like desktops, notebooks, handhelds, or USB drives without the risk of data loss. IT security is assured without hampering productivity.
            </DecoDesc1>
            <div style={{ padding: '16px', boxSizing: 'border-box', textAlign: 'center' }}>
                <img src={endpic11} style={{ maxWidth: '1000px', width: '100%' }} />
            </div>
            <StyledGradInnerWrapper>
                <StyledHeading>
                    Features
                </StyledHeading>
                <StyledText>OPM data and information security solutions deliver comprehensive capabilities for data protection and access
                    management that can be integrated to help address information security across the enterprise with:</StyledText>

                <StyledText>
                    <br />
                    <EndPointGridWrapper>
                        <EndPointGrid>
                            <Text>
                                Data encryption (Folder level and Full Disk with multi factor authentication)
                            </Text>
                        </EndPointGrid>
                        <EndPointGrid className="second">
                            <Text>Endpoint control (Including personal firewall, wireless connection management, application control, device control, patch delivery, and network access control)</Text>
                        </EndPointGrid>
                        <EndPointGrid>
                            <Text>Access protection policy management</Text>
                        </EndPointGrid>
                        <EndPointGrid>
                            <Text>Information assess, protect, and manage services</Text>
                        </EndPointGrid>
                        <EndPointGrid>
                            <Text>Endpoint device patching and virus signature updates</Text>
                        </EndPointGrid>
                    </EndPointGridWrapper>
                </StyledText>
            </StyledGradInnerWrapper>

            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'><img src={microLogo} alt="" height="50" style={{ marginRight: '20px' }} /></a>
                    <a target='_blank' href='https://secude.com/'><img src={secudeLogo} alt="" height="50" /></a>
                </div>
                <div><img src={bl} alt="" style={{ width: `80%`, marginTop: `20px`, marginBottom: `20px`, objectFit: `contain` }} /></div>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>
                </div>
            </SectionBlock>
            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default EndPointSolution
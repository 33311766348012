import React from 'react'






import lineHon from '../../assets/nFont_hon_line.png'

import pf1 from '../../assets/1pf.png'
import pf3 from '../../assets/3pf.png'
import pf5 from '../../assets/5pf.png'
import pf6 from '../../assets/pf6.png'

import styled from 'styled-components'
import { InnerWrapper, Text } from '../../common.styles'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import TopButton from '../TopButton/TopButton'
import './nfontpwd.css'


const StyledInnerWrapper = styled(InnerWrapper)`
    max-width: 1200px;
    margin: auto;
    padding: 16px;
`;

const BlueBox = styled.div`
    background-color: #4f7ac7;
    text-align: center;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;

    div {
        width: 33.333%;
        color: white;
        display: inline-block;
    }
`;

const GreenBox = styled(BlueBox)`
    background-color: #00b0bd;
`;

const StyledText = styled(Text)`
    color: #343434;
`;

const StyledContentTitle = styled(StyledText)`
    font-family: AB;
    font-weight: bold;
    color: #00b0bd;
    margin-top: 30px;
`;

const NFontPwd = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <DecoHeading heading="nFront Password Filter" />
            <DecoDesc1>
                nFront Password Filter is a password policy tool that prevents the use of weak, easily hacked passwords for Windows Active Directory. nFront Password Filter allows network administrators to create and enforce multiple granular password policies within a single Windows domain. nFront Password Filter can reject non-compliant passwords before they are allowed on the network. nFront Password Filter can scan a new password against over 2 million common passwords in less than 1 second. Also included is a client that lists the user’s specific password policy rules. All configuration is done via Group Policies (GPO) and the design contains no single point of failure and no “password policy server.” nFront Password Filter is used by many companies to meet SOX, PCI and HIPAA password compliance requirements.
            </DecoDesc1>
            <StyledInnerWrapper>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>ARE YOU ENFORCING A GOOD PASSWORD POLICY?</StyledContentTitle>

                    <StyledText>
                        Passwords. Everyone on your corporate network has one. How weak is the weakest password?
                        Having a good password policy that is enforced across all users is fundamental to good security practices. You are probably spending money on firewalls, anti-virus, encryption and data leakage products. However, if you are using the built-in
                        Windows Password Policy you might as well burn the money you are spending for all the security software and devices.
                    </StyledText>


                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>WHAT IS NFRONT PASSWORD FILTER?</StyledContentTitle>

                    <StyledText>
                        nFront Password Filter is a password policy enforcement tool for Windows Active Directory that allows up to 10 different password policies in the same Windows domain. Each password policy has many granular settings and can be associated with one or more global or universal security groups. nFront Password Filter allows you to strengthen network security by preventing the use of weak, easily hacked passwords.
                    </StyledText>


                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>CAN YOU BENEFIT FROM NFRONT PASSWORD FILTER?</StyledContentTitle>

                    <StyledText>
                        If a security auditor ran a password cracker on your network how many passwords would they crack? How can you be sure your employees are following your suggested password guidelines?
                        How can you be sure your administrators are not creating accounts with blank or simple passwords?<br></br>

                        Are you using an identity management tool that has users change their password via a website but also allows them to bypass the website using CTRL-ALT-DEL and set weak passwords?

                    </StyledText>


                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>WHY USE NFRONT PASSWORD FILTER?</StyledContentTitle>

                    <StyledText>
                        <b style={{ fontWeight: `bold` }}>Users do not understand the need for strong passwords and will not follow formal password policies unless the policies are enforced.</b><br></br>
                        Protects from external hacking. IPSec is great. VPNs are great. 128-bit encryption is great. However, the correct username and password allows a hacker to use the 128-bit IPSec VPN tunnel and access your corporate data. All of your firewall devices and IDS devices have no way of distinguishing the compromised account from the actual legitimate user.<br></br>
                        Protects from internal hacking. How about the new engineer who would like to access to company financial data? How about the college student who would like the modify the student records database? How about all the free password sniffing and cracking tools on the Internet?<br></br><br></br>


                    </StyledText>
                    <StyledText>Disallowing weak passwords is part of the <a target='_blank' href='https://www.sans.org/blog/cis-controls-v8/' style={{ color: `#00b0bd` }}>SANS/FBI Top 20 List </a></StyledText>


                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>HOW PASSWORDS ARE COMPROMISED:</StyledContentTitle>

                    <div style={{ display: `flex`, flexDirection: `row`, width: `100%` }}>

                        <div style={{ display: `flex`, flexDirection: `column`, width: `100%`, marginRight: `37.5px` }}>

                            <StyledText>Passwords can be compromised in a number of ways. There are software tools to “guess” passwords. Essentially there are 4 categories of tools:</StyledText>

                            <br />
                            <StyledText>
                                <ul style={{ marginLeft: '8px', listStyleType: `decimal` }}>
                                    <li>Brute Force - go through every possible character combination incrementally starting with “a”,”b” and moving to “aa”,”ab”, etc.</li>
                                    <li>Dictionary password crackers - try dictionary words and common sequences/patterns</li>
                                    <li>Hybrid - combine multiple dictionary words and patterns</li>
                                    <li>Rainbow Tables - use precalculated hashes for all passwords of 14 characters or less to find a match. Given a hash rainbow tables can be used to crack any password of 14 characters or less in about 2 minutes.</li>
                                </ul>
                            </StyledText>
                            <br />
                            <StyledText><a target='_blank' href='http://resources.infosecinstitute.com/10-popular-password-cracking-tools' style={{ color: `#00b0bd` }}>Here</a> is are nice list of different password cracking tools</StyledText>
                        </div>


                        <div style={{ display: `flex`, flexDirection: `column`, width: `100%`, marginLeft: `0px`, marginRight: `15px` }}>
                            <StyledText>
                                There are DLL injection tools that can retrieve the database of hashed passwords. Please note that hashed passwords are not the same as encrypted passwords. Encrypted
                                passwords can be decrypted given the shared secret or
                                private key. However, hashed passwords cannot be reverse engineered. So what is the danger of a thief getting the hashes. A lot! There are tools like <a target='_blank' href='https://www.darknet.org.uk/2006/02/rainbowcrack-how-to-use-rainbow-crack-rainbow-tables/' style={{ color: `#00b0bd` }}>Rainbow Crackers</a> which can crack any 14 character or less password in a matter of minutes if you can provide the password hash. There are websites where you an paste a captured hash and they will use their computing power to crack the LanMan or MD5 hash for you.
                            </StyledText>
                        </div>
                    </div>
                </div>


                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>WHAT IS WRONG WITH THE WINDOWS PASSWORD POLICY SETTINGS?</StyledContentTitle>


                    <StyledText>
                        Windows gives you the tools to control password length, history and expiration, but no good controls to enforce the use of reasonable passwords that are not easily hacked.  <b style={{ fontWeight: `bold` }}>Without nFront Password</b> Filter it is highly likely that weak, easily cracked passwords are allowed on your network.

                    </StyledText>

                    <StyledText style={{ textAlign: 'center', marginTop: `50px`, marginBottom: `20px` }}>Consider the following standard Windows policy:</StyledText>

                    <img src={pf1} width="100%" />
                </div>

            </StyledInnerWrapper >

            <TopButton />

            <StyledInnerWrapper>

                <div>
                    <StyledText style={{ textAlign: 'center', marginTop: `50px`, marginBottom: `20px` }}>THE WINDOWS PASSWORD POLICY ABOVE DOES NOT PREVENT ANY OF THE FOLLOWING PASSWORDS :</StyledText>

                    <BlueBox>
                        <StyledText>123456</StyledText>
                        <StyledText>februarypw</StyledText>
                        <StyledText>qwerty123</StyledText>

                        <StyledText>januarypw</StyledText>
                        <StyledText>myuserid</StyledText>
                        <StyledText>abcdefgh</StyledText>

                        <StyledText>march123</StyledText>
                        <StyledText>mydogsname</StyledText>
                        <StyledText>aaaaaaaa</StyledText>
                    </BlueBox>

                    <img src={pf3} style={{ marginTop: `60px` }} width="100%" />

                    <StyledText style={{ textAlign: 'center', marginTop: `50px`, marginBottom: `20px` }}>EVEN IF YOU ENABLE WINDOWS COMPLEXITY YOU HAVE PASSWORDS LIKE THIS:</StyledText>

                    <GreenBox>
                        <StyledText>Password1</StyledText>
                        <StyledText>Summer2019</StyledText>
                        <StyledText>Company1</StyledText>

                        <StyledText>Microsoft1</StyledText>
                        <StyledText>Welcome1</StyledText>
                        <StyledText>LetMeIn1</StyledText>
                    </GreenBox>



                </div>

                <StyledText style={{ fontFamily: 'HB', fontWeight: 'bold', marginTop: `50px`, marginBottom: `20px` }}>NOW CONSIDER THE ROBUST POLICY CAPABILITIES WITH NFRONT PASSWORD FILTER</StyledText>


                <img src={pf5} width="100%" style={{ maxWidth: '600px' }} />
                <br />
                <br />

            </StyledInnerWrapper>

            <TopButton />

            <StyledInnerWrapper>

                <StyledText style={{ paddingTop: `40px` }}>
                    Each policy in nFront Password Filter has over 40 settings. You can enforce specific requirements based on character types. There are several rules to break typical user patterns.
                    The most effective rule is the dictionary checking rule. The filter can check million of words in less than one second.
                </StyledText>

                <StyledText style={{ paddingTop: `40px` }}>
                    To see a more exact comparison of settings see these links:
                </StyledText>

                <StyledText><a target='_blank' href='https://nfrontsecurity.com/products/nfront-password-filter/nfront-password-filter-vs-windows-2003-password-policy.php' style={{ color: `#00b0bd` }} > nFront Password Filter versus the Windows 2003 Password Policy</a></StyledText>

                <StyledText><a target='_blank' href='https://nfrontsecurity.com/products/nfront-password-filter/nfront-password-filter-vs-windows-2012-password-policy.php' style={{ color: `#00b0bd` }}> nFront Password Filter versus Windows 2008/2012/2016/2019 Password Policies</a></StyledText>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>CONTROLLED BY GROUP POLICY</StyledContentTitle>

                    <StyledText>
                        nFront Password Filter is controlled using a single Group Policy Object configuration. After installation of the software on all
                        domain controllers, simply create a new GPO, load one of our provided templates (ADM and ADMX templates provided) and
                        configure your policies. It's that easy!

                    </StyledText>


                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>PASSWORD POLICIES LINKED TO GROUPS OR ORGANIZATIONAL UNITS</StyledContentTitle>

                    <StyledText>
                        nFront Password Filter is controlled by a single GPO, not a bunch of confusing GPOs all over the place. You can associate any policy in the MPE version with one or more security groups or organizational units. Nested groups are supported.
                        Thus, you can easily use the same groups that you have created for resource security to control password security. No need to re-organize your OU structure to support your password policies. No need to run Resultant Set of Policy to see who gets what policy. No need to edit multiple GPOs all over the place or figure the best policy precedence order such that one policy does not negate the other.
                    </StyledText>


                </div>


                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>GRANULAR PASSWORD POLICIES</StyledContentTitle>

                    <StyledText>
                        nFront Password Filter gives you granular control over your password policies. It can put min and max limits on specific types of characters,
                        reject passwords that contain userids/usernames and even check a new password against a multi-language dictionary with over 2 million words in less than 1 second.
                    </StyledText>


                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>WHAT ABOUT FINE-GRAINED PASSWORD POLICIES?</StyledContentTitle>

                    <StyledText>
                        Windows 2008, Windows 2012, Windows 2016, and Windows 2019 support multiple password policies in the same domain. However, the policy settings are the same basic policies that are in Windows 2000 and Windows 2003.
                        The only thing granular about fine-grained policies is the ability to apply them to different OUs. The policies do not have granular rules. You can set the minimum length, min/max age, history and complexity but still not stop passwords like Password1. The settings are not
                        robust enough to prevent the use of weak and easily cracked passwords. The settings are also cumbersome to put in place with no GUI to manage the settings.

                    </StyledText>


                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>MULTIPLE POLICIES</StyledContentTitle>

                    <StyledText>
                        nFront Password Filter MPE allows you to have up to 6 different password policies in the same Windows domain. Each policy
                        can be associated with one or more security groups and/or OUs. You can have strong password polices for Domain
                        Administrators and those with access to more privileged information (credit card data, tax information, etc.). You can also
                        associate weaker policy with other groups like "Mainframe Users."

                    </StyledText>


                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>POLICY RULES TO ENSURE PASSWORD COMPATIBILITY ACROSS OTHER SYSTEMS</StyledContentTitle>

                    <StyledText>
                        Suppose you sync your Windows passwords with UNIX or AS/400 or other mainframe systems. You do not want a one-size fits-all password policy
                        that has to be dumbed down to the least common denominator. System like UNIX or mainframes often truncate passwords longer than 8 or 12 characters.
                        Furthermore, such systems often do not accept certain special characters. With nFront Password Filter
                        you can control the special characters which are accepted or block the use of any special characters.

                    </StyledText>


                </div>




                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>PASSWORD POLICY RULES TO ENFORCE THE USE OF PASSPHRASES</StyledContentTitle>

                    <div style={{ display: `flex`, flexDirection: `row`, width: `100%` }}>




                        <div style={{ display: `flex`, flexDirection: `column`, width: `100%`, marginRight: `37.5px` }}>

                            <StyledText> Passphrases are simply long passwords like "The dog ate my newspaper." or "I love Chocolate!"
                                Such phrases make great passwords because they are long and long passwords are generally always superior to shorter ones.
                                You can configure nFront Password Filter to require a longer length for the password and require a minimum number of spaces to be used in the password.
                                This should get you well on your way to the correct horse battery staple passphrases you want to see on your network.<br /><br />

                                Since passphrases typically contain dictionary words, you can skip dictionary checking for passwords over a specified

                            </StyledText>



                        </div>


                        <div style={{ display: `flex`, flexDirection: `column`, width: `100%`, marginLeft: `0px`, marginRight: `15px` }}>
                            <StyledText>
                                number of characters.
                                So long passwords may contain dictionary words but short passwords may not.<br /><br />
                                The filter also supports a feature called Length Based Aging. You can use this to incentivize your users to use
                                passphrases or longer passwords. For example, you may decide that users can keep passwords for one year if the passwords
                                are over 20 characters but passwords that are 10-15 characters must be changed every 90 days.
                            </StyledText>
                        </div>



                    </div>




                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>POLICIES THAT CANNOT BE BYPASSED</StyledContentTitle>

                    <StyledText>
                        nFront Password Filter is not some set of Java rules on a website that are easily bypassed. nFront Password Filter is
                        integrated into the operating system and runs as a thread under the local security authority (the lsass.ese process).
                        The polices you create cannot be bypassed with an alternative password change mechanism.

                    </StyledText>
                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>WHY NOT WRITE A CUSTOM PASSFILT.DLL (PASSWORD FILTER)?</StyledContentTitle>

                    <StyledText>
                        Writing a custom passfilt.dll is not a trivial process and is much more involved than a simple win32 application. The custom password filter must interface to
                        the Local Security Authority (the lsass.exe process) and runs as a thread of the LSA. You cannot afford a bad line of code or an overlooked exception. A bad line
                        of code can quickly mean a BSOD (blue screen of death). A memory leak or failure to use exception handing and secure coding techniques can deal to a security vulnerability and possible exploitation. A passfilt.dll works on the password in Unicode clear text and care must be taken to properly destroy the memory used by such buffers.

                    </StyledText>

                    <br />
                    <div style={{ display: `flex`, flexDirection: `row`, width: `100%` }}>

                        <div style={{ display: `flex`, flexDirection: `column`, width: `100%`, marginRight: `37.5px` }}>
                            <StyledText>We got started in 2001 writing custom password filters for many different organizations. After noticing many similarities among the requests we decided to write a "configurable
                                customer password filter." We were the first to introduce a password filter controlled by a group policy. In 2005, we were the first to release a 64-bit password filter. We were the first company to put a password strength meter on the Windows change password screen. Currently we are the only company to offer rules for length-based password aging
                                (e.g. longer passwords can be kept for a longer
                                period of time).

                            </StyledText>
                        </div>

                        <div style={{ display: `flex`, flexDirection: `column`, width: `100%`, marginRight: `15px` }}>
                            <StyledText>You should contemplate the following questions if you are considering the development of a custom passfilt:

                                Will the code be written in house or by an external firm? Who will handle support issues?
                                Who will maintain the code and update it (as in the case of 64-bit servers)?
                            </StyledText>
                        </div>

                    </div>
                </div>

                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>DICTIONARY CHECKING</StyledContentTitle>

                    <StyledText>
                        nFront Password Filter goes beyond giving you control over character types and includes a very fast dictionary check feature.
                        In less than 1 second, nFront Password Filter can scan a 2 million word dictionary and ensure that the user's proposed new
                        password is not contained in the dictionary file!<br /><br />

                        nFront Password Filter ships with a 27,000 word customizable, plain-text dictionary. The dictionary check feature looks for a
                        case-insensitive exact match (instead of a substring match) between the proposed new password and each entry in the dictionary. The substring search feature can be enable to look for the dictionary word anywhere within the password. You can customize the dictionary by editing the file in Notepad or any other text editor of your choice.


                    </StyledText>


                </div>


                <div className='nFontPFContent'>

                    <img src={lineHon} />

                    <StyledContentTitle>OPTIONAL CLIENT TO HELP END USERS</StyledContentTitle>

                    <StyledText>
                        nFront Password Filter comes with an optional client that you can deploy to end-user workstations. You can choose to include your
                        own custom message to the end user or our default password rules or both. You can also display a password strength meter. All settings, of course, are controlled by GPO.
                    </StyledText>

                    <br />
                    <div style={{ display: `flex`, flexDirection: `row`, width: `100%` }}>

                        <div style={{ display: `flex`, flexDirection: `column`, width: `100%`, marginRight: `37.5px` }}>

                            <StyledText>
                                The client automatically works in multiple languages (English, German, French, Spanish and Italian are supported).
                                It automatically reports the locale of the client workstation to the encrypted RPC service that supports the client.
                                The service then formulates the password policy rules in the language appropriate to the language of the client operating system.

                            </StyledText>

                        </div>


                        <div style={{ display: `flex`, flexDirection: `column`, width: `100%`, marginLeft: `0px`, marginRight: `15px` }}>

                            <StyledText>The client is compatible with Windows XP, Windows 7, Windows 8, Windows 8.1 and Windows 10. On Windows XP it operates as a GINA stub DLL (which is the only method support my Microsoft). On Windows 7 and above it operates as a credential provider.
                            </StyledText>

                        </div>
                    </div>
                </div>

                <img src={pf6} style={{ marginTop: `60px` }} width="100%" />

                <div className='nFontPFContent'>

                    <StyledContentTitle>
                        System Requirements For nFront Password Filter
                    </StyledContentTitle>

                    <StyledText>
                        Windows 2003, 2008, 2008 R2, Server 2012, Server 2012 R2, Server 2016, Server 2019<br />
                        x86 and x64 supported for all OS<br />
                        2 MB free disk space<br />
                        5 minutes of time per domain controller to install<br />
                        30 minutes of time per domain to configure and test.
                    </StyledText>
                </div>

                <div className='nFontPFContent'>

                    <StyledContentTitle style={{ marginTop: `0px` }} className='subContent_ar'>
                        System Requirements For nFront Password Filter Client
                    </StyledContentTitle>

                    <StyledText>
                        Windows XP, Windows 7, Windows 8, Windows 8.1 and Windows 10. It may also be installed on servers and DCs that are Windows 2003 and above.<br />
                        x86 and x64 supported for all OS<br />
                        2 MB free disk space<br />
                        We suggest an automated deployment using a software GPO or a tool like Microsoft System Center.
                    </StyledText>

                </div>
            </StyledInnerWrapper >
            <TopButton />


        </div >
    )
}

export default NFontPwd
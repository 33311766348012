import React from 'react';
import styled from 'styled-components';
import { device } from './common.styles';

const IconWrapper = styled.span`

  margin: 0 4px;

  svg {
    fill: #f8ca42;
  }
`;

const QuoteLeft = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 49.87 39.68">
      <path d="M0,39.67V23.84L6.88,0H17.15L12.26,23.28h8.45v16.4Zm29.16,0V23.84L36,0H46.3L41.42,23.28h8.45v16.4Z" />
    </svg>
  </IconWrapper>
);

export default QuoteLeft;
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin: 0 4px;
`;

const SolutionHybridIcon = ({ className, ...otherProps }) => (
    <IconWrapper className={className} {...otherProps}>
        <svg viewBox="0 0 21.22 18.43">
            <path d="M16,15.72a.33.33,0,0,0-.33-.33H5.05a.33.33,0,0,0-.33.33V18.1a.33.33,0,0,0,.33.33H15.63A.33.33,0,0,0,16,18.1h0Zm-5.87,1.71H5.68v-1h4.41Zm2.06.28a.8.8,0,0,1,0-1.6.8.8,0,1,1,0,1.6Zm2,0a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8ZM16,11.85a.33.33,0,0,0-.33-.33H5.05a.33.33,0,0,0-.33.33h0v2.39a.33.33,0,0,0,.33.33H15.63a.33.33,0,0,0,.33-.33h0Zm-5.87,1.72H5.68v-1h4.41Zm2.06.3a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8Zm2,0a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8ZM16,8.07a.33.33,0,0,0-.33-.33H5.05a.33.33,0,0,0-.33.33v2.39a.33.33,0,0,0,.33.33H15.63a.33.33,0,0,0,.33-.33h0ZM10.09,9.74H5.68v-1h4.41Zm2.06.28a.8.8,0,1,1,0-1.6.8.8,0,0,1,.8.8.81.81,0,0,1-.8.81h0Zm2,0a.8.8,0,1,1,.8-.8.81.81,0,0,1-.8.81h0Z" /><path class="cls-1" d="M18,4.32a1.48,1.48,0,0,0,0-.21,2.33,2.33,0,0,0-3.47-2,5.71,5.71,0,0,0-10,2.37,3.37,3.37,0,0,0-1-.15,3.55,3.55,0,0,0,0,7.09h.12v-.87H3.53a2.67,2.67,0,0,1,0-5.34,2.59,2.59,0,0,1,1.16.26.44.44,0,0,0,.58-.21.33.33,0,0,0,0-.13,4.83,4.83,0,0,1,8.75-2.17.44.44,0,0,0,.61.11l0,0A1.45,1.45,0,0,1,17,4.61a.44.44,0,0,0,.26.56.58.58,0,0,0,.19,0h.21a2.67,2.67,0,0,1,0,5.34H17v.87h.63A3.55,3.55,0,0,0,18,4.32Z" />
        </svg>
    </IconWrapper>
);

export default SolutionHybridIcon;
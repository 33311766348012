import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin: 0 4px;
`;

const SolutionSecurityIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 15.92 21.4">
      <path d="M8,11.13a2.13,2.13,0,0,0-2.09,2.18,2.16,2.16,0,0,0,.66,1.51L6.29,17a.86.86,0,0,0,.2.65.89.89,0,0,0,.63.29H8.79a.89.89,0,0,0,.63-.29A.87.87,0,0,0,9.63,17l-.25-2.21a2.15,2.15,0,0,0,.08-3A2.12,2.12,0,0,0,8,11.13Zm.74,3.18-.19.14.29,2.68H7.12l.29-2.67-.19-.14A1.26,1.26,0,1,1,9,14a1.48,1.48,0,0,1-.28.28Z" /><path class="cls-1" d="M8,2.55A3.82,3.82,0,0,0,4.19,6.4V8.53h7.54V6.4A3.82,3.82,0,0,0,8,2.55ZM10.9,7.7H5V6.4a2.94,2.94,0,0,1,5.87-.11V7.7Z" /><path class="cls-1" d="M15.09,7.7h-.85V6.4A6.28,6.28,0,1,0,1.68,6.17a.89.89,0,0,0,0,.23V7.7H.84A.84.84,0,0,0,0,8.54V19.26A2.13,2.13,0,0,0,2.09,21.4H13.83a2.13,2.13,0,0,0,2.09-2.14V8.54a.84.84,0,0,0-.83-.84ZM13.83,20.56H2.09a1.28,1.28,0,0,1-1.26-1.3V8.53H2.51V6.4a5.45,5.45,0,0,1,10.9-.3V8.53h1.67V19.26A1.27,1.27,0,0,1,13.83,20.56Z" />
    </svg>
  </IconWrapper>
);

export default SolutionSecurityIcon;
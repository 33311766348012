import styled from "styled-components";
import Sticky from 'react-stickynode';
import { useMediaQuery } from 'react-responsive'
import SideMenu from "./SideMenu";
import { device } from "./common.styles";

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
`;

const MenuContent = styled.div`
    position: relative;
    width: 300px;
    padding-top: 60px;
    padding-left: 16px;
    z-index: 1;
`;

const Content = styled.div`
    width: calc(100% - 300px);

    @media only screen and ${device.mobileL} {
        width: 100%;
    }
`;

const SideMenuContent = ({ children, menuTitle, menuItems }) => {
    const isMobile = useMediaQuery({ query: device.mobileL });
    return (
        <Wrapper>
            {!isMobile && (
                <MenuContent>
                    <Sticky>
                        <SideMenu menuTitle={menuTitle} menuItems={menuItems} />
                    </Sticky>
                </MenuContent>
            )}
            <Content>{children}</Content>
        </Wrapper>
    );
};

export default SideMenuContent;

import styled, { withTheme, css } from "styled-components";
import { Link, Events } from 'react-scroll';
import { Heading } from "./common.styles";
import { useEffect, useState } from "react";
import classNames from 'classnames';
import { theme } from './ThemeContext';

const Wrapper = styled.div`
    padding: 16px;
    padding-right: 32px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 16px;
`;

const Content = styled.div`
    position: relative;
    &::before {
        position: absolute;
        top: 0;
        right: 0;
        width: 3px;
        height: 100%;
        background-color: #c3c3c3;
        display: block;
        content: ' ';
    }
`;

const AnimatedPin = styled.div`
    position: absolute;
    top: 0;
    right: -4px;
    transition: top .8s;

    &::before {
        position: absolute;
        top: -5px;
        display: block;
        content: ' ';
        background-color: white;
        width: 10px;
        height: 20px;
    }

    &::after {
        position: relative;
        display: block;
        content: ' ';
        background-color: #c3c3c3;
        width: 10px;
        height: 10px;
        border-radius: 50px;
    }

    ${props => props.linkPos && css`
        top: ${props.linkPos}px;
    `}
`;

const MenuTitle = styled(Heading)`
    color: #c2c2c2;
    padding-bottom: 10px;
    text-align: center;
`;

const MenuItem = styled.div`
    padding: 10px 8px 10px 0px;
    color: #c2c2c2;
    cursor: pointer;

    span svg {
        width: 20px;
        fill: #c2c2c2;
    }

    .active.menu:nth-child(n+2) {
        height: auto;
    }

    a {
        display: flex;
        align-items: center;
        font-size: ${props => props.theme.fontSize.body}px;
        font-weight: bold;
        &.active {
            color: #4f7ac7;

            span svg {
                fill: #4f7ac7;
            }
        }

        div {
            padding-left: 6px;
            padding-right: 0px;
            box-sizing: border-box;
        }
    }
`;

const MenuChild = styled.div`
    position: relative;
    overflow: hidden;
    height: 0;
    transition: height 0.8s;

    &.open {
        height: auto;
    }
`;

const MenuChildItem = styled.div`
    padding-left: 32px;
    padding-right: 16px;

    a {
        font-weight: normal;
        display: flex;
        align-items: start;
        padding: 8px 0;

        &.active {
            color: black;
        }
    }
`;

const SideMenu = ({ menuTitle, menuItems = [] }) => {
    const [linkPos, setLinkPos] = useState(18);
    const [scrollTop, setScrollTop] = useState(0);
    const onSetActive = (to) => {
        setTimeout(() => {
            const menuTitle = document.querySelector(`#MenuTitle`).getBoundingClientRect();
            const rect = document.querySelector(`div[data-section="${to}"]`).getBoundingClientRect();
            const tmpPos = rect.top - (menuTitle.height + menuTitle.top) + rect.height * 0.5 - 5;
            setLinkPos(tmpPos);
        }, 100);
    };

    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
            if (e.target.documentElement.scrollTop < 528) {
                setLinkPos(18);
            }
        };
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollTop])

    return (
        <Wrapper>
            <MenuTitle id='MenuTitle'>{menuTitle}</MenuTitle>
            <Content>
                {menuItems.map(({ label, icon, element, child = [] }, index) => {
                    const parent = document.querySelector(`div[data-section="${element}"] a`);
                    return (
                        <MenuItem key={index} data-section={element}>
                            <Link className={classNames({ active: window.scrollY < 528 && index === 0 })} to={element} spy={true} onSetActive={onSetActive} ignoreCancelEvents={false}>{icon} <div>{label}</div></Link>
                            <MenuChild className={classNames({ open: parent && parent.className.indexOf('active') === 0 })}>
                                {child.map(({ label, element }, cIndex) => (
                                    <MenuChildItem key={cIndex} data-section={element}>
                                        <Link spy={true} to={element} onSetActive={onSetActive}>
                                            <div>
                                                ⁜
                                            </div>
                                            <div>
                                                {label}
                                            </div>
                                        </Link>
                                    </MenuChildItem>
                                ))}
                            </MenuChild>
                        </MenuItem>
                    );
                })}
                <AnimatedPin linkPos={linkPos} />
            </Content>
        </Wrapper>
    )
};

export default withTheme(SideMenu);

import React from 'react'
import itradingPic from '../../assets/itrading_pic.jpg'




import itrading_download from '../../assets/itrading_download.png'
import itrading_enlarge1 from '../../assets/itrading_enlarge1.jpg'
import itrading_enlarge2 from '../../assets/itrading_enlarge2.jpg'

import styled from 'styled-components'
import ProductiTrading1Icon from '../../assets/ProductiTrading1Icon'
import ProductiTrading2Icon from '../../assets/ProductiTrading2Icon'
import ProductiTrading3Icon from '../../assets/ProductiTrading3Icon'
import ProductiTrading4Icon from '../../assets/ProductiTrading4Icon'
import ProductiTrading5Icon from '../../assets/ProductiTrading5Icon'
import ProductiTrading6Icon from '../../assets/ProductiTrading6Icon'
import { device, Heading, InnerWrapper, PreviewBox, Text } from '../../common.styles'
import DecoHeading from '../../DecoHeading'
import ImageDescBlock from '../../ImageDescBlock'
import './iTrading.css'
import ITradingBenefitGrid from './ITradingBenefitGrid'
import ITradingKeyFeaturesGrid from './ITradingKeyFeaturesGrid'

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import itradingPdf from '../../assets/pdf/iTrading.pdf'
import TopButton from '../TopButton/TopButton'




const StyledHeading = styled(Heading)`
    text-align: center;
`;

const Section = styled.div`
    text-align: center;
`;

const ITradingKeyFeaturesBox = styled.div`
    width: 100%;
    margin: auto;
    max-width: 75%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    
    @media only screen and ${device.mobileL} {
        max-width: 100%;
    }
`;

const ITradingBenefitBox = styled.div`
    width: 75%;
    margin: auto;
    max-width: 1191px;
`;

const ITrading = () => {
    const arrOfImages = [
        itrading_enlarge1,
        itrading_enlarge2,
    ];

    const [indexOfImages, setIndexOfImages] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const openModalAndSetIndex = (index) => {
        setIndexOfImages(index);
        setShowModal(true);
        return;
    };

    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>


            <InnerWrapper>


                <DecoHeading heading="iTrading" subheading="A value-added trading system designed especially for project oriented company" />


                <ImageDescBlock image={itradingPic} desc={'Users are able to handle customer relationships, project tracking, trading documents, and financial ledgers. It is a 100% web based application, so the user could access it through the internet.\n\niTrading provides a complete solution to streamline the complex trading workflow, and enhance your competitiveness in the market.'} />
                <br />
                <br />
                <Section>
                    <StyledHeading>
                        Features
                    </StyledHeading>
                    <br />
                    <br />
                    <ITradingKeyFeaturesBox>
                        <ITradingKeyFeaturesGrid icon={<ProductiTrading1Icon />} text="Support multi-level of CRM, brokerages, companies and currencies" />
                        <ITradingKeyFeaturesGrid icon={<ProductiTrading2Icon />} text="Keep track of the policy history" />
                        <ITradingKeyFeaturesGrid icon={<ProductiTrading3Icon />} text="Well integrated with the accounting system" />
                        <ITradingKeyFeaturesGrid icon={<ProductiTrading4Icon />} text="Embedded a policy calculation engine to calculate profit sharing of the policy with different parties" />
                        <ITradingKeyFeaturesGrid icon={<ProductiTrading5Icon />} text="Support different types of report formats, e.g. EXCEL, PDF, etc." />
                        <ITradingKeyFeaturesGrid icon={<ProductiTrading6Icon />} text="Multi-level security control and user access right" />
                    </ITradingKeyFeaturesBox>
                </Section>
                <br />
                <br />
                <Section>
                    <StyledHeading>
                        Benefits
                    </StyledHeading>
                    <br />
                    <br />
                    <ITradingBenefitBox>
                        <ITradingBenefitGrid index={1} text="Support virtual products (services maintenance, product warranties)" />
                        <ITradingBenefitGrid index={2} text="Access through any web browser" />
                        <ITradingBenefitGrid index={3} text=" Customizable" />
                        <ITradingBenefitGrid index={4} text="Standard workflow with simple user interface" />
                        <ITradingBenefitGrid index={5} text="Real time project status in a summary page" />
                        <ITradingBenefitGrid index={6} text="Jobs deadline reminder" />
                    </ITradingBenefitBox>
                </Section>
                <br />
                <br />
                <Section>
                    <StyledHeading>
                        Webpage Preview
                    </StyledHeading>
                    <br />
                    <PreviewBox>
                        <div>
                            <Text style={{ textAlign: 'left' }}>Click to enlarge</Text>
                            <img src={itrading_enlarge1} style={{ width: `300px`, height: `auto`, cursor: 'zoom-in' }} alt="" onClick={() => setShowModal(true)} />
                        </div>
                        <div>
                            <Text style={{ textAlign: 'left' }}>Click to enlarge</Text>
                            <img src={itrading_enlarge2} style={{ width: `300px`, height: `auto`, cursor: 'zoom-in' }} alt="" onClick={() => setShowModal(true)} />
                        </div>
                    </PreviewBox>

                    {showModal && (
                        <Lightbox
                            mainSrc={arrOfImages[indexOfImages]}
                            nextSrc={arrOfImages[(indexOfImages + 1) % arrOfImages.length]}
                            prevSrc={
                                arrOfImages[
                                (indexOfImages + arrOfImages.length - 1) % arrOfImages.length
                                ]
                            }
                            onCloseRequest={() => setShowModal(false)}
                            onMovePrevRequest={() =>
                                setIndexOfImages(
                                    (indexOfImages + arrOfImages.length - 1) % arrOfImages.length
                                )
                            }
                            onMoveNextRequest={() =>
                                setIndexOfImages(
                                    (indexOfImages + arrOfImages.length + 1) % arrOfImages.length
                                )
                            }
                        />
                    )}
                    <br />
                    <a target='_blank' href={itradingPdf} style={{ alignSelf: `center` }} ><img src={itrading_download} alt="" width={'28%'} /></a>
                </Section>
                <TopButton />
            </InnerWrapper>

        </div >
    )
}

export default ITrading

import { useMediaQuery } from 'react-responsive'
import { device } from '../../common.styles'
import OpmAward from '../../components/OpmAward/OpmAward'
import OpmAwardList from '../../components/OpmAwardList/OpmAwardList'
import OpmInfo from '../../components/OpmInfo/OpmInfo'
import OpmMethod from '../../components/OpmMethod/OpmMethod'
import "./OpmContent.css"

const OpmContent = () => {
    const contentHashes = {
        '#OpmInfo': null,
        '#OpmExperience': null,
        '#OpmApproach': null,
        '#OpmMethod': null,
        '#OpmAward': null,
        '#OpmAwardList': null,
    }

    const isMobile = useMediaQuery({ query: device.mobileL });

    const refreshScroll = () => {
        var hash = window.location.hash;
        if (hash && contentHashes[hash] instanceof HTMLElement) {
            if (isMobile) {
                setTimeout(() => {
                    const headerOffset = 80;
                    const elementPosition = contentHashes[hash].getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                    window.scrollTo({ top: offsetPosition });
                }, 100);
            } else {
                contentHashes[hash].scrollIntoView();
            }
        }
    }

    document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
            refreshScroll();
        }
    };

    return (
        <>
            <OpmInfo contentHashes={contentHashes} />
            <OpmMethod contentHashes={contentHashes} />
            <div ref={(v) => { contentHashes[`#OpmAward`] = v; }}></div>
            <OpmAward />
            <div ref={(v) => { contentHashes[`#OpmAwardList`] = v; }}></div>
            <OpmAwardList />

        </>

    );
}

export default OpmContent
import React from 'react';
import styled from 'styled-components';
import descHeading from './assets/deco_heading.png';
import { SectionHeading, SectionDesc, SectionSubheading, device } from './common.styles';

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 64px;
  box-sizing: border-box;

  @media only screen and ${device.mobileL} {
    padding: 0px 32px;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 4px;
  padding: 40px 0px 16px;
  color: #4f7ac7;
  border-bottom: 1px solid #002361;

  @media only screen and ${device.mobileL} {
    padding: 40px 0px 4px;
  }

  > div {
    text-align: left;
  }

  &::before, &::after {
    position: absolute;
    bottom: -4px;
    width: 8px;
    height: 8px;
    display: block;
    content: ' ';
    background-color: #002361;
    border-radius: 50px;
  }

  &::after {
    right: 0;
  }

  div {
    font-family: AB;
    width: 100%;
  }

  span {
    display: inline-block;
    margin-left: 2%;
    margin-right: 2%;
    width: 67.4px;
    svg {
      fill: #4f7ac7;
    }
    @media only screen and ${device.mobileL} {
      width: 15.7px;
    }
  }

  .default {
    width: 200px;
    object-fit: contain;

    @media only screen and ${device.mobileL} {
      width: 100px;
    }
  }
`;

const DecoHeading = ({ className, heading, desc, subheading, icon }) => (
  <Wrapper className={className}>
    <Content>
      <div style={{ display: `flex`, alignItems: `end`, paddingLeft: '8px' }}>
        <div>
          <SectionHeading>{heading}</SectionHeading>
          {desc && <SectionDesc>{desc}</SectionDesc>}
          {subheading && <SectionSubheading>{subheading}</SectionSubheading>}
        </div>
        {icon}
        {!icon && <img className='default' src={descHeading} alt="" />}
      </div>

    </Content>
  </Wrapper>
);

export default DecoHeading;




import React from 'react'
import { Helmet } from 'react-helmet'
import SolutionContent from '../components/solutonContent/SolutionContent'
import { Footer, Header } from '../containers'
import solutionnavBg from '../assets/solutionnav_bg.png'
import OPMLogo from '../assets/OPMLogo';
import styled from 'styled-components'
import { device } from '../common.styles';

const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AR;
  font-size: 88px;
  color: #343434;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const Solution = () => {
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Solution - Welcome to OPM System Company Limited</title>
        <meta name="Solution" content="OPM - Solution" />
      </Helmet>
      <Header bgImg={solutionnavBg} tone="dark" contentAlign='end'>
        <StyledOPMLogo />
        <Slogan1>
          Solutions
        </Slogan1>
      </Header>
      <SolutionContent />
      <Footer haveSideMenu={true} />
    </div>
  )
}

export default Solution
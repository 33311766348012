import React from 'react'





import cs from '../../assets/cs.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'

import styled from 'styled-components'
import SolutionHybridIcon from '../../assets/SolutionHybridIcon'
import { device, InnerWrapper, Text } from '../../common.styles'
import DecoHeading from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'

const ContactUsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    @media only screen and ${device.mobileL} {
        height: 100px;
    }

    a {
        width: 380.9px;
        
        @media only screen and ${device.mobileL} {
            width: 169.34px;
        }
    }
`;

const Orchestrator = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };
    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading desc="Orchestrator" icon={<SolutionHybridIcon />} />

                <Text style={{ maxWidth: '800px', margin: '16px auto 0', textAlign: 'center' }}>
                    Orchestration is automating many tasks together. It’s automation not of a single task but an entire
                    IT-driven process. Orchestrating a process, then, is automating a series of individual tasks to work
                    together to achieve easily management.
                </Text>

                <ContactUsContainer>
                    <a href='/pages/ContactUs#Message'><img src={cs} style={{ width: `100%` }} /></a>
                </ContactUsContainer>
            </InnerWrapper>

            <SectionBlock>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>

                </div>
            </SectionBlock>
        </div>
    )
}

export default Orchestrator
import React from 'react'




import cms from '../../assets/cms.png'
import cmsLine from '../../assets/cmsLine.png'
import cmsshake from '../../assets/cmsshake.png'
import serviceAd from '../../assets/service_ad.png'
import serviceCs from '../../assets/service_cs.png'
import serviceTs from '../../assets/service_ts.png'

import styled from 'styled-components'
import SolutionIndustryIcon from '../../assets/SolutionIndustryIcon'
import { device, InnerWrapper, Text } from '../../common.styles'
import DecoHeading from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import TopButton from '../TopButton/TopButton'
import './careManagement.css'

const ShakeContainer = styled.div`
    text-align: center;
    margin: 30px 0 60px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;

    @media only screen and ${device.mobileL} {
        flex-wrap: wrap;
    }

    img {
        align-self: start;
        width: 100px;

        @media only screen and ${device.mobileL} {
            width: 45px;
        }
    }
`;

const CmsImg = styled.img`
    max-width: 400px;
    width: 100%;

    @media only screen and ${device.mobileL} {
        width: 50%;
    }
`;


const CareManagement = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading desc="Case Management System" icon={<SolutionIndustryIcon />} />


                <div className='quote'>

                    <CmsImg src={cms} />



                </div>


                <div style={{ textAlign: `center`, margin: '16px 0' }}>
                    <Text>Case Management System for case handling of a group or individual people in need  and its follow-up action of them.</Text>

                    <img src={cmsLine} style={{ alignSelf: `center`, justifyContent: `top`, width: `70%`, marginTop: `30px` }} />
                </div>

                <ShakeContainer>

                    <img src={cmsshake} />

                    <Text>OPM works closely with Non-governmental organizations (NGO) to design case solution<br />for those people and allow inspector to keep tracking the progress of the people.</Text>

                </ShakeContainer>

            </InnerWrapper>


            <SectionBlock>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Application-Development'><img src={serviceAd} alt="" height="50" /></a>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginLeft: '16px', marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>
                </div>
            </SectionBlock>
            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default CareManagement
import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 106.6px;
  height: 68.3px;

  @media only screen and ${device.mobileL} {
    width: 35.53px;
    height: 22.77px;
  }

  svg {
    fill: #4f7ac7;
  }
`;

const ProductiTrading3Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 106.601 68.332">
      <g>
        <path d="m5890.7 1141.779-8.519-1.328a24.987 24.987 0 0 0-2.727-6.563l5.095-6.967a1.5 1.5 0 0 0-.151-1.894l-4.944-4.94a1.488 1.488 0 0 0-1.884-.149l-6.971 5.095a24.72 24.72 0 0 0-6.572-2.725l-1.316-8.519a1.513 1.513 0 0 0-1.448-1.24h-6.991a1.507 1.507 0 0 0-1.443 1.24l-1.315 8.519a24.7 24.7 0 0 0-6.574 2.725l-6.968-5.095a1.5 1.5 0 0 0-1.893.149l-4.946 4.94a1.509 1.509 0 0 0-.143 1.894l5.094 6.967a24.983 24.983 0 0 0-2.725 6.563l-8.527 1.328a1.519 1.519 0 0 0-1.231 1.443v6.992a1.5 1.5 0 0 0 1.231 1.44l8.527 1.325a25.04 25.04 0 0 0 2.725 6.57l-5.094 6.962a1.519 1.519 0 0 0 .143 1.9l4.946 4.942a1.505 1.505 0 0 0 1.893.145l6.968-5.1a25.143 25.143 0 0 0 6.574 2.728l1.315 8.517a1.506 1.506 0 0 0 1.443 1.233h6.991a1.512 1.512 0 0 0 1.448-1.233l1.316-8.517a25.161 25.161 0 0 0 6.572-2.728l6.971 5.1a1.514 1.514 0 0 0 1.892-.145l4.936-4.951a1.5 1.5 0 0 0 .151-1.892l-5.095-6.962a25.044 25.044 0 0 0 2.727-6.57l8.519-1.325a1.5 1.5 0 0 0 1.242-1.44v-6.992a1.507 1.507 0 0 0-1.242-1.442zm-32.933 17.537a12.6 12.6 0 1 1 12.6-12.591 12.6 12.6 0 0 1-12.604 12.591z" transform="translate(-5823.597 -1112.549)" />
        <path d="m5929.536 1128.967-4.544-.708a13.362 13.362 0 0 0-1.455-3.5l2.718-3.716a.8.8 0 0 0-.081-1.011l-2.637-2.634a.794.794 0 0 0-1-.08l-3.718 2.718a13.133 13.133 0 0 0-3.5-1.453l-.7-4.545a.806.806 0 0 0-.772-.661h-3.728a.806.806 0 0 0-.771.661l-.7 4.545a13.113 13.113 0 0 0-3.506 1.453l-3.717-2.718a.8.8 0 0 0-1.01.08l-2.638 2.634a.8.8 0 0 0-.076 1.011l2.717 3.716a13.309 13.309 0 0 0-1.454 3.5l-4.547.708a.81.81 0 0 0-.657.77v3.729a.8.8 0 0 0 .657.768l4.547.707a13.342 13.342 0 0 0 1.454 3.5l-2.717 3.714a.808.808 0 0 0 .076 1.014l2.638 2.636a.805.805 0 0 0 1.01.077l3.717-2.718a13.423 13.423 0 0 0 3.506 1.455l.7 4.543a.805.805 0 0 0 .771.658h3.728a.805.805 0 0 0 .772-.658l.7-4.543a13.444 13.444 0 0 0 3.5-1.455l3.718 2.718a.808.808 0 0 0 1.01-.077l2.632-2.641a.8.8 0 0 0 .081-1.009l-2.718-3.714a13.4 13.4 0 0 0 1.455-3.5l4.544-.707a.8.8 0 0 0 .662-.768v-3.729a.8.8 0 0 0-.667-.77zm-17.567 9.354a6.721 6.721 0 1 1 6.72-6.716 6.719 6.719 0 0 1-6.72 6.716z" transform="translate(-5823.597 -1112.549)" />
      </g>
    </svg>



  </IconWrapper>
);

export default ProductiTrading3Icon;




import React from 'react'
import { Link } from 'react-scroll'
import descImg from '../../assets/helpdesk_img.png'
import ServiceHDSIcon from '../../assets/ServiceHDSIcon'
import logo1 from '../../assets/services_helpdesk_logo1.png'
import logo2 from '../../assets/services_helpdesk_logo2.png'
import logo3 from '../../assets/services_helpdesk_logo3.png'
import logo4 from '../../assets/services_helpdesk_logo4.png'
import serviceTs from '../../assets/service_ts.png'
import { device, InnerWrapper } from '../../common.styles'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import ImagePointsBlock from '../../ImagePointsBlock'
import SectionBlock from '../../SectionBlock'
import './helpdeskService.css'
// import '././App.css'

import TopButton from '../TopButton/TopButton'
import { useMediaQuery } from 'react-responsive'


const SECTIONS = {
  APPLICATION_DEVELOPMENT: 'APPLICATION_DEVELOPMENT',
  CONSULTING_SERVICE: 'CONSULTING_SERVICE',
  TECHNICAL_SUPPORT: 'TECHNICAL_SUPPORT',
  OPM_CLOUD_COMPUTING_SERVICES: 'OPM_CLOUD_COMPUTING_SERVICES',
  HELP_DESK_SERVICES: 'HELP_DESK_SERVICES',
}

const itemsArr = [
  { logo: logo4, text: "Hardware and software help desk" },
  { logo: logo3, text: "Bilingual and multi-lingual support available" },
  { logo: logo2, text: "On-site and off-site support available" },
  { logo: logo1, text: "Customer service inquiries and information" },
]


const HelpdeskService = () => {
  const isMobile = useMediaQuery({ query: device.mobileL });

  return (
    <div>
      <InnerWrapper>
        <DecoHeading desc="Help Desk Services" icon={<ServiceHDSIcon />} />
        <DecoDesc1>
          OPM professional help desk team can provides 24/7 IT support services to your business.
          Our engineers can help to troubleshoot any technical problems and expedite solutions on and
          off-site to make sure your business is always on track.
        </DecoDesc1>
        <ImagePointsBlock image={descImg} title="Features" desc="We have a professional and experienced team providing:" itemsArr={itemsArr} />
      </InnerWrapper>
      <SectionBlock>
        <div style={{ position: 'absolute' }}>
          <TopButton isWhite={true} />
        </div>
        Related Services
        <div style={{ marginTop: `20px` }}>
          <a href=''><Link to={SECTIONS.TECHNICAL_SUPPORT} offset={isMobile ? -80 : null} spy={true}><img src={serviceTs} alt="" height="50" /></Link></a>
        </div>
      </SectionBlock >

      {/* <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} /> */}

    </div >
  )
}

export default HelpdeskService
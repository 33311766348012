import styled from 'styled-components';
import { device, Text } from '../../common.styles';

const Wrapper = styled.div`
    position: relative;
    padding: 16px 12px;
    box-sizing: border-box;
    // width: 33.333%;
    display: inline-flex;
    align-items: self-end;
    vertical-align: bottom;
    /* height: 200px; */
`;

const Content = styled.div`
    position: relative;
    padding: 0 16px;
    border-right: 1px solid #4f7ac7;
    border-bottom: 1px solid #4f7ac7;
    border-top: 1px solid #4f7ac7;
    // width: 300px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and ${device.mobileL} {
        height: 100px;
    }

    &::before {
        position: absolute;
        top: -11px;
        right: -11px;
        width: 20px;
        height: 20px;
        display: block;
        content: ' ';
        transform-origin: center center;
        transform: rotate(-45deg);
        background-color: white;
        border-left: 1px solid #4f7ac7;
    }

    &::after {
        position: absolute;
        top: -2px;
        left: 0;
        width: 50%;
        height: 4px;
        display: block;
        content: ' ';
        background-color: white;
    }
`;


const StyledText = styled(Text)`
    line-height: 1.5;
    padding: 8px;
    box-sizing: border-box;
    padding-bottom: 16px;
    padding-top: 16px;
    width: 250px;

    &::after {
        display: block;
        content: ' ';
        position: absolute;
        top: -4px;
        left: 50%;
        background-color: #4f7ac7;
        width: 6px;
        height: 6px;
        border-radius: 50px;
    }

    @media only screen and ${device.mobileL} {
        width: 200px;
    }
`;

// const DecoCircle = styled.div`
//     position: absolute;
//     top: -4px;
//     left: 50%;
//     background-color: #4f7ac7;
//     width: 6px;
//     height: 6px;
//     border-radius: 50px;
// `;

const Index = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 48px;
    font-family: AR;
    color: #4f7ac7;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #4f7ac7;
`;

const ITradingBenefitGrid = ({ className, index, text }) => (
    <Wrapper className={className}>
        <Content>
            {/* <DecoCircle /> */}
            <StyledText>{text}</StyledText>
        </Content>
        <Index>{index}</Index>
    </Wrapper>
)

export default ITradingBenefitGrid;
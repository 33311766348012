import styled from 'styled-components';
import { device, Text } from '../../common.styles';

const Wrapper = styled.div`
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 8px 16px;
    box-sizing: border-box;

    &:nth-child(2n) {
        .deco-right-line, .deco-circle {
            display: none;
        }
    }

    &:nth-child(n+5) {
        .deco-bottom-line, .deco-circle {
            display: none;
        }
    }

    @media only screen and ${device.mobileL} {
        width: 100%;
        padding:8px 0;

        .deco-right-line {
                display: none !important;
        }

        .deco-circle {
            display: none !important;
        }

        .deco-bottom-line {
            display: block !important;
        }

        &:last-child {
            .deco-bottom-line {
                display: none !important;
            }
        }
    }
`

const Content = styled.div`
    display: flex;
    align-items: center;
`;

const Thumb = styled.div`
    width: 130px;
    height: 130px;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and ${device.mobileL} {
        width: 45px;
        height: 45px;
    }
`;

const StyledText = styled(Text)`
    width: calc(100% - 130px);
    color: white;
    text-align: left;
    padding-left: 16px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        width: calc(100% - 45px);
  }
`;

const DecoBottomLine = styled.div`
    position: relative;
    width: 100%;
    padding: 0 16px;
    padding-top: 8px;

    &::after {
        display: block;
        content: ' ';
        background-color: white;
        width: 100%;
        height: 1px;
    }
`;

const DecoRightLine = styled.div`
    position: absolute;
    top: 20px;
    right: 0;
    width: 1px;
    height: calc(100% - 50px);

    &::after {
        display: block;
        content: ' ';
        background-color: white;
        width: 100%;
        height: 100%;
    }
`;

const DecoCircle = styled.div`
    position: absolute;
    bottom: 3px;
    right: -3px;
    background-color: white;
    width: 6px;
    height: 6px;
    border-radius: 50px;
`;

const AuthBlock = ({ icon, text }) => (
    <Wrapper>
        <Content>
            <Thumb>
                {icon}
            </Thumb>
            <StyledText>{text}</StyledText>
        </Content>
        <DecoBottomLine className="deco-bottom-line" />
        <DecoRightLine className="deco-right-line" />
        <DecoCircle className="deco-circle" />
    </Wrapper>
)

export default AuthBlock;
import React from 'react';

import jobPos from '../../assets/job_pos.png';
import location from '../../assets/location.png';
import time from '../../assets/time.png';


import styled, { withTheme } from 'styled-components';
import { device, Heading, Text } from '../../common.styles';
import './joinUsTitle.css';

const JobWrapper = styled.div`
  // max-width: 800px;
  margin: auto;
  padding: 16px;
  box-sizing: border-box;
`;

const JobHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media only screen and ${device.mobileL} {
    margin-bottom: 12px;
  }
`;

const JobHeading = styled(Heading)`
  color: #002361;
`;

const JobSubHeading = styled(Text)`
  font-family: HB;
  font-weight: bold;
  color: #343434;
`;

const JobDesc = styled(Text)`
  color: #676767;
  font-family: HR;
  line-height: 1.2;
`;

const ReadMoreText = styled(Text)`
    margin-bottom: 20px;
    font-family: HR;
    line-height: 1.2;
    // cursor: pointer;
    display: inline-block;
`;

const ApplyNowText = styled(Text)`
  color: white;
`;



const JoinUsTitle = ({ title, type, locationValue, respon, require }) => {
  const [readMore, setReadMore] = React.useState(false);
  const readMoreOnClick = () => {
    setReadMore(!readMore);
  };

  return (

    <JobWrapper>



      <div className="job-item">



        <div style={{ width: `100%` }}>

          <div className="flex_column">

            <JobHeadingContainer>
              <JobHeading className='job_title'>{title}</JobHeading>
              <a target='_blank' href="mailto:hr@opm.com.hk"><button className='applyBtn'><ApplyNowText>Apply Now</ApplyNowText></button></a>
            </JobHeadingContainer>

            <div className="flex-container flex_row joinUs-info-wrapper">
              <div className="flex-container flex_row">

                <img src={time} className='job_img' alt="time" />

                <JobDesc>{type}</JobDesc>

              </div>

              <div className="flex-container flex_row">

                <img src={location} className='job_img' alt="loc" />

                <JobDesc>{locationValue}</JobDesc>

              </div>
            </div>
            {readMore && (
              <div className='joinUs-detail-wrapper'>
                <JobSubHeading className='joinUs-heading'>Job Responsibilities:</JobSubHeading>


                <ul className='text-22'>

                  {respon.map(respon => {

                    return <li><JobDesc>{respon.desc}</JobDesc></li>
                  })}

                  {/* <li><JobDesc>Provide end-user software applications and computer hardware support and troubleshooting in a professional manner.</JobDesc></li>
                  <li><JobDesc>Keep record of daily data communication transactions, problems and remedial action taken</JobDesc></li>
                  <li><JobDesc>Handle the user requests according to the IT standards/procedures/guidelines</JobDesc></li>
                  <li><JobDesc>Responsible to work according to the shift duty assigned and support roster for 24/7 technical support to End users</JobDesc></li>
                  <li><JobDesc>Report Line manager or Supervisor and finish the duties with proper time management</JobDesc></li> */}

                </ul>


                <JobSubHeading className='joinUs-heading'>Job Requirements:</JobSubHeading>


                <ul className='text-22'>
                  {require.map(require => {

                    return <li><JobDesc>{require}</JobDesc></li>
                  })}
                  {/* <li><JobDesc>Work in customer site in Tung Chung</JobDesc></li>
                  <li><JobDesc>Diploma or above in Information Technology or Computer related disciplines</JobDesc></li>
                  <li><JobDesc>Hand-on experience in IT Helpdesk and End-User support</JobDesc></li>
                  <li><JobDesc>I.T. Certificates holder is an advantage</JobDesc></li>
                  <li><JobDesc>Good command in Cantonese and English, Putonghua is an advantage</JobDesc></li> */}

                </ul>
              </div>
            )}



            <ReadMoreText onClick={() => readMoreOnClick()}><u>{readMore && `Read less` || `Read more`}</u></ReadMoreText>

            <div className='sline' src={jobPos} alt="line"></div>


          </div>




        </div>


      </div>


    </JobWrapper >
  )
}

export default withTheme(JoinUsTitle);

import React, { useState } from 'react';

// import { Footer, Partner, FeatureList, WhatGPT3, Header,Features ,Solution} from './containers';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import './App.css';

import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import JoinUs from './pages/JoinUs';
import Product from './pages/Products';
import Service from './pages/Services';
import Solution from './pages/Solutions';

import ScrollToTop from './pages/ScrollToTop';
import SiteMap from './pages/Sitemap';
import TextVersion from './pages/TextVersion';
import OPM from './pages/WhyOPM';
import { theme, ThemeContext } from './ThemeContext';

const GlobalStyled = createGlobalStyle`
  p {
    font-family: HR;
  }
`;

const App = () => {
  const [selectedTheme, setSelectedTheme] = useState(theme);

  return (
    <ProSidebarProvider>
      <ThemeContext.Provider value={{
        theme,
        setSelectedTheme
      }}>
        <ThemeProvider theme={selectedTheme}>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path='/' element={<Home />} ></Route>
              <Route path='/pages/WhyOPM' element={<OPM />} ></Route>
              <Route path='/pages/Services' element={<Service />} ></Route>
              <Route path='/pages/ContactUs' element={<ContactUs />} ></Route>
              <Route path='/pages/Products' element={<Product />} ></Route>
              <Route path='/pages/Solutions' element={<Solution />} ></Route>
              <Route path='/pages/JoinUs' element={<JoinUs />} ></Route>
              <Route path='/pages/Sitemap' element={<SiteMap />} ></Route>
              <Route path='/pages/TextVersion' element={<TextVersion />} ></Route>
            </Routes>
          </Router>
          <GlobalStyled />
        </ThemeProvider>
      </ThemeContext.Provider>
    </ProSidebarProvider>

    // <div className='App'>
    //     <div className="gradient__bg">
    //       <Navbar/>

    //       <Features/>
    //       <FeatureList/>

    //       <Solution/>

    //       <Partner/>
    //       <Footer/>

    //     </div>
    // </div>
  )
}

export default App
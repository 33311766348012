import React from 'react'



import istorageLogo from '../../assets/istorageLogo.png'
import istorageAboutPdt from '../../assets/istorage_about_pdt.png'
import istorageFeature from '../../assets/istorage_feature.png'
import moreInfo from '../../assets/moreInfo.png'

import styled from 'styled-components'
import { device, Heading, InnerWrapper, Text } from '../../common.styles'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import ImageDescBlock from '../../ImageDescBlock'
import './iStorage.css'
import IStorageProductGrid from './IStorageProductGrid'
// products
import product1Logo from '../../assets/istorage_products/Product-01.png'
import product1 from '../../assets/istorage_products/Product-02.png'
import product2Logo from '../../assets/istorage_products/Product-03.png'
import product2 from '../../assets/istorage_products/Product-04.png'
import product3Logo from '../../assets/istorage_products/Product-05.png'
import product3 from '../../assets/istorage_products/Product-06.png'
import product4Logo from '../../assets/istorage_products/Product-07.png'
import product4 from '../../assets/istorage_products/Product-08.png'
import product5Logo from '../../assets/istorage_products/Product-09.png'
import product5 from '../../assets/istorage_products/Product-10.png'
import product6Logo from '../../assets/istorage_products/Product-11.png'
import product6 from '../../assets/istorage_products/Product-12.png'
import product7Logo from '../../assets/istorage_products/Product-13.png'
import product7 from '../../assets/istorage_products/Product-14.png'
import product8Logo from '../../assets/istorage_products/Product-15.png'
import product8 from '../../assets/istorage_products/Product-16.png'
import product9Logo from '../../assets/istorage_products/Product-17.png'
import product9 from '../../assets/istorage_products/Product-18.png'
import product10Logo from '../../assets/istorage_products/Product-19.png'
import product10 from '../../assets/istorage_products/Product-20.png'
import product11Logo from '../../assets/istorage_products/Product-21.png'
import product11 from '../../assets/istorage_products/Product-22.png'
import product12Logo from '../../assets/istorage_products/Product-23.png'
import product12 from '../../assets/istorage_products/Product-24.png'
import product13Logo from '../../assets/istorage_products/Product-25.png'
import product13 from '../../assets/istorage_products/Product-26.png'
import product14Logo from '../../assets/istorage_products/Product-27.png'
import product14 from '../../assets/istorage_products/Product-28.png'
import product15Logo from '../../assets/istorage_products/Product-29.png'
import product15 from '../../assets/istorage_products/Product-30.png'
import product16Logo from '../../assets/istorage_products/Product-31.png'
import product16 from '../../assets/istorage_products/Product-32.png'
import product17Logo from '../../assets/istorage_products/Product-33.png'
import product17 from '../../assets/istorage_products/Product-34.png'
import TopButton from '../TopButton/TopButton'

const IStorageProductsArr = [
    { thumb: product1, logo: product1Logo, pointsArr: ['Capacities up to 5TB', 'IP 56 Certified', 'FIPS PUB 197 Validated Encryption Algorithm', 'Tamper Proof', 'available in 4 colours'] },
    { thumb: product2, logo: product2Logo, pointsArr: ['Capacities up to 5TB', 'IP 56 Certified', 'FIPS 140-2 Level 3, NLNCSA Certifications pending', 'Tamper Proof & Tamper Evident Design'] },
    { thumb: product3, logo: product3Logo, pointsArr: ['Capacities up to 4TB', 'IP 56 Certified', 'FIPS PUB 197 Validated Encryption Algorithm', 'Tamper Proof', 'available in 4 colours'] },
    { thumb: product4, logo: product4Logo, pointsArr: ['Capacities up to 4TB', 'IP 56 Certified', 'FIPS 140-2 Level 3, NLNCSA Certifications pending', 'Tamper Proof & Tamper Evident Design'] },
    { thumb: product5, logo: product5Logo, pointsArr: ['Capacities up to 10TB', 'FIPS 140-2 Level 2, NLNCSA - Certifications pending', 'Tamper Proof Design'] },
    { thumb: product6, logo: product6Logo, pointsArr: ['USB 3.0 Flash Drive', 'Capacities up to 64GB', 'FIPS 140-2 Level 3 certified', 'NLNCSA - Certified', 'IP57 certified - water/dust resistant', 'Bootable drive'] },
    { thumb: product7, logo: product7Logo, pointsArr: ['USB 3.0 Flash Drive', 'Capacities up to 64GB', 'FIPS PUB 197 certified', 'Bootable Drive'] },
    { thumb: product8, logo: product8Logo, pointsArr: ['USB 2.0 Flash Drive', 'Capacities up to 32GB', 'FIPS 140-2 Level 3 certified', 'IP57 certified - water/dust resistants'] },
    { thumb: product9, logo: product9Logo, pointsArr: ['USB 2.0 Flash Drive', 'Capacities up to 32GB', 'FIPS PUB 197 certified'] },
    { thumb: product10, logo: product10Logo, pointsArr: ['USB 3.0 Solid State Flash Drive', 'Capacities up to 240GB', 'FIPS 140-2 Level 3 certified', 'IP58 certified', 'Bootable drive'] },
    { thumb: product11, logo: product11Logo, pointsArr: ['USB 3.0 Portable Solid State Drive', 'Capacities up to 1TB', 'FIPS PUB 197 certified'] },
    { thumb: product12, logo: product12Logo, pointsArr: ['USB 3.0 Portable Hard Drive', 'Capacities up to 2TB', 'FIPS 140-2 Level 2 Inside certified'] },
    { thumb: product13, logo: product13Logo, pointsArr: ['USB 3.0 Portable Solid State Drive', 'Capacities up to 1TB', 'FIPS 140-2 Level 2 Inside certified'] },
    { thumb: product14, logo: product14Logo, pointsArr: ['USB 2.0 Portable Hard Drive', 'Capacities up to 1TB', 'FIPS PUB 197 certified', 'CESG / CCTM Certified'] },
    { thumb: product15, logo: product15Logo, pointsArr: ['USB 2.0 Portable Solid State Drive', 'Capacities up to 1TB', 'FIPS PUB 197 certified', 'CESG / CCTM Certified'] },
    { thumb: product16, logo: product16Logo, pointsArr: ['USB 3.0 Desktop Hard Drive', 'Capacities up to 8TB', 'FIPS Pub 197 Certified'] },
    { thumb: product17, logo: product17Logo, pointsArr: ['On-device Crypto-chip', 'Five factor authentication', 'BRUTE FORCE HACK DEFENCE MECHANISM', 'FIPS Level 3 compliant tamper proof & evident design'] },
]

const Section = styled.div`
    text-align: center;
`;

const ProductList = styled.div`
    display: flex;
    flex-flow: row wrap;
`

const StyledInnerWrapper = styled(InnerWrapper)`
    max-width: 1200px;
    margin: auto;
`;

const StyledHeading = styled(Heading)`
    margin-left: 20px;
    text-align: center;
`;

const StyledText = styled(Text)`
    color: #343434;
`

const MoreInfoImg = styled.img`
    width: 383.1px;

    @media only screen and ${device.mobileL} {
        width: 170px;
    }
`;

const IStorage = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <DecoHeading heading="iStorage" subheading="Securing the Mainstream" />
            <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `center`, objectFit: 'contain', marginTop: '32px' }}><img src={istorageLogo} alt="" /></div>
            <DecoDesc1>
                Trusted global leader of PIN authenticated/ hardware encrypted data storage devices
            </DecoDesc1>
            <StyledInnerWrapper>

                <ImageDescBlock image={istorageAboutPdt} title="About the Product" titleAlign='center' desc="We have USB Flash Drives, Hard Disk Drives, and Solid State Drive formats. We deliver the most innovative products to securely store and protect data to Military specified encryption levels, safeguarding your valuable business information whilst ensuring compliance to regulations and directives." />

                <ImageDescBlock image={istorageFeature} title="Features" titleAlign='center' imageFirst={false}>
                    <ul>
                        <li><StyledText>Securely store and protect data to military specified encryption levels</StyledText></li>
                        <li><StyledText>No software required</StyledText></li>
                        <li><StyledText>PIN authentication - full disk encryption</StyledText></li>
                        <li><StyledText>Immune to key-loggers and brute force attacks</StyledText></li>
                        <li><StyledText>Safeguarding valuable business information whilst ensuring compliance to regulations and directives</StyledText></li>
                        <li><StyledText>AES 256-bit hardware encryption</StyledText></li>
                    </ul>
                </ImageDescBlock>
                <Section>
                    <br />
                    <a target='_blank' href='https://istorage-uk.com/'> <MoreInfoImg src={moreInfo} alt="" /></a>
                    <br />
                </Section>
            </StyledInnerWrapper>
            <TopButton />
            <StyledInnerWrapper>
                <br />
                <br />
                <br />
                <StyledHeading>
                    Product List
                </StyledHeading>
                <br />
                <br />
                <ProductList>
                    {IStorageProductsArr.map(({ thumb, logo, pointsArr }, index) => <IStorageProductGrid key={index} thumb={thumb} logo={logo} pointsArr={pointsArr} />)}
                </ProductList>
                <br />
                <br />
            </StyledInnerWrapper>
            <TopButton />
        </div>
    )
}

export default IStorage
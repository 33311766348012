import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductPrimeIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 19.84 9.37">
      <path d="M37.94,303.28a4.5,4.5,0,0,0-1-.45.35.35,0,0,0-.14-.06h-.12l-.09,0a2,2,0,1,0-1.35,0h-.05l-.15,0a.44.44,0,0,0-.16.06,4.28,4.28,0,0,0-1,.45c-1.39.84-2,2.18-2,4.34a.32.32,0,1,0,.64,0c0-2.43.86-3.28,1.7-3.79a4,4,0,0,1,.72-.35l.5.67L35,306.09a.33.33,0,0,0,.07.28l.6.74a.32.32,0,0,0,.25.12.33.33,0,0,0,.25-.12l.61-.77a.29.29,0,0,0,.06-.28l-.47-1.92.5-.66a4.08,4.08,0,0,1,.73.35c.83.51,1.69,1.36,1.69,3.79a.33.33,0,0,0,.65,0c0-2.16-.61-3.5-2-4.34Zm-2,.39-.24-.33.24,0,.25,0Zm-1.25-2.23a1.4,1.4,0,1,1,1.25.77A1.4,1.4,0,0,1,34.67,301.44Zm1.25,4.95-.25-.31.26-1.05.25,1Z" transform="translate(-31.91 -298.58)" />
      <path d="M49.75,303.1a4.5,4.5,0,0,0-1-.45.35.35,0,0,0-.14-.06h-.12l-.09,0a2,2,0,1,0-1.34,0H47l-.15,0a.33.33,0,0,0-.16.07,3.86,3.86,0,0,0-1,.44c-1.39.84-2,2.18-2,4.35a.32.32,0,0,0,.32.32.32.32,0,0,0,.33-.32c0-2.44.85-3.29,1.69-3.8a4.79,4.79,0,0,1,.72-.35l.5.67-.47,1.94a.32.32,0,0,0,.07.28l.6.74a.32.32,0,0,0,.25.12.34.34,0,0,0,.26-.12l.6-.77a.28.28,0,0,0,.06-.28L48.18,304l.5-.67a4.08,4.08,0,0,1,.73.35c.84.51,1.69,1.36,1.69,3.8a.32.32,0,0,0,.32.32.32.32,0,0,0,.33-.32c0-2.16-.62-3.5-2-4.35Zm-2,.39-.25-.32.25,0,.24,0Zm-1.25-2.23a1.4,1.4,0,1,1,1.88.63,1.52,1.52,0,0,1-.63.14A1.36,1.36,0,0,1,46.48,301.26Zm1.25,5-.25-.31.25-1.06.26,1Z" transform="translate(-31.91 -298.58)" />
      <path d="M44.52,302.19H39.05a.33.33,0,0,1,0-.65h5.47a.33.33,0,1,1,0,.65Z" transform="translate(-31.91 -298.58)" />
      <path d="M44.81,302s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,1,0,0v-.13a0,0,0,0,0,0,0v0s0,0,0,0l0,0,0,0h0l-1.15-1.36a.31.31,0,0,0-.45,0,.32.32,0,0,0,0,.44l.7.83H39.67l.7-.83a.32.32,0,1,0-.49-.41l-1.15,1.36h0v0h0V302h0v0l0,0h0l1.15,1.36a.32.32,0,0,0,.45,0,.32.32,0,0,0,0-.43l-.7-.83h4.16l-.7.83a.32.32,0,0,0,.49.41l1.15-1.36h0l0,0,0,0Z" transform="translate(-31.91 -298.58)" />
    </svg>
  </IconWrapper>
);

export default ProductPrimeIcon;




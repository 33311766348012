import React from 'react'

import consultingIcon2 from '../../assets/Consulting Services-10.png'
import consultingIcon3 from '../../assets/Consulting Services-11.png'
import consultingIcon1 from '../../assets/Consulting Services-9.png'
import consultingCloud1 from '../../assets/consulting_cloud_1.png'
import serviceIbroker from '../../assets/service_ibroker.png'
import TopButton from '../TopButton/TopButton'


import styled from 'styled-components'
import ServiceCSIcon from '../../assets/ServiceCSIcon'
import { Heading, Text } from '../../common.styles'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import SectionBlock from '../../SectionBlock'
import './consultingService.css'

// import '././App.css'

const ApplicationContent = styled.div`
    padding: 16px;
    box-sizing: border-box;
`;

const FeatureCenter = styled.div`
    position: relative;
    padding-top: 30px;
    text-align: center;

    img.top {
        position: absolute;
        left: 0;
        width: 30px;
    }

    .consulting-cloud-img {
        max-width: 900px;
        width: 100%;
        margin: auto;
    }
`;

const ConsultingService = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <ApplicationContent>
                <DecoHeading desc="Consulting Services" icon={<ServiceCSIcon />} />




                <FeatureCenter>
                    <div className='consulting-cloud-img'>
                        <img src={consultingCloud1} alt="" width="100%" />
                    </div>

                    <br />
                    <DecoDesc1>
                        OPM aligns your IT strategy with business priorities to promote growth and innovation. We create
                        innovative and dynamic infrastructure designs for you to deliver improved business responsiveness
                        and return on investment (ROI). Integrate best practices to help transform and optimize your IT and
                        design solution architecture for you.
                    </DecoDesc1>
                </FeatureCenter>
                <TopButton />
                <FeatureCenter>

                    <Heading>Features</Heading>
                    <div>

                        <Text> OPM Consulting Services ensure smooth implementations with the right economics,<br />
                            at the right time, with the functionality you need.</Text>

                        <br />
                    </div>

                    <div className='consulting-feature-wrapper'>
                        <div className='consulting-feature-item'>
                            <img src={consultingIcon1} alt=""></img>
                            <Text>
                                <strong>Focus on deadlines and ROI</strong>
                                <p>Help maximize your technology
                                    investment and partner with you
                                    to collaboratively resolve your
                                    challenges.</p>
                            </Text>
                        </div>


                        <div className='consulting-feature-item'>
                            <img src={consultingIcon2} alt=""></img>
                            <Text>
                                <strong>A proven track record</strong>
                                <p>Our expertise ranges across project
                                    management issues and deep into
                                    a variety of IT technologies.
                                    We have delivered measurable
                                    business outcomes and tangible
                                    ROI to clients since 2006.</p>


                            </Text>
                        </div>
                        <div className='consulting-feature-item'>
                            <img src={consultingIcon3} alt=""></img>
                            <Text>
                                <strong>Full dedication and accoutnability</strong>
                                <p>Our organizational structure is designed
                                    for full accountability.
                                    Your Project Manager takes complete
                                    responsibility for the success of your
                                    implementation.</p>
                            </Text>
                        </div>

                    </div>
                    <div className='counsulting-remark'>
                        <Text><span>Committed to your implementation success by mapping our proven consulting methodology to your working model.</span></Text>
                    </div>
                </FeatureCenter>
            </ApplicationContent>
            {/* 
            <div className='consultImg'>
                <img src={inlineContent} />

            </div>

            <div className='consultImg'>
                <img src={inlineFooter} />

            </div> */}



            {/* </div> */}
            {/* 
            <div className='horizontal'>



                <div className='applicationImg'>


                </div>

                <div className='vertical'>

                </div>


            </div> */}

            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Products#iBrokers'><img src={serviceIbroker} alt="" height="50" /></a>
                </div>
            </SectionBlock>

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />

        </div>
    )
}

export default ConsultingService
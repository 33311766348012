import React from 'react'
import { Helmet } from 'react-helmet'
import ContactUsContent from '../components/contactUs/ContactUsContent'
import { Footer, Header } from '../containers'
import contactusBg from '../assets/contactus_bg.png'
import styled from 'styled-components'
import OPMLogo from '../assets/OPMLogo'
import { device } from '../common.styles'

const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AR;
  font-size: 88px;
  color: #343434;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Welcome to OPM System Company Limited</title>
        <meta name="Contact Us" content="OPM - Contact Us" />
      </Helmet>
      <Header bgImg={contactusBg}>
        <StyledOPMLogo />
        <Slogan1>Contact Us</Slogan1>
      </Header>
      <ContactUsContent />
      <Footer />
    </>
  )
}

export default ContactUs
import styled from 'styled-components';
import { device, Text } from '../../common.styles';

const Wrapper = styled.div`
    position: relative;
    padding: 8px;
    box-sizing: border-box;
    width: 50%;
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    height: 200px;

    svg {
        width: 30px;
    }

    &:nth-child(2n) {
        .deco-right-line, .deco-circle {
            display: none;
        }
    }

    &:nth-child(n+3) {
        .deco-bottom-line, .deco-circle {
            display: none;
        }
    }

    @media only screen and ${device.mobileL} {
        width: 50%;

        .deco-bottom-line, .deco-right-line {
                display: block !important;
        }

        .deco-circle {
            display: none !important;
        }

        &:nth-child(2n) {
            .deco-right-line {
                display: none !important;
            }
        }

        &:nth-child(n+3) {
            .deco-bottom-line {
                display: none !important;
            }
        }

        &:first-child {
            .deco-circle {
                display: block !important;
            }
        }

        &:nth-child(n+6) {
            .deco-circle {
                bottom: auto;
                right: auto;
                top: -6px;
                left: -3px;
                display: block !important;
            }
        }
    }
`;

const Content = styled.div`
`;

const DecoBottomLine = styled.div`
    position: absolute;
    left: 25px;
    bottom: 5px;
    width: calc(100% - 50px);
    height: 1px;
    background-color: #4f7ac7;

    &::after {
        display: block;
        content: ' ';
        background-color: #4f7ac7;
        width: 1px;
        height: 100%;
    }
`;

const DecoRightLine = styled.div`
    position: absolute;
    top: 20px;
    right: 0;
    width: 1px;
    height: calc(100% - 50px);

    &::after {
        display: block;
        content: ' ';
        background-color: #4f7ac7;
        width: 100%;
        height: 100%;
    }
`;

const DecoCircle = styled.div`
    position: absolute;
    bottom: 3px;
    right: -3px;
    background-color: #4f7ac7;
    width: 6px;
    height: 6px;
    border-radius: 50px;
`;

const StyledText = styled(Text)`
    line-height: 1.5;
    padding: 8px;
    box-sizing: border-box;
    padding-bottom: 16px;
`;

const IconBox = styled.div`
    display: flex;
    align-items: center;
    text-align: left;

    @media only screen and ${device.mobileL} {
        display: inline;
        text-align: center;
    }
`;


const LDapProtectKeyFeaturesGrid = ({ className, icon, text }) => (
    <Wrapper className={className}>
        <Content>
            <IconBox>
                {icon}
                <StyledText>{text}</StyledText>
            </IconBox>
            <DecoBottomLine className="deco-bottom-line" />
            <DecoRightLine className="deco-right-line" />
            <DecoCircle className="deco-circle" />
        </Content>
    </Wrapper>
)

export default LDapProtectKeyFeaturesGrid;
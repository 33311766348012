import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin: 0 4px;
`;

const ServiceHDSIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 17.03 15.88">
      <path d="M15.64,453.47l0,.09c-.25,1-1.1,2.82-4,3.08a.54.54,0,0,0-.41-.2H9.59A.55.55,0,0,0,9,457a.57.57,0,0,0,.55.55h1.6a.55.55,0,0,0,.48-.3c3.27-.3,4.25-2.37,4.54-3.59l0-.11Z" transform="translate(-1.41 -441.65)" />
      <path d="M15.92,454a3.51,3.51,0,0,1-1-.16.35.35,0,0,1-.18-.3v-5.17l0-.21a5.28,5.28,0,0,0-.61-2.4,4.45,4.45,0,0,0-4.17-2,4.44,4.44,0,0,0-4.16,2,5.16,5.16,0,0,0-.62,2.4l0,.21v5.17a.35.35,0,0,1-.18.3,3.73,3.73,0,0,1-1,.16,1.28,1.28,0,0,1-1.2-.84,2.52,2.52,0,0,1-1.32-2.37,2.45,2.45,0,0,1,1.42-2.41,1.26,1.26,0,0,1,.5-.47,9.65,9.65,0,0,1,1-3.64c1.13-1.74,3-2.63,5.55-2.63s4.42.89,5.54,2.63a9.47,9.47,0,0,1,1,3.64,1.2,1.2,0,0,1,.5.47,2.45,2.45,0,0,1,1.42,2.41,2.48,2.48,0,0,1-1.32,2.37,1.26,1.26,0,0,1-1.19.84Zm-.5-.74a2.59,2.59,0,0,0,.48,0,.56.56,0,0,0,.57-.47.36.36,0,0,1,.26-.27,1.78,1.78,0,0,0,1-1.77,1.7,1.7,0,0,0-1-1.77.4.4,0,0,1-.25-.21.56.56,0,0,0-.36-.33.34.34,0,0,1-.24-.3,9.72,9.72,0,0,0-1-3.54c-1-1.53-2.66-2.31-5-2.31s-4,.78-5,2.31a9.72,9.72,0,0,0-1,3.54.34.34,0,0,1-.24.3.56.56,0,0,0-.36.33.38.38,0,0,1-.25.21,1.71,1.71,0,0,0-1,1.77,1.78,1.78,0,0,0,1,1.77.36.36,0,0,1,.26.27.56.56,0,0,0,.55.47,4,4,0,0,0,.5,0v-4.94l0-.23a5.61,5.61,0,0,1,.73-2.71,5.15,5.15,0,0,1,4.75-2.28,5.14,5.14,0,0,1,4.74,2.28,5.65,5.65,0,0,1,.73,2.72l0,.22v4.94Z" transform="translate(-1.41 -441.65)" />
      <path d="M8.28,450.75H7l.19-.14.26-.19a6.4,6.4,0,0,0,.5-.41,1.35,1.35,0,0,0,.3-.37.76.76,0,0,0,.11-.38,1.15,1.15,0,0,0-.08-.41.89.89,0,0,0-.24-.3,1.77,1.77,0,0,0-.31-.15,1.65,1.65,0,0,0-.38-.05,1.21,1.21,0,0,0-.91.41l-.09.11a.56.56,0,0,1,0,.08h0a.42.42,0,0,0,0,.1.25.25,0,0,0,0,.08.33.33,0,0,0,.06.22.25.25,0,0,0,.2.07.2.2,0,0,0,.11,0,.32.32,0,0,0,.1-.1A.83.83,0,0,1,7,449a.59.59,0,0,1,.34-.1,1,1,0,0,1,.33.06.27.27,0,0,1,.18.26.4.4,0,0,1-.09.2,1.52,1.52,0,0,1-.25.26L7,450.1l-.37.31a.77.77,0,0,0-.34.56.25.25,0,0,0,.2.28l.14,0H8.18l.1,0a.26.26,0,0,0,.09-.05.41.41,0,0,0,.05-.09.43.43,0,0,0,0-.11.32.32,0,0,0-.07-.19Z" transform="translate(-1.41 -441.65)" />
      <path d="M10.87,450.31h-.33v-1.54a.64.64,0,0,0-.07-.28.32.32,0,0,0-.24-.14l-.13,0-.1.05a.6.6,0,0,0-.16.19l-1.08,1.57a.73.73,0,0,0-.16.37.29.29,0,0,0,.25.3H10V451a.22.22,0,0,0,0,.14.14.14,0,0,0,.05.1.24.24,0,0,0,.22.08.2.2,0,0,0,.17-.07.37.37,0,0,0,.06-.21v-.23h.24a.21.21,0,0,0,.16-.08.39.39,0,0,0,0-.38l-.07-.06ZM10,449.22v1.07H9.3Z" transform="translate(-1.41 -441.65)" />
      <path d="M12,450.07a.51.51,0,0,0-.33.13v-.52a.22.22,0,0,0,0-.13.17.17,0,0,0-.12,0,.13.13,0,0,0-.12,0,.22.22,0,0,0,0,.13v1.4a.19.19,0,0,0,0,.08l0,.06a.1.1,0,0,0,.05,0l.06,0c.06,0,.16,0,.16-.19v-.48l0,0a.25.25,0,0,1,0-.07l.07-.07a.35.35,0,0,1,.14-.06l.09,0,.07,0a.33.33,0,0,1,.06.19v.47a.18.18,0,0,0,0,.14.17.17,0,0,0,.12,0,.16.16,0,0,0,.12-.06l0-.05h0s0-.05,0-.08v-.51a.56.56,0,0,0-.13-.39A.55.55,0,0,0,12,450.07Z" transform="translate(-1.41 -441.65)" />
      <path d="M13.55,450.12a.29.29,0,0,0-.16,0,.46.46,0,0,0-.2.05.38.38,0,0,0-.13.09.17.17,0,0,0,0-.1.13.13,0,0,0-.11-.06.21.21,0,0,0-.13.05.15.15,0,0,0,0,.13v.86a.15.15,0,0,0,0,.13.18.18,0,0,0,.12,0,.16.16,0,0,0,.17-.14v-.61a.43.43,0,0,1,.12-.11.19.19,0,0,1,.12,0h.15a.12.12,0,0,0,.11,0,.15.15,0,0,0,0-.11.15.15,0,0,0-.07-.12Z" transform="translate(-1.41 -441.65)" />
      <path d="M2.73,448.62h.72V453H2.73Z" transform="translate(-1.41 -441.65)" />
      <path d="M16.42,448.62h.73V453h-.73Z" transform="translate(-1.41 -441.65)" />
    </svg>
  </IconWrapper>
);

export default ServiceHDSIcon;




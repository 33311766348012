import React from 'react'



import dig from '../../assets/digittrade.png'
import moreInfo from '../../assets/moreInfo.png'

import dig1 from '../../assets/diglogo1.png'
import dig2 from '../../assets/diglogo2.png'

import digLine from '../../assets/digline.png'
import digprod1 from '../../assets/digprod1.png'
import digprod2 from '../../assets/digprod2.png'
import digprod3 from '../../assets/digprod3.png'

import styled from 'styled-components'
import { device, Heading, InnerWrapper, Text } from '../../common.styles'
import DecoDesc1 from '../../DecoDesc1'
import DecoHeading from '../../DecoHeading'
import TopButton from '../TopButton/TopButton'
import './digittrade.css'

const DeviceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center; 
    margin-top: 30px;

    @media only screen and ${device.mobileL} {
        display: inline;

        > img {
            display: none;
        }
    }
`;

const Device = styled.div`
    width: calc(33.333% - 80px);
    padding: 16px;
    box-sizing: border-box;
    
    @media only screen and ${device.mobileL} {
        width: 100%;
    }

    img {
        width: 100%;
        padding: 32px;
        object-fit: contain;

        @media only screen and ${device.mobileL} {
            width: 50%;
            margin: auto;
        }
    }

    b {
        font-family: HB;
    }
`;

const StyledInnerWrapper = styled(InnerWrapper)`
    max-width: 1200px;
    margin: auto;
`;

const StyledHeading = styled(Heading)`
    text-align: center;
`;

const StyledText = styled(Text)`
    color: #343434;
    text-align: center;
`;

const MoreInfoImg = styled.img`
    width: 383.1px;

    @media only screen and ${device.mobileL} {
        width: 170px;
    }
`;

const DigImgContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    object-fit: contain;

    img {
        width: 252px;

        @media only screen and ${device.mobileL} {
            width: 84px;
        }
    }
`;

const FeaturesImg = styled.img`
    width: 131.4px;
    align-self: center;
    object-fit: contain;

    @media only screen and ${device.mobileL} {
        width: 45px;
    }
`;

const Digittrade = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <DecoHeading heading="DIGITTRADE" />
            <DigImgContainer><img src={dig} alt="" /></DigImgContainer>
            <DecoDesc1>
                DIGITTRADE have been developing and producing external encrypted hard drives since 2005. Banks, companies and authorities use the highly secure HS256S and HS256 S3 hard drives for the safe transport of sensitive data and for the creation of data protection-compliant backups. The HS256 S3 hard drive also received the BSI certificate at the end of 2017.
            </DecoDesc1>

            <StyledInnerWrapper>
                <StyledHeading>
                    Features
                </StyledHeading>
                <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `center`, marginTop: `30px` }}>

                    <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, marginTop: `30px`, justifyContent: `center`, marginRight: `30px` }}>
                        <FeaturesImg src={dig1} />
                        <br />
                        <StyledText>Safe transport of sensitive data</StyledText>


                    </div>

                    <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, marginTop: `30px`, justifyContent: `center`, marginLeft: `30px` }}>
                        <FeaturesImg src={dig2} />
                        <br />
                        <StyledText >Create data<br />protection-compliant backups</StyledText>


                    </div>
                </div>


                <DeviceWrapper>

                    <Device>
                        <div style={{ display: 'flex' }}>
                            <img src={digprod1} />
                        </div>

                        <Text>

                            <label className='marginBotton_dig'>
                                <b className='alignleft'>Kobra Stick</b>
                                <br />
                                <i className='alignleft marginBotton_dig'>
                                    Encrypted USB-C stick with keyboard for PIN input
                                </i>
                            </label>

                            <ul style={{ marginLeft: `20px` }} >
                                <li style={{ marginBottom: `30px` }}>
                                    <p >256-bit AES Full Disk hardware
                                        encryption in XTS mode with 2
                                        AES keys
                                    </p>
                                </li>
                                <li style={{ marginBottom: `30px` }}>
                                    <p >User management of cryptographic
                                        keys (Create, modify and destroy)</p>
                                </li>
                                <li style={{ marginBottom: `30px` }}>
                                    <p >PIN authentication, integrated
                                        writing protection, time-out and
                                        quick-out</p>
                                </li>


                            </ul>
                        </Text>

                    </Device>

                    <img style={{ height: `100%` }} src={digLine} />

                    <Device>
                        <div style={{ display: 'flex' }}>
                            <img src={digprod2} />
                        </div>


                        <Text>

                            <label className='marginBotton_dig'>
                                <b className='alignleft'>HS256 S3 High-Security external Hard drive USB 3.0</b><br />  <i className='alignleft marginBotton_dig'>
                                    BSI-certified High Security Hard Drive/Professional solution for
                                    government and business</i></label>



                            <ul style={{ marginLeft: `20px` }} >
                                <li style={{ marginBottom: `30px` }}>
                                    <p >256-Bit AES full hard drive
                                        encryption in XTS-Mode with 2
                                        AES-keys


                                        ·
                                    </p>
                                </li>
                                <li style={{ marginBottom: `30px` }}>
                                    <p >
                                        2-factor authentication via smart
                                        card and 8-digit PIN</p>
                                </li>
                                <li style={{ marginBottom: `30px` }}>
                                    <p >User management of cryptographic
                                        keys (create, modify and destroy)</p>
                                </li>


                            </ul>
                        </Text>




                    </Device>
                    <img src={digLine} />

                    <Device>
                        <div style={{ display: 'flex' }}>
                            <img src={digprod3} />
                        </div>

                        <Text>

                            <label className='marginBotton_dig'><b className='alignleft'>External RFID security hard drive RS256
                            </b><br />  <i className='alignleft marginBotton_dig'>
                                    Secure solution for businesses and home users</i></label>



                            <ul style={{ marginLeft: `20px` }} >
                                <li style={{ marginBottom: `30px` }}>
                                    <p > 256-bit AES Full Disk hardware
                                        encryption in XTS mode
                                    </p>
                                </li>
                                <li style={{ marginBottom: `30px` }}>
                                    <p >RFID access control, encrypted
                                        storage of the AES key on
                                        the hard drive</p>
                                </li>
                                <li style={{ marginBottom: `30px` }}>
                                    <p >Robust aluminium case and
                                        integrated silicone anti-shock
                                        protectors</p>
                                </li>


                            </ul>
                        </Text>



                    </Device>
                </DeviceWrapper>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <a target='_blank' href='https://www.digittrade.de/store/en'><MoreInfoImg src={moreInfo} alt="" /></a>
                </div>
            </StyledInnerWrapper >
            <br />
            <TopButton />



        </div >
    )
}

export default Digittrade
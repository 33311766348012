import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 52.5px;
  height: 68px;

  @media only screen and ${device.mobileL} {
    width: 17.5px;
    height: 22.67px;
  }

  svg {
    fill: #4f7ac7;
  }
`;

const ProductiTrading5Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 52.452 68.032">
      <g>
        <path d="M5472.825 1342.076v15.6h15.6z" transform="translate(-5438.245 -1339.806)" />
        <path d="M5471.22 1360.889a1.6 1.6 0 0 1-1.6-1.6v-19.478h-31.37v68.032h52.45v-46.949zm11.951 30.554h-37.4a1.6 1.6 0 0 1 0-3.21h37.4a1.6 1.6 0 0 1 0 3.21zm0-9.455h-37.4a1.6 1.6 0 1 1 0-3.209h37.4a1.6 1.6 0 1 1 0 3.209zm0-9.455h-37.4a1.6 1.6 0 0 1 0-3.21h37.4a1.6 1.6 0 0 1 0 3.21z" transform="translate(-5438.245 -1339.806)" />
      </g>
    </svg>
  </IconWrapper>
);

export default ProductiTrading5Icon;



